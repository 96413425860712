import React, { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import { storageUrl } from '../../../../../../constants/globals';

export function SubscriptionUnnecessary({educators, setOpenTermsModal}) {
    return (
        <Grid container alignItems="top" justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
                <Paper sx={{ p: 2 }}>
                    <Typography color='secondary' variant='h4' component='div'>
                        No Subscription is Required!
                    </Typography>
                    <Typography color='secondary' variant='h6' component='div' sx={{mt: 2, mb: 2}}>
                        You currently have an active educator. 
                        This means you get all the benefits of education advisor for free!
                        If you had an active subscription we automatically cancelled it for you.
                        Any remaining days you will be prorated for.
                    </Typography>
                    
                    <Button
                        variant='contained'
                        disableElevation
                        size='small'
                        onClick={() => { setOpenTermsModal(true) }}
                    >
                        Read Terms and Conditions
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                {educators?.map((educator, index) => (
                    <Box sx={{ width:'100%'}} key={index}>
                        <Grid 
                            container 
                            spacing={2} 
                            alignItems="center" 
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={3} md={2} textAlign={'center'}>

                                <div
                                    style={{
                                        height: "70px",
                                        width: "70px",
                                        border: "1px solid #777",
                                        borderRadius: "200px",
                                        overflow: "hidden",
                                        position: "relative",
                                        margin:'auto'
                                    }}
                                >
                                    {!educator?.profile_image && (
                                    <>
                                        <PersonIcon
                                            fontSize="large"
                                            style={{
                                                position: "absolute",
                                                top: "34%",
                                                left: "26%",
                                                transform: "translateX(-50%) translateY(-50%)",
                                                zIndex: 0,
                                                color: "#999",
                                                transform: "scale(2.5)"
                                            }}
                                        />
                                    </>
                                    )}
                                    {educator?.profile_image && (
                                    <>
                                        <img
                                        src={storageUrl + educator?.profile_image}
                                        style={{
                                            height: "100%",
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translateX(-50%) translateY(-50%)",
                                            zIndex: 1
                                        }}
                                        />
                                    </>
                                    )}
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={9} md={10}>
                                <Typography color='secondary' variant='h6'>{educator?.first_name} {educator?.last_name}</Typography>
                                <Typography color='secondary' component='div' sx={{mb:1}}>{educator?.institution?.institution_name}</Typography>
                                {educator?.active === 1 && (
                                    <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                )}
                                {educator?.active === 0 && (
                                    <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                )} 
                                {educator?.pending === 1 && (
                                    <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Your Approval' />
                                )}
                                {educator?.pending === 2 && (
                                    <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Educator Approval' />
                                )}
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};