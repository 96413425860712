import React, { Suspense } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { TasksView } from '../../../../../components/global/tasks/tasksView';
import { TasksList } from '../../../../../components/global/tasks/tasksList';
import { getEducators } from '../../../../../services/studentActionService';
import { getUserTasksByReferrer } from '../../../../../services/tasksService';

export function TasksComponent({user, educator, setOpenErrorModal, setOpenErrorModalText}) {
    const [tasks, setTasks] = React.useState([]);
    const [selectedTask, setSelectedTask] = React.useState({});
    const [educators, setEducators] = React.useState([]);
    const [type, setType] = React.useState('student');
    const [reloadList, setReloadList] = React.useState(false);
    const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
    const handleCloseDrawer = () => setOpenTaskDrawer(false);
  
    const drawerStyle = {
        width: '60%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }

    React.useEffect(() => {
        getEducators(localStorage.getItem('token')).then(educators => {
            if (educators.status == true) {
                setEducators(educators.data);
            }
        }).catch(err => {
            setOpenErrorModal(true);
            setOpenErrorModalText(err);
        });
    }, []);

    React.useEffect(() => {
        getUserTasksByReferrer(localStorage.getItem('token'), educator?.id).then(tasks => {
            if (tasks.status == true) {
                setTasks(tasks.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(tasks.message);
            }
        }).catch(err => {
            setOpenErrorModal(true);
            setOpenErrorModalText(err);
        });
        setReloadList(false);
    }, [user, setOpenTaskDrawer, reloadList]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Tasks
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ width: '100%' }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <TasksList
                        type={type}
                        tasks={tasks}
                        setSelectedTask={setSelectedTask}
                        attachedUser={user}
                        setReloadList={setReloadList}
                        setOpenTaskDrawer={setOpenTaskDrawer}
                        setError={setOpenErrorModal}
                        setErrorText={setOpenErrorModalText}
                        removeGutters={true}
                    />
                </Suspense>
            </Box>
            <Drawer
                open={openTaskDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <TasksView 
                    type={type}
                    selectedTask={selectedTask}
                    attachedUser={user}
                    attachedRecipient={{}}
                    educators={educators}
                    handleCloseDrawer={handleCloseDrawer}
                    setReloadList={setReloadList}
                    setError={setOpenErrorModal}
                    setErrorText={setOpenErrorModalText}
                />
            </Drawer>
        </>
    )
}