import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import MailIcon from '@mui/icons-material/Mail';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HandshakeIcon from '@mui/icons-material/Handshake';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import StarIcon from '@mui/icons-material/Star';
import Chip from '@mui/material/Chip';
import { StudentEdit } from './studentEdit';
import { EducatorsComponent } from './studentViewComponents/educatorsComponent';
import { ActivationComponent } from './studentViewComponents/activationComponent';
import { getEmployee } from '../../../../services/educationActionsService';
import { OverviewComponent } from './studentViewComponents/overviewComponent';
import { TasksComponent } from './studentViewComponents/tasksComponent';
import { MessagesComponent } from './studentViewComponents/messagesComponent';
import { PortfolioComponent } from './studentViewComponents/portfolioComponent';
import { ResumeComponent } from './studentViewComponents/resumeComponent';
import { DocumentsComponent } from './studentViewComponents/documentsComponent';
import { SuggestionsComponent } from './studentViewComponents/suggestionsComponent';
import { LoansComponent } from './studentViewComponents/loansComponent';
import { FavoritesComponent } from './studentViewComponents/favoritesComponent';
import { ScholarshipsComponent } from './studentViewComponents/scholarshipsComponent';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function StudentView({student, educator, employees, selectedEducatorId, setViewStudent}) {
    const [selectedEducator, setSelectedEducator] = React.useState({});
    const [selectedEducatorData, setSelectedEducatorData] = React.useState({});
    const [deactivatedGlobally, setDeactivatedGlobally] = React.useState(false);
    const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);
    const [drawerState, setDrawerState] = React.useState(false);
    const [openOverview, setOpenOverview] = React.useState(true);
    const [openTasks, setOpenTasks] = React.useState(false);
    const [openMessages, setOpenMessages] = React.useState(false);
    const [openPortfolio, setOpenPortfolio] = React.useState(false);
    const [openFavorites, setOpenFavorites] = React.useState(false);
    const [openResume, setOpenResume] = React.useState(false);
    const [openDocuments, setOpenDocuments] = React.useState(false);
    const [openSuggestions, setOpenSuggestions] = React.useState(false);
    const [openLoans, setOpenLoans] = React.useState(false);
    const [openScholarships, setOpenScholarships] = React.useState(false);

    const navigationStyle = {
        '@media (max-width: 899px)': {
            display: 'block',
            float: 'right'
        },
        'display': 'none'
    }

    const sidebarStyle = {
        '@media (max-width: 899px)': {
            display: 'none'
        },
        borderLeft: '1px solid #e0e0e0',
    }

    React.useEffect(() => {
        // I was drunk when I wrote the endpoint 
        // So now we have this fucked up logic.
        if (student?.educators) {
            let assignment = {};
            student?.educators.forEach(assignedEducator => {
                if (selectedEducatorId === assignedEducator.id) {
                    assignment = assignedEducator;
                }
            });
            if (!assignment?.id) {
                assignment = student?.educators[0];
            }
            setSelectedEducator(assignment);
        } else {
            setSelectedEducator({
                id: educator?.id, 
                account_locked: educator?.account_locked ? 1 : 0,
                active: educator?.active ? 1 : 0,
                deactivated_by: educator?.active ? educator?.id : null,
                email: educator?.email,
                first_name: educator?.firstName,
                last_name: educator?.lastName,
                pending: student?.pending
            });
        }
        if (student?.active_institution === 0) {
            setDeactivatedGlobally(true);
        }
    }, []);

    React.useEffect(() => {
        if (selectedEducator?.id !== undefined) {
            getEmployee(localStorage.getItem('token'), selectedEducator?.id).then(selectedEducatorData => {
                if (selectedEducatorData?.status == true) {
                    setSelectedEducatorData(selectedEducatorData?.data);
                } else {
                    setOpenErrorModal(true);
                    setOpenErrorModalText(selectedEducatorData?.message);
                }
            });
        }
    }, [selectedEducator]);

    const toggleDrawer = (direction) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setDrawerState(direction);
    };

    const handleNavigationChange = (page) => {
        setOpenOverview(false);
        setOpenMessages(false);
        setOpenPortfolio(false);
        setOpenFavorites(false);
        setOpenResume(false);
        setOpenTasks(false);
        setOpenDocuments(false);
        setOpenSuggestions(false);
        setOpenLoans(false);
        setOpenScholarships(false);

        if (page === 'overview') {
            setOpenOverview(true);
        }

        if (page === 'favorites') {
            setOpenFavorites(true);
        }

        if (page === 'messages') {
            setOpenMessages(true);
        }

        if (page === 'portfolio') {
            setOpenPortfolio(true);
        }

        if (page === 'resume') {
            setOpenResume(true);
        }

        if (page === 'tasks') {
            setOpenTasks(true);
        }

        if (page === 'documents') {
            setOpenDocuments(true);
        }

        if (page === 'suggestions') {
            setOpenSuggestions(true);
        }

        if (page === 'loans') {
            setOpenLoans(true);
        }

        if (page === 'scholarships') {
            setOpenScholarships(true);
        }
    };

    const navigationList = (
        <Box
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{pt:0, pb:0}}>
            <ListItemButton
                selected={openOverview}
                onClick={() => handleNavigationChange('overview')}
            >
                <ListItemIcon>
                    <LineAxisIcon />
                </ListItemIcon>
                <ListItemText primary='Overview' />
            </ListItemButton>
            <ListItemButton
                selected={openLoans}
                onClick={() => handleNavigationChange('loans')}
            >
                <ListItemIcon>
                    <HandshakeIcon />
                </ListItemIcon>
                <ListItemText primary='Loans' />
            </ListItemButton>
            <ListItemButton
                selected={openScholarships}
                onClick={() => handleNavigationChange('scholarships')}
            >
                <ListItemIcon>
                    <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary='Scholarships' />
            </ListItemButton>
            <Tooltip 
                arrow 
                placement='bottom'
                title={educator?.user_level > selectedEducatorData?.user_level ? 'You do not have permission to view this users data.' : ''}
            >
                <span>
                    <ListItemButton
                        selected={openMessages}
                        onClick={() => handleNavigationChange('messages')}
                        disabled={educator?.user_level > selectedEducatorData?.user_level ? true : false}
                    >
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary='Messages' />
                    </ListItemButton>
                </span>
            </Tooltip>
            <ListItemButton
                selected={openFavorites}
                onClick={() => handleNavigationChange('favorites')}
            >
                <ListItemIcon>
                    <StarIcon />
                </ListItemIcon>
                <ListItemText primary='Favorites' />
            </ListItemButton>
            <ListItemButton
                selected={openPortfolio}
                onClick={() => handleNavigationChange('portfolio')}
            >
                <ListItemIcon>
                    <FolderCopyIcon />
                </ListItemIcon>
                <ListItemText primary='Portfolio' />
            </ListItemButton>
            <ListItemButton
                selected={openResume}
                onClick={() => handleNavigationChange('resume')}
            >
                <ListItemIcon>
                    <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary='Resume' />
            </ListItemButton>
            <Tooltip 
                arrow 
                placement='bottom'
                title={educator?.user_level > selectedEducatorData?.user_level ? 'You do not have permission to view this users data.' : ''}
            >
                <span>
                    <ListItemButton
                        selected={openTasks}
                        onClick={() => handleNavigationChange('tasks')}
                        disabled={educator?.user_level > selectedEducatorData?.user_level ? true : false}
                    >
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Tasks' />
                    </ListItemButton>
                </span>
            </Tooltip>
            <Tooltip 
                arrow 
                placement='bottom'
                title={educator?.user_level > selectedEducatorData?.user_level ? 'You do not have permission to view this users data.' : ''}
            >
                <span>
                    <ListItemButton
                        selected={openDocuments}
                        onClick={() => handleNavigationChange('documents')}
                        disabled={educator?.user_level > selectedEducatorData?.user_level ? true : false}
                    >
                        <ListItemIcon>
                            <FilePresentIcon />
                        </ListItemIcon>
                        <ListItemText primary='Documents' />
                    </ListItemButton>
                </span>
            </Tooltip>
            <Tooltip 
                arrow 
                placement='bottom'
                title={educator?.user_level > selectedEducatorData?.user_level ? 'You do not have permission to view this users data.' : ''}
            >
                <span>
                    <ListItemButton
                        selected={openSuggestions}
                        onClick={() => handleNavigationChange('suggestions')}
                        disabled={educator?.user_level > selectedEducatorData?.user_level ? true : false}
                    >
                        <ListItemIcon>
                            <LightbulbIcon />
                        </ListItemIcon>
                        <ListItemText primary='Suggestions' />
                        <Chip 
                            label="BETA"
                            color='primary'
                            size='small'
                            sx={{
                                color: 'white',
                            }}
                        />
                    </ListItemButton>
                </span>
            </Tooltip>
          </List>
        </Box>
    );

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Link onClick={() => setViewStudent(false)} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To All Students
                    </Link>
                </Grid>
                <Grid item xs={12} sm={6} sx={{textAlign: 'right'}}>
                    {(educator?.user_level === 1 || educator?.user_level === 2) && (
                        <Button
                            variant='outlined'
                            disableElevation
                            size='small'
                            disabled={educator?.active ? false : true}
                            onClick={() => setOpenUpdateModal(true)}
                        >
                            Edit Student Institutionally
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{padding:2}}>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <Typography color='secondary' variant='h4' component='div'>
                        {student?.first_name} {student?.last_name}
                    </Typography>
                    {deactivatedGlobally && (
                        <Typography component='p' sx={{color:'red'}}>
                            This student was deactivated from this institution. {educator?.user_level === 1 || educator?.user_level === 2 ? 'To edit this user you will need to reactivate them institutionally.' : 'Contact your manager to reactivate this student on the institution.'}
                        </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} textAlign={'right'}>
                        <ActivationComponent
                            selectedEducator={selectedEducator}
                            student={student}
                            currentEducator={educator}
                            deactivatedGlobally={deactivatedGlobally}
                        />
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                {(student?.educators && (educator?.user_level === 1 || educator?.user_level === 2) &&
                    <>
                        <EducatorsComponent
                            loggedInUser={educator}
                            educators={student?.educators} 
                            selectedEducator={selectedEducator} 
                            setSelectedEducator={setSelectedEducator} 
                            setOpenUpdateModal={setOpenUpdateModal}
                        />
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    </>
                )}
                <Grid container spacing={0}>
                    <Grid 
                        item 
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        <IconButton
                            size="small"
                            sx={navigationStyle}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon sx={{ fontSize: '30px'}} />
                        </IconButton>
                        {openOverview && (
                            <OverviewComponent
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenOverview={setOpenOverview}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openLoans && (
                            <LoansComponent
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenLoans={setOpenLoans}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openScholarships && (
                            <ScholarshipsComponent
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenScholarships={setOpenScholarships}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openMessages && (
                            <MessagesComponent
                                user={educator}
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openFavorites && (
                            <FavoritesComponent
                                user={educator}
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenFavorites={setOpenFavorites}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openPortfolio && (
                            <PortfolioComponent
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openResume && (
                            <ResumeComponent
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenMessages={setOpenMessages}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openTasks && (
                            <TasksComponent
                                user={educator}
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenTasks={setOpenTasks}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openDocuments && (
                            <DocumentsComponent
                                user={educator}
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenDocuments={setOpenDocuments}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                        {openSuggestions && (
                            <SuggestionsComponent
                                user={educator}
                                student={student}
                                selectedEducator={selectedEducator}
                                setOpenSuggestions={setOpenSuggestions}
                                setError={setOpenErrorModal}
                                setErrorText={setOpenErrorModalText}
                            />
                        )}
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={sidebarStyle}
                    >
                        {navigationList}
                    </Grid>
                </Grid>
                <Drawer
                    open={drawerState}
                    onClose={toggleDrawer(false)}
                    anchor='right'
                    sx={{zIndex: 99999}}
                    PaperProps={{
                        sx: {
                            width: '300px',
                        }
                    }}
                >
                    {navigationList}
                </Drawer>
            </Paper>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                </Typography>
                </Box>
            </Modal>
            <Modal
                open={openUpdateModal}
                onClose={handleCloseUpdateModal}
            >
                <>
                    <StudentEdit
                        student={student}
                        educator={educator}
                        employees={employees}
                        handleCloseUpdateModal={handleCloseUpdateModal}
                    />
                </>
            </Modal>
        </>
    )
}