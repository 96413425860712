import { apiUrl } from '../constants/globals';

export async function getContacts(token) {
    try {
        const response = await fetch(apiUrl + '/user/contacts/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getMessages(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/messages/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEmployeeMessages(token, educatorId, id) {
    try {
        const response = await fetch(apiUrl + '/user/messages/' + educatorId + '/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUnreadMessagesCount(token) {
  try {
      const response = await fetch(apiUrl + '/user/unread/messages', {
        method: 'GET',
        headers: new Headers({
          'Authorization': 'Bearer ' + token
        })
      });
      return await response.json();
  } catch(error) {
      return error;
  }
}

export async function sendMessage(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/user/messages/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function markMessagesRead(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/messages/' + id, {
          method: 'PUT',
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}