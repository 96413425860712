import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import StarIcon from '@mui/icons-material/StarBorder';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

const tierLimits = {
    'Standard': 50,
    'Standard Plus': 100,
    'Premium': 200,
    'Enterprise': Infinity
}

const PricesComponent = ({products, institution, recommendedProduct, setChosenPrice, setChosenProduct, setStripeOptions}) => {

    const [selectedPricing, setSelectedPricing] = React.useState({});

    const handlePricingChange = (event, values) => {
        let pricing = structuredClone(selectedPricing);
        pricing[values.props.id] = event.target.value;
        
        setSelectedPricing(pricing);
    };

    const handlePricingClick = (productID) => {
        setChosenProduct(productID);
        setChosenPrice(selectedPricing[productID]);
        products.forEach(product => {
            if (product.id === productID) {
                product.pricing.forEach(pricing => {
                    if (pricing.id === selectedPricing[productID]) {
                        setStripeOptions({
                            mode: 'subscription',
                            currency: 'usd',
                            amount: pricing.unit_amount,
                            captureMethod: 'automatic',
                            setupFutureUsage: 'off_session'
                        });
                    }
                });
            }
        });
    };

    React.useEffect(() => {
        if (Object.keys(selectedPricing).length === 0) {
            if (Array.isArray(products) && products.length !== 0) {
                let pricing = {};

                products?.forEach((product) => {
                    pricing[product.id] = product?.pricing[0].id;
                });

                setSelectedPricing(pricing);
            }
        }
    });

    return (
        <>
            <Typography color='secondary' variant='h4' component='div'>
                Your Subscription
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{ p: 2 }}>
                <Typography
                    component="h3"
                    variant="h3"
                    align="center"
                    color="primary"
                    gutterBottom
                >
                    Our Pricing
                </Typography>
                <Typography align="center" color="secondary" component="p">
                    We offer tiered pricing based on the number of students your institution has.<br />
                    You have to option to pay monthly or yearly for a discount. 
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
                <Grid container spacing={5} alignItems="flex-end">
                    {products.length > 0 && products?.slice(0).reverse().map((product, index) => (
                        <Grid
                            item
                            key={product.id}
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <Card
                                variant='outlined'
                            >
                                <CardHeader
                                    title={product.name}
                                    subheader={product.name === recommendedProduct ? 'Recommended For You' : null}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={product.name === recommendedProduct ? <StarIcon color='primary' /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                        color: 'primary'
                                    }}
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.grey[200]
                                    }}
                                />
                                <CardContent>
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mt: 2,
                                    }}
                                    >
                                    <Select
                                        value={selectedPricing[product.id] || ''}
                                        autoWidth
                                        sx={{width:'100%', textAlign: "center"}}
                                        onChange={handlePricingChange}
                                    >
                                        {product?.pricing.slice(0).map((price, index) => (
                                            <MenuItem key={price.id} id={product.id} value={price.id}>
                                                <Typography component="h2" variant="h3" display="inline">
                                                    ${price.unit_amount.toString().substring(0, price.unit_amount.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </Typography>
                                                <Typography variant="h6" color="secondary" display="inline">
                                                    {price?.recurring.interval === 'year' ? "/yr" : "/mo"}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </Box>
                                    <Typography
                                        component="p"
                                        align="center"
                                        sx={{
                                            mt:2,
                                            fontWeight:'bold'
                                        }}
                                    >
                                    {product.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Tooltip arrow title={institution?.actual_number_of_students > tierLimits[product?.name] ? "The number of students you have exceeds this tier limit." : ""}>
                                        <span style={{display:'block', width:'100%'}}>
                                            <Button 
                                                fullWidth
                                                disableElevation
                                                variant='contained'
                                                disabled={institution?.actual_number_of_students > tierLimits[product?.name] ? true : false}
                                                onClick={() => handlePricingClick(product.id)}
                                            >
                                                Sign Up
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </>
    );
};

export default PricesComponent;
