import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';
import EditPayments from '../subscription/account/edit/payments/payments';
import { getBillingMethods, deleteBillingMethod, getSubscription, addToCreditBalance } from '../../../../services/educatorBillingService';
import { educatorAccount, checkType } from '../../../../services/userService';
import { getInstitution, updateInstitutionAdvertisingData } from '../../../../services/institutionService';
import { stripePublicKey } from '../../../../constants/globals';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const stripePromise = loadStripe(stripePublicKey);

const AdvertisingBalance = () => {
    const [cards, setCards] = React.useState([]);
    const [subscription, setSubscription] = React.useState({});
    const [institutionData, setInstitutionData] = React.useState({});
    const [addPaymentPage, setAddPaymentPage] = React.useState(false);
    const [creditBalance, setCreditBalance] = React.useState(0.00);
    const [dailySpendLimit, setDailySpendLimit] = React.useState(0.00);
    const [automaticallyRecharge, setAutomaticallyRecharge] = React.useState(0);
    const [rechargeBalance, setRechargeBalance] = React.useState(0.00);
    const [rechargeThreshold, setRechargeThreshold] = React.useState(0.00);
    const [updateAdvertisingBalancePage, setUpdateAdvertisingBalancePage] = React.useState(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);
    const [openHowItWorksModal, setOpenHowItWorksModal] = React.useState(false);
    const handleCloseHowItWorksModal = () => setOpenHowItWorksModal(false);
    const [openCreditBalanceModal, setOpenCreditBalanceModal] = React.useState(false);
    const handleCloseCreditBalanceModal = () => setOpenCreditBalanceModal(false);
    const [openDailySpendModal, setOpenDailySpendModal] = React.useState(false);
    const handleCloseDailySpendModal = () => setOpenDailySpendModal(false);
    const [openRechargeModal, setOpenRechargeModal] = React.useState(false);
    const handleCloseRechargeModal = () => setOpenRechargeModal(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const handleCloseSuccess = () => setOpenSuccess(false);

    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }

    React.useEffect(() => {
        checkType(localStorage.getItem('token')).then(requestData => {
          if (requestData.status == true) {
            if (requestData.data?.type === 1) {
              window.location.href = '/student/dashboard';
            }
          }
        });
        educatorAccount(localStorage.getItem('token')).then(userData => {
          if (userData.status == true) {
            if (userData.data?.user_level !== 1 || userData.data?.account_locked) {
              window.location.href = '/educator/dashboard';
            }
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(userData.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
        getBillingMethods(localStorage.getItem('token')).then(cards => {
            if (cards.status == true) {
                setCards(cards.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(cards.errors);
            }
        });
        getSubscription(localStorage.getItem('token')).then(subscription => {
            if (subscription.status == true && Object.keys(subscription.data).length !== 0) {
              setSubscription(subscription.data);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
        getInstitution(localStorage.getItem('token')).then(institutionData => {
            if (institutionData.status == true) {
                setInstitutionData(institutionData.data);
                setDailySpendLimit(institutionData.data.daily_spend_limit);
                setAutomaticallyRecharge(institutionData.data.automatically_recharge);
                setRechargeBalance(institutionData.data.recharge_balance);
                setRechargeThreshold(institutionData.data.recharge_threshold);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(institutionData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
    }, []);

    const deleteCard = (card) => {
        let cardData = new URLSearchParams({
            'card':card
        });

        deleteBillingMethod(localStorage.getItem('token'), cardData).then(response => {
            if (response.status == true) {
                const newCardList = cards.filter((cardList) => cardList.id !== card);
                setCards(newCardList);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(response.errors);
            }
        });
    };

    const setPaymentPage = () => {
        handleCloseCreditBalanceModal()
        setAddPaymentPage(true);
        window.scrollTo(0, 0);
    };

    const handleDailySpendLimitChange = (event) => {
        if (event.target.value) {
            setDailySpendLimit(event.target.value);
        }
    };

    const handleCreditBalanceChange = (event) => {
        if (event.target.value) {
            setCreditBalance(event.target.value);
        }
    };
    
    const handleAutotmaticallyRechargeChange = (event) => {
        setAutomaticallyRecharge(event.target.checked === true ? 1 : 0);
    };

    const handleRechargeBalanceChange = (event) => {
        if (event.target.value) {
            setRechargeBalance(event.target.value);
        }
    };

    const handleRechargeThresholdChange = (event) => {
        if (event.target.value) {
            setRechargeThreshold(event.target.value);
        }
    };

    const handleUpdateCreditBalance = () => {
        let balanceData = new URLSearchParams({
            'amount':creditBalance
        });

        addToCreditBalance(localStorage.getItem('token'), balanceData).then(response => {
            if (response.status == true) {
                getInstitution(localStorage.getItem('token')).then(institutionData => {
                    if (institutionData.status == true) {
                        setInstitutionData(institutionData.data);
                        setDailySpendLimit(institutionData.data.daily_spend_limit);
                        setAutomaticallyRecharge(institutionData.data.automatically_recharge);
                        setRechargeBalance(institutionData.data.recharge_balance);
                        setRechargeThreshold(institutionData.data.recharge_threshold);
                    } else {
                        setOpenErrorModal(true);
                        setOpenErrorModalText(institutionData.message);
                    }
                }).catch(err => {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                });
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(response.errors);
            }
        });
        handleCloseCreditBalanceModal();
    };

    const handleUpdateDailySpend = () => {
        let data = new URLSearchParams({
            'daily_spend_limit':dailySpendLimit,
            'automatically_recharge':institutionData?.automatically_recharge,
            'recharge_balance':institutionData?.recharge_balance,
            'recharge_threshold':institutionData?.recharge_threshold,
        });
    
        updateInstitutionAdvertisingData(localStorage.getItem('token'), data).then(institutionData => {
            if (institutionData?.status == true) {
                setInstitutionData(institutionData.data);
                setCreditBalance(0.00);
                setDailySpendLimit(institutionData.data.daily_spend_limit);
                setAutomaticallyRecharge(institutionData.data.automatically_recharge);
                setRechargeBalance(institutionData.data.recharge_balance);
                setRechargeThreshold(institutionData.data.recharge_threshold);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(institutionData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
        handleCloseDailySpendModal();
    };

    const handleUpdateRechargeSettings = () => {
        let data = new URLSearchParams({
            'daily_spend_limit':institutionData?.daily_spend_limit,
            'automatically_recharge':automaticallyRecharge,
            'recharge_balance':rechargeBalance,
            'recharge_threshold':rechargeThreshold,
        });
    
        updateInstitutionAdvertisingData(localStorage.getItem('token'), data).then(institutionData => {
            if (institutionData?.status == true) {
                setInstitutionData(institutionData.data);
                setCreditBalance(0.00);
                setDailySpendLimit(institutionData.data.daily_spend_limit);
                setAutomaticallyRecharge(institutionData.data.automatically_recharge);
                setRechargeBalance(institutionData.data.recharge_balance);
                setRechargeThreshold(institutionData.data.recharge_threshold);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(institutionData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
        handleCloseRechargeModal();
    };

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
            <Typography color='secondary' variant='h4' component='div'>
                Advertising Balance
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{ p: 2 }}>
                <Box sx={{ width: '100%' }}>

                    {addPaymentPage ? (
                        <Elements 
                            stripe={stripePromise}
                            options={{
                                mode: 'payment',
                                amount: subscription?.plan?.amount ? subscription?.plan?.amount : 100,
                                currency: 'usd',
                                paymentMethodCreation: 'manual',
                            }}
                        >
                            <EditPayments
                                setAddPaymentPage={setAddPaymentPage}
                                setUpdateSubscriptionPage={setUpdateAdvertisingBalancePage}
                                subscription={subscription}
                            />
                        </Elements>
                    ) : (
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Typography color='secondary' variant='h4' component='div' sx={{mb:2}}>
                                    Pay Per Click
                                </Typography>
                                <Typography color='secondary' variant='h6' component='div'>
                                    Current Credit Balance
                                </Typography>
                                <Typography color='primary' variant='h3' component='div' sx={{mb:1}}>
                                    ${institutionData?.credit_balance}
                                </Typography>
                                <Button 
                                    disableElevation
                                    size='small'
                                    variant='contained'
                                    onClick={() => setOpenCreditBalanceModal(true)}
                                    sx={{mr:1}}
                                >
                                    Add More
                                </Button>
                                <Button 
                                    disableElevation
                                    size='small'
                                    color='secondary'
                                    variant='outlined'
                                    onClick={() => setOpenHowItWorksModal(true)}
                                >
                                    How It works
                                </Button>
                                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                <Typography color='secondary' variant='h6' component='div'>
                                    Daily Spend Limit
                                    {(institutionData?.credit_balance > 0 && institutionData?.daily_spend_limit === 0) && (
                                        <Tooltip arrow title={(institutionData?.credit_balance > 0 && institutionData?.daily_spend_limit === 0) ? "You must make your daily spending limit greater than zero for your ads to show up." : ""}>
                                            <WarningIcon sx={{mb:'-5px', ml:1}} color='primary' />
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography color='secondary' variant='h5' component='div'>
                                    {(institutionData?.daily_spend_limit === null) ? 'Not Set' : ('$' + institutionData?.daily_spend_limit)}
                                    <IconButton 
                                        color='secondary' 
                                        size='small'
                                        onClick={() => setOpenDailySpendModal(true)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Typography>
                                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                {institutionData?.automatically_recharge === 1 ? (
                                    <>
                                        <Typography color='primary' component='div' sx={{fontWeight:'bold'}}>
                                            Auto Recharge Is On
                                        </Typography>
                                        <Typography color='secondary' component='div'>
                                            When your credit balance reaches <b>${institutionData?.recharge_balance}</b>, your payment method will be charged to bring the balance up to <b>${institutionData?.recharge_threshold}</b>.
                                        </Typography>
                                        <Button 
                                            disableElevation
                                            size='small'
                                            color='primary'
                                            variant='outlined'
                                            onClick={() => setOpenRechargeModal(true)}
                                        >
                                            Modify
                                        </Button>
                                    </>
                                ) : (
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Switch
                                                checked={openRechargeModal}
                                                onChange={() => setOpenRechargeModal(true)}
                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography color='secondary' component='div'>
                                                Automatically recharge my card when my credit balance falls below a threshold
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Typography color='secondary' variant='h6' component='div'>
                                    Payment Methods
                                </Typography>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
                                {cards?.length > 0 ? (
                                    <>
                                        {cards?.map((card, index) => (
                                            <Grid 
                                                key={index} 
                                                container 
                                                p={1} 
                                                mb={2}
                                                alignItems="center"
                                                sx={[
                                                    {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                                    {border:'1px solid #E5E5E5', borderRadius:1}
                                                ]}
                                            >
                                                <Grid item xs={9}>
                                                    {card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)} &#8226; &#8226; &#8226; &#8226; {card.card.last4}
                                                    {card?.is_primary && (
                                                        <>
                                                            &nbsp; <Chip 
                                                                        label="Card Used For Payments" 
                                                                        color="primary" 
                                                                        size="small" 
                                                                        sx={{
                                                                            color:'white',
                                                                            fontSize: '1'
                                                                        }}
                                                                    />
                                                        </>
                                                    )}
                                                    <br />
                                                    <Typography fontSize="14px">
                                                        Expires On {card.card.exp_month}/{card.card.exp_year}
                                                    </Typography>
                                                    <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }}></Divider>
                                                    <Typography fontSize="14px">
                                                        {card.billing_details.address.line1}, {card.billing_details.address.city} {card.billing_details.address.state}, {card.billing_details.address.postal_code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                                    <Tooltip arrow title={card?.is_primary ? "You cannot delete a primary card. You must add a new card and add it as the primary card." : ""}>
                                                        <span>
                                                            <Button
                                                                color="secondary"
                                                                disabled={card?.is_primary && true}
                                                                onClick={() => {deleteCard(card?.id)}}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </>
                                ) : (
                                    <Typography
                                        color='secondary'
                                        component='div'
                                        sx={{ mt: 3, mb: 3, textAlign:'center'}}
                                    >
                                        No Payment Methods Found
                                    </Typography>
                                )}
                                <Button 
                                    variant="text" 
                                    sx={{fontWeight:'bold'}}
                                    onClick={setPaymentPage}
                                >
                                    + Add A New Payment Method
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Paper>
            <Modal
                open={openHowItWorksModal}
                onClose={handleCloseHowItWorksModal}
            >
                <Box sx={style}>
                    <Typography variant="h4" component='div'>
                        How It Works
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>
                    <Typography variant="p" component='div' sx={{mb:1}}>
                        We use a pay-per-click model for advertisements. When you create an ad, you set how much you are willing to pay per click.
                        If a user clicks on your advertisement, we take that amount out of your credit balance.
                    </Typography>
                    <Typography variant="p" component='div'>
                        The more you are willing to pay per click the higher your advertisement shows up to users throughout the application. 
                        More competitive, areas, schools and occupations will cost more to show up first than less competitive ones. You can 
                        also set a daily spending limit and automatically refill your account if it hits a certain threshold.
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openCreditBalanceModal}
                onClose={handleCloseCreditBalanceModal}
            >
                <Box sx={style}>
                    <Typography variant="h4" component='div' sx={{textAlign:'center'}}>
                    Add To Your Credit Balance
                    </Typography>
                    {cards.length === 0 ? (
                        <>
                            <Typography variant="h6" component='div' sx={{textAlign:'center'}}>
                            You have no cards attached to your account! Please click to add one below:
                            </Typography>
                            <Button 
                                variant='contained'
                                color='primary'
                                onClick={setPaymentPage}
                                disableElevation
                                fullWidth
                                sx={{mt: 1}}
                            >
                                Add A Payment Method
                            </Button>
                        </>
                    ) : (
                        <>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>
                            <TextField
                                required
                                fullWidth
                                name="credit"
                                id="credit"
                                label="Credit Balance"
                                type="number"
                                sx={{mb:1}}
                                value={creditBalance}
                                onChange={handleCreditBalanceChange}
                                error={(creditBalance < 5 || creditBalance > 500)}
                                helperText={'Must be less than $500 and more than $5.'}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                disableElevation
                                size="large"
                                fullWidth
                                sx={{mt:2}}
                                value={creditBalance}
                                disabled={(creditBalance < 5 || creditBalance > 500) ? true : false}
                                onClick={handleUpdateCreditBalance}
                            >
                                Add To Credit Balance
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
            <Modal
                open={openDailySpendModal}
                onClose={handleCloseDailySpendModal}
            >
                <Box sx={style}>
                    <Typography variant="h4" component='div' sx={{textAlign:'center'}}>
                    Daily Spending Limit
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>
                    <TextField
                        required
                        fullWidth
                        name="daily_spend"
                        id="daily_spend"
                        label="Daily Spend"
                        type="number"
                        sx={{mb:1}}
                        value={dailySpendLimit}
                        onChange={handleDailySpendLimitChange}
                        error={(dailySpendLimit > 500 || dailySpendLimit <= 0)}
                        helperText={'Cannot be over $500 or $0'}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        size="large"
                        fullWidth
                        sx={{mt:2}}
                        disabled={(dailySpendLimit <= 0 || dailySpendLimit > 500) ? true : false}
                        onClick={handleUpdateDailySpend}
                    >
                        Update Spending Limit
                    </Button>
                </Box>
            </Modal>
            <Modal
                open={openRechargeModal}
                onClose={handleCloseRechargeModal}
            >
                <Box sx={style}>
                    <Typography variant="h4" component='div' sx={{textAlign:'center'}}>
                    Automatic Recharge Settings
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }}></Divider>
                    <Typography variant="p" component='div' sx={{fontWeight:'bold', mb: 1}}>
                    Would you like to automatically recharge?
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Switch
                                checked={automaticallyRecharge === 1 ? true : false}
                                onChange={handleAutotmaticallyRechargeChange}
                            />
                        </Grid>
                        <Grid item xs={10}>
                            <Typography color='secondary' component='div'>
                                Automatically replenish your credit balance when it falls below a certain threshold.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="p" component='div' sx={{fontWeight:'bold', mt: 2, mb: 1}}>
                    When credit balance falls below:
                    </Typography>
                    <TextField
                        required
                        fullWidth
                        name="recharge_balance"
                        id="recharge_balance"
                        label="Credit Balance"
                        type="number"
                        sx={{mb:1, mt:1}}
                        value={rechargeBalance}
                        error={(rechargeBalance <= 0 || (rechargeThreshold - rechargeBalance) < 5)}
                        onChange={handleRechargeBalanceChange}
                        helperText={'Must be at least $5 less than what you want your credit balance to jump back up to.'}
                    />
                    <Typography variant="p" component='div' sx={{fontWeight:'bold', mt: 2, mb: 1}}>
                    Bring credit balance back up to:
                    </Typography>
                    <TextField
                        required
                        fullWidth
                        name="recharge_threshold"
                        id="recharge_threshold"
                        label="Recharge Threshold"
                        type="number"
                        sx={{mb:1, mt:1}}
                        value={rechargeThreshold}
                        error={(rechargeThreshold < 5 || rechargeThreshold > 500)}
                        onChange={handleRechargeThresholdChange}
                        helperText={'Must be greater than $5 and less than $500.'}
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        size="large"
                        fullWidth
                        sx={{mt:2}}
                        onClick={handleUpdateRechargeSettings}
                        disabled={((rechargeBalance < 5 || (rechargeThreshold - rechargeBalance) < 5) || (rechargeThreshold <= 0 || rechargeThreshold > 500)) ? true : false}
                    >
                        Update Recharge Settings
                    </Button>
                </Box>
            </Modal>
            <Snackbar
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseErrorModal} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                {openErrorModalText}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openSuccess}
                onClose={handleCloseSuccess}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
                Success
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default AdvertisingBalance;