import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { userAccount } from '../../services/userService';
import { handleNoLoans } from '../../services/loansService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};


export const NoLoansModal = () => {

  const [investmentId, setInvestmentId] = React.useState(null);

  React.useEffect(() => {
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const clickHandleNoLoans = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'no_loans':1
    });

    handleNoLoans(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
          If you do not anticipate having loans and your college will be paid in full continue below. This will affect your financial projections. NOTE: In the future if you do end up getting loans you can add them here.
        </Typography>
        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          onClick={clickHandleNoLoans}
        >
          Continue Without Loans
        </Button>
      </Box>
    </React.Fragment>
  );
};
