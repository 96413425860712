import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getOccupationsList, getIndustriesList, updateOccupation } from '../../services/employmentService';
import { userAccount } from '../../services/userService';

export const OccupationModal = () => {

  const [investmentId, setInvestmentId] = React.useState(null);
  const [occupations, setOccupations] = React.useState([]);
  const [selectedOccupationCode, setSelectedOccupationCode] = React.useState(null);
  const [showIndustries, setShowIndustries] = React.useState(false);
  const [industries, setIndustries] = React.useState([]);
  const [occupation, setOccupation] = React.useState(null);
  const [industry, setIndustry] = React.useState(null);

  React.useEffect(() => {
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    getOccupationsList().then(getOccupationsList => {
      setOccupations(getOccupationsList.data);
    });
  }, []);

  const handleUpdateOccupation = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'occupation':occupation,
      'industry':industry
    });

    updateOccupation(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  const onOccupationChange = (event, values) => {
    setShowIndustries(false);
    setIndustries([]);
    setOccupation(null);
    if (values) {
      setOccupation(values.occupation_code);
      getIndustriesList(values.occupation_code).then(getIndustriesList => {
        setIndustries(getIndustriesList.data);
      });
      setShowIndustries(true);
    }
  }

  const onIndustryChange = (event, values) => {
    setIndustry(null);
    if (values) {
      setIndustry(values.industry_code);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
          Change your occupation and industry information here. NOTE: This will affect your investment projections.
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Autocomplete
            disablePortal
            id="occupation"
            options={occupations}
            name="occupation"
            fullWidth
            onChange={onOccupationChange}
            getOptionLabel={occupations => occupations.occupation_title}
            renderInput={(params) => <TextField {...params} required label="Desired Occupation" />}
          />
        </Box>
        <Box style={showIndustries ? {} : { display: 'none' }}>
          <Autocomplete
            disablePortal
            id="industry"
            options={industries}
            name="industry"
            fullWidth
            onChange={onIndustryChange}
            getOptionLabel={industries => industries.industry_title}
            renderInput={(params) => <TextField {...params} label="Desired Industry (Optional)" />}
          />
        </Box>
        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          sx={{ mt: 4 }}
          onClick={handleUpdateOccupation}
          disabled={occupation ? false : true}
        >
          Save Occupation
        </Button>
      </Box>
    </React.Fragment>
  );
};
