import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MuiTelInput } from 'mui-tel-input';
import { educatorAccount, checkType } from '../../../services/userService';
import { getStates } from '../../../services/generalService';
import { getInstitution, updateInstitutionAccount, updateInstitutionPhoto } from '../../../services/institutionService';
import { storageUrl } from '../../../constants/globals';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const Details = () => {
  const [formCompleted, setFormCompleted] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [states, setStates] = useState([]);
  const [institutionImage, setInstitutionImage] = useState(null);
  const [institutionData, setInstitutionData] = useState(null);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const imageUploader = React.useRef(null);

  const handleImageUpload = (event) => {
    const data = new FormData();
    data.append('image', event.target.files[0]);

    updateInstitutionPhoto(localStorage.getItem('token'), data).then(photoData => {
      if (photoData.status) {
        setInstitutionImage(storageUrl + photoData.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(photoData.message);
      }
    }).catch(err => {
      setOpenErrorModal(true);
      setOpenErrorModalText('There was an error uploading the image.');
    });
  };

  const onInstitutionNameChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.institution_name = event.target.value;
    setInstitutionData(data);
  }

  const onPhoneChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.phone = event.target.value;
    setInstitutionData(data);
  }

  const onDescriptionChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.description = event.target.value;
    setInstitutionData(data);
  }

  const onAddressOneChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.address_1 = event.target.value;
    setInstitutionData(data);
  }

  const onAddressTwoChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.address_2 = event.target.value;
    setInstitutionData(data);
  }

  const onCityChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.city = event.target.value;
    setInstitutionData(data);
  }

  const onStateChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.state = event.target.value;
    setInstitutionData(data);
  }

  const onPostalCodeChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.postal_code = event.target.value;
    setInstitutionData(data);
  }

  const onWebsiteChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.website = event.target.value;
    setInstitutionData(data);
  }

  const onPublicChange = (event, values) => {
    let data = structuredClone(institutionData);
    data.public = event.target.checked;
    setInstitutionData(data);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new URLSearchParams({
      'institution_name':institutionData?.institution_name,
      'phone':institutionData?.phone,
      'description':institutionData?.description,
      'public':institutionData?.public,
      'address_1':institutionData?.address_1,
      'address_2':institutionData?.address_2,
      'city':institutionData?.city,
      'state':institutionData?.state,
      'postal_code':institutionData?.postal_code,
      'website':institutionData?.website
    });

    updateInstitutionAccount(localStorage.getItem('token'), data).then(update => {
      setOpenErrorModal(true);
      setOpenErrorModalText(update.message);
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  React.useEffect(() => {
    if (institutionData?.institution_name && institutionData?.phone) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  });

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.user_level !== 1 || userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getInstitution(localStorage.getItem('token')).then(institutionData => {
      if (institutionData.status == true) {
        if (institutionData.data?.user_level !== 1) {
          setInstitutionData(institutionData.data);
          if (institutionData.data?.image) {
            setInstitutionImage(storageUrl + institutionData.data?.image);
          }
          if (institutionData.data.institution_name && institutionData.data.phone) {
            setFormCompleted(true);
          }
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(institutionData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getStates().then(states => {
      setStates(states.data);
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Paper sx={{ p: 2 }}>
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <Grid item sm={12} md={6}>
            <Typography color='secondary' variant='h4' component='div'>
              Institution Settings
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} align='right'>
            <Button
              variant='contained'
              disableElevation
              size='large'
              disabled={formCompleted ? false : true}
              onClick={handleSubmit}
            >
              Save Settings
            </Button>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              name="institution_name"
              id="institution_name"
              label="Institution Name"
              type="text"
              value={institutionData?.institution_name || ''}
              onChange={onInstitutionNameChange}
            />
            <MuiTelInput
              defaultCountry="US"
              required
              fullWidth
              sx={{ mt: 3 }}
              id="phone"
              value={institutionData?.phone || ''}
              onChange={onPhoneChange}
            />
            <TextField
              fullWidth
              id="description"
              label="Description (Max 1500 Characters)"
              name="description"
              type="description"
              sx={{ mt: 3 }}
              inputProps={{ maxLength: 1500 }}
              value={institutionData?.description || ''}
              onChange={onDescriptionChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>


            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none"
                }}
              />
              <div
                style={{
                  height: "210px",
                  width: "210px",
                  border: "1px dashed #777",
                  borderRadius: "5px",
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative"
                }}
                onClick={() => imageUploader.current.click()}
              >
                {!institutionImage && (
                  <>
                    <PhotoCameraIcon
                      fontSize="large"
                      style={{
                        position: "absolute",
                        top: "43%",
                        left: "43%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 0,
                        color: "#999",
                        transform: "scale(4)"
                      }}
                    />
                  </>
                )}
                {institutionImage && (
                  <>
                    <img
                      src={institutionImage}
                      style={{
                        height: "100%",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        zIndex: 1
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <Typography sx={{
              textAlign:'center',
              fontSize:'14px'
            }}>Your Logo</Typography>


          </Grid>
          <Grid item xs={12}>
            <Divider variant="fullWidth" sx={{mb:4}}>Address</Divider>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="address_1"
                  id="address_1"
                  label="Address 1"
                  type="text"
                  value={institutionData?.address_1 || ''}
                  onChange={onAddressOneChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="address_2"
                  id="address_2"
                  label="Address 2"
                  type="text"
                  value={institutionData?.address_2 || ''}
                  onChange={onAddressTwoChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt:1}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="city"
                  id="city"
                  label="City"
                  type="text"
                  value={institutionData?.city || ''}
                  onChange={onCityChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="state_label">State</InputLabel>
                  <Select
                    labelId="state_label"
                    id="state"
                    value={institutionData?.state || ''}
                    label="State"
                    onChange={onStateChange}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.code} value={state.code}>{state.name} - ({state.code})</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{mt:1}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="postal_code"
                  id="postal_code"
                  label="Postal Code"
                  type="number"
                  value={institutionData?.postal_code || ''}
                  onChange={onPostalCodeChange}
                />
              </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{mb:2, mt:3}}>Social</Divider>
            <Grid container spacing={3} sx={{mt:1}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="website"
                  id="website"
                  label="Website"
                  type="text"
                  value={institutionData?.website || ''}
                  onChange={onWebsiteChange}
                />
              </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{mb:2, mt:3}}>Public</Divider>
            <FormControlLabel
              control={<Checkbox
                value="allowPublic"
                color="primary"
                name="public"
                checked={institutionData?.public ? true : false}
                onChange={onPublicChange}
              />}
              label="Make your institution public."
            />
            <Typography sx={{fontSize:'12px'}}>This enables employees in your institution and yourself to be advertised to students in your area.</Typography>
          </Grid>
        </Grid>
        <Divider variant="fullWidth" sx={{ mt: 4, mb: 2 }} />
        <Grid container alignItems="right" justifyContent="right" spacing={3}>
          <Grid item align='right'>
            <Button
              variant='contained'
              disableElevation
              size='large'
              disabled={formCompleted ? false : true}
              onClick={handleSubmit}
            >
              Save Settings
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Details;
