import { apiUrl } from '../constants/globals';

export async function getAllUserPortfolioItems(token) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAllPublicUserPortfolioItems(userId) {
    try {
        const response = await fetch(apiUrl + '/students/public/portfolio/' + userId, {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserPortfolioItemById(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addPortfolioItem(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updatePortfolioItem(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updatePortfolioItemOrder(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/portfolioOrder/', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deletePortfolioItem(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getPortfolioItemDocuments(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/file/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getPublicPortfolioItemDocuments(userId, id) {
    try {
        const response = await fetch(apiUrl + '/students/public/portfolio/file/' + id + '/' + userId, {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function downloadPortfolioItemDocument(token, id, filename) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/file/download/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
    } catch(error) {
        return error;
    }
}

export async function downloadPublicPortfolioItemDocument(userId, id, filename) {
    try {
        const response = await fetch(apiUrl + '/students/public/portfolio/file/download/' + id + '/' + userId, {
          method: 'GET'
        });
        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
    } catch(error) {
        return error;
    }
}

export async function addPortfolioItemDocument(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/file/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deletePortfolioItemDocument(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/portfolio/file/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

