import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import AccountOverview from './overview/overview';
import EditPayments from './edit/payments/payments';
import EditSubscription from './edit/subscription/subscription';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { stripePublicKey } from '../../../../../constants/globals';

const stripePromise = loadStripe(stripePublicKey);

const AccountComponent = ({subscription, products, institution}) => {
  const [selectedPricingData, setSelectedPricingData] = React.useState({});
  const [selectedProductData, setSelectedProductData] = React.useState({});
  const [nextChargeDate, setNextChargeDate] = React.useState({});

  const [addPaymentPage, setAddPaymentPage] = React.useState(false);
  const [updateSubscriptionPage, setUpdateSubscriptionPage] = React.useState(false);

  React.useEffect(() => {
    products?.forEach(product => {
        if (product.id === subscription?.items.data[0].price.product) {
            setSelectedProductData(product);
            product.pricing.forEach(pricing => {
                if (pricing.id === subscription?.items.data[0].price.id) {
                    setSelectedPricingData(pricing);
                }
            });
        }
    });
    let date = new Date(subscription?.current_period_end * 1000);
    setNextChargeDate({
      day: date.toLocaleString("default", { day: "numeric" }),
      month: date.toLocaleString("default", { month: "long" }),
      year: date.toLocaleString("default", { year: "numeric" }),
    });
  }, []);

  return (
    <>
      <Typography color='secondary' variant='h4' component='div'>
          Your Subscription
      </Typography>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper sx={{ p: 2 }}>
        <Box sx={{ width: '100%' }}>
          {!addPaymentPage && !updateSubscriptionPage && (
            <AccountOverview
              subscription={subscription}
              selectedPricingData={selectedPricingData}
              selectedProductData={selectedProductData}
              nextChargeDate={nextChargeDate}
              setAddPaymentPage={setAddPaymentPage}
              setUpdateSubscriptionPage={setUpdateSubscriptionPage}
            />
          )}
          {addPaymentPage && (
            <Elements 
              stripe={stripePromise} 
              options={{
                mode: 'payment', 
                amount: subscription?.plan.amount,
                currency: 'usd',
                paymentMethodCreation: 'manual',
              }}
            >
              <EditPayments
                setAddPaymentPage={setAddPaymentPage}
                setUpdateSubscriptionPage={setUpdateSubscriptionPage}
                subscription={subscription}
              />
            </Elements>
          )}
          {updateSubscriptionPage && (
            <EditSubscription
              products={products}
              institution={institution}
              subscription={subscription}
              setAddPaymentPage={setAddPaymentPage}
              setUpdateSubscriptionPage={setUpdateSubscriptionPage}
            />
          )}
        </Box>
      </Paper>
    </>
  )
};

export default AccountComponent;