import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { getStudentLoans, getLoanRepaymentPlans, getLoanTypes } from '../../../../../services/loansService';

export function LoansComponent({student, selectedEducator, openLoans, setError, setErrorText}) {
    const [loans, setLoans] = React.useState([]);
    const [loanTypes, setLoanTypes] = React.useState([]);
    const [loanRepaymentPlans, setLoanRepaymentPlans] = React.useState([]);

    React.useEffect(() => {
        getStudentLoans(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(loans => {
            if (loans.status == true) {
                setLoans(loans.data);
                getLoanTypes(localStorage.getItem('token')).then(loanTypesData => {
                    if (loanTypesData.status == true) {
                        setLoanTypes(loanTypesData.data);
                    }
                });
                getLoanRepaymentPlans(localStorage.getItem('token')).then(loanRepaymentPlansData => {
                    if (loanRepaymentPlansData.status == true) {
                        setLoanRepaymentPlans(loanRepaymentPlansData.data);
                    }
                });
            } else {
                setError(true);
                setErrorText(loans.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
    }, [openLoans, selectedEducator]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Loans
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            {loans?.length > 0 ? (
                <>
                {loans?.map((loan, index) => (
                    <Container key={index} maxWidth={false} disableGutters={true}>
                        <Grid container>
                            <Typography color='secondary'>Loan Type:</Typography>&nbsp;<Typography color='primary'><b>{loanTypes.find(type => type.id === loan.type)?.name}</b></Typography>
                        </Grid>
                        <Grid container>    
                            <Typography color='secondary'>Repayment Plan:</Typography>&nbsp;<Typography color='primary'><b>{loanRepaymentPlans.find(plan => plan.id === loan.repayment_plan)?.name}</b></Typography>
                        </Grid>
                        <Grid container>    
                            <Typography variant='h5' color="primary" component='div' sx={{mb: 1, mt: 1}}>
                                <b>${loan.balance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</b>&nbsp;
                            </Typography>
                            <Typography variant='h5' color="secondary" component='div' sx={{mb: 1, mt: 1}}>
                                at&nbsp;
                            </Typography>
                            <Typography variant='h5' color="primary" component='div' sx={{mb: 1, mt: 1}}>
                                <b>{loan.interest_rate}% Interest</b>&nbsp;
                            </Typography>
                            <Typography variant='h5' color="secondary" component='div' sx={{mb: 1, mt: 1}}>
                                Over&nbsp;
                            </Typography>
                            <Typography variant='h5' color="primary" component='div' sx={{mb: 1, mt: 1}}>
                            <b>{loan.loan_term} Years</b>
                            </Typography>
                        </Grid>
                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    </Container>
                ))}
                </>
            ) : (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10 }}>
                    This student has not added any loans to their account.
                </Typography>
            )}
        </>
    )
}