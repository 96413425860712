import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Modal from '@mui/material/Modal';
import { storageUrl } from '../../../../constants/globals';
import { updateEmployee } from '../../../../services/educationActionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export function EmployeeEdit({employee, currentEducator}) {
    const [employeeLevel, setEmployeeLevel] = React.useState(employee?.user_level);
    const [employeeActive, setEmployeeActive] = React.useState(employee?.active === 1 ? true : false);
    const [employeePrimary, setEmployeePrimary] = React.useState(employee?.is_primary === 1 ? true : false);
    const [openErrorText, setOpenErrorText] = React.useState('');

    const onSetEmployeeLevel = (event, values) => {
        setEmployeeLevel(event.target.value);
    }

    const onSetEmployeeActive = (event, values) => {
        setEmployeeActive(event.target.checked);
    }

    const onSetEmployeePrimary = (event, values) => {
        setEmployeePrimary(event.target.checked);
    }

    const handleSubmit = (event) => {
      let data = new URLSearchParams({
        'active':employeeActive,
        'is_primary':employeePrimary,
        'user_level':employeeLevel
      });
      setOpenErrorText('');
  
      updateEmployee(localStorage.getItem('token'), employee?.id, data).then(update => {
        if (update?.status == true) {
          window.location.reload();
        } else {
          setOpenErrorText(update.message);
        }
      });
    };

    return (
        <Box sx={style}>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>

                    <div
                        style={{
                            height: "70px",
                            width: "70px",
                            border: "1px solid #777",
                            borderRadius: "200px",
                            overflow: "hidden",
                            cursor: "pointer",
                            position: "relative",
                            display:'block',
                            margin: 'auto'
                        }}
                    >
                        {!employee?.profile_image && (
                        <>
                            <PersonIcon
                                fontSize="large"
                                style={{
                                    position: "absolute",
                                    top: "34%",
                                    left: "26%",
                                    transform: "translateX(-50%) translateY(-50%)",
                                    zIndex: 0,
                                    color: "#999",
                                    transform: "scale(2.5)"
                                }}
                            />
                        </>
                        )}
                        {employee?.profile_image && (
                        <>
                            <img
                            src={storageUrl + employee?.profile_image}
                            style={{
                                height: "100%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translateX(-50%) translateY(-50%)",
                                zIndex: 1
                            }}
                            />
                        </>
                        )}
                    </div>

                <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                    Edit {employee?.first_name} {employee?.last_name}
                </Typography>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
                {currentEducator?.user_level === 2 && employee?.user_level === 1 ? (
                    <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                        You do not have permission to edit this employee.
                    </Typography>
                ) : (
                    <>
                        <Grid item xs={12} sx={{textAlign:'left'}}>
                            <FormControl fullWidth>
                                <InputLabel id="user_level">User Level *</InputLabel>
                                <Select
                                    labelId="user_level"
                                    id="user_level"
                                    label="User Level *"
                                    sx={{textAlign:'left'}}
                                    value={employeeLevel || ''}
                                    onChange={onSetEmployeeLevel}
                                >
                                    <MenuItem key='3' value='3'>Employee</MenuItem>
                                    <MenuItem key='2' value='2'>Manager</MenuItem>
                                    {currentEducator?.user_level === 1 && (
                                        <MenuItem key='1' value='1'>Owner</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                control={<Checkbox
                                    value="deactivate"
                                    color="primary"
                                    name="deactivate"
                                    onChange={onSetEmployeeActive}
                                    checked={employeeActive}
                                />}
                                label="Is employee active?"
                            />
                            {(employeeLevel === '1' || employeeLevel === 1) && currentEducator?.user_level === 1 && currentEducator?.is_primary === 1 && (
                                <FormControlLabel
                                    control={<Checkbox
                                        value="deactivate"
                                        color="primary"
                                        name="deactivate"
                                        onChange={onSetEmployeePrimary}
                                        checked={employeePrimary}
                                    />}
                                    label="Make this the primary owner. WARNING: This will remove you as the primary owner."
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant='contained'
                                disableElevation
                                size='large'
                                sx={{width:'100%'}}
                                onClick={handleSubmit}
                            >
                                Edit Employee
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    )
}