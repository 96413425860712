import React, { Suspense } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useParams } from "react-router-dom";
import { getAllPublicUserPortfolioItems } from '../../../services/portfolioService';
import { publicUserAccount } from '../../../services/userService';
import { storageUrl } from '../../../constants/globals';
import { PortfolioItemList } from '../../../components/global/portfolio/portfolioList';
import { PortfolioItemView } from '../../../components/global/portfolio/portfolioView';

const drawerStyle = {
    width: '60%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
}

const PublicPortfolio = () => {
    const [profileImage, setProfileImage] = React.useState(null);
    const [userData, setUserData] = React.useState({});
    const [portfolioData, setPortfolioData] = React.useState({});
    const [selectedPortfolioItem, setSelectedPortfolioItem] = React.useState({});
    const [openError, setOpenError] = React.useState(false);
    const [openErrorText, setOpenErrorText] = React.useState('');
    const [reloadList, setReloadList] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const handleCloseError = () => setOpenError(false);
    const { id } = useParams();
  
    const handleCloseDrawer = () => {
      setSelectedPortfolioItem({});
      setOpenDrawer(false);
    }
  
    const handleOpenDrawer = (portfolioItem) => {
      setSelectedPortfolioItem(portfolioItem);
      setOpenDrawer(true);
    }

    React.useEffect(() => {
        publicUserAccount(id).then(userData => {
        if (userData.status == true) {
          if (userData.data.educator_count === 0) {
            window.location.href = '/students/dashboard';
          }
          setUserData(userData.data);
          if (userData.data.profile_image !== null) {
            setProfileImage(storageUrl + userData.data.profile_image);
          }
          getAllPublicUserPortfolioItems(id).then(portfolioItems => {
            if (portfolioItems.status == true) {
              setPortfolioData(portfolioItems.data);
            } else {
              setOpenError(true);
              setOpenErrorText(portfolioItems.message);
            }
          });
        } else {
          setOpenError(true);
          setOpenErrorText(userData.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }, []);

    return (
        <>
            <Container sx={{ mt: 4, mb: 4 }} maxWidth='lg'>
                <Paper sx={{ p: 2 }}>
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item sm={12} md={12}>

                            <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            >
                            <div
                                style={{
                                height: "160px",
                                width: "160px",
                                border: "1px solid #777",
                                borderRadius: "200px",
                                overflow: "hidden",
                                cursor: "pointer",
                                position: "relative"
                                }}
                            >
                                {!profileImage && (
                                <>
                                    <PhotoCameraIcon
                                    fontSize="large"
                                    style={{
                                        position: "absolute",
                                        top: "43%",
                                        left: "43%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 0,
                                        color: "#999",
                                        transform: "scale(4)"
                                    }}
                                    />
                                </>
                                )}
                                {profileImage && (
                                <>
                                    <img
                                    src={profileImage}
                                    style={{
                                        height: "100%",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 1
                                    }}
                                    />
                                </>
                                )}
                            </div>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={12}>
                            <Typography color='secondary' align='center' variant='h4' component='div'>
                                {userData?.first_name} {userData?.last_name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Box sx={{ width: '100%' }}>
                        <Suspense fallback={<div>Loading...</div>}>
                            <PortfolioItemList
                                type={'public'}
                                student={userData}
                                portfolioItemList={portfolioData}
                                setPortfolioItemList={setPortfolioData}
                                setReloadList={setReloadList}
                                handleOpenDrawer={handleOpenDrawer}
                                setError={setOpenError}
                                setErrorText={setOpenErrorText}
                            />
                        </Suspense>
                    </Box>
                </Paper>
            </Container>
            <Snackbar
                open={openError}
                onClose={handleCloseError}
                autoHideDuration={6000}
            >
                <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                {openErrorText}
                </Alert>
            </Snackbar>
            <Drawer
                open={openDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <PortfolioItemView
                    type={'public'}
                    student={userData}
                    selectedPortfolioItem={selectedPortfolioItem}
                    handleCloseDrawer={handleCloseDrawer}
                    setReloadList={setReloadList}
                    setError={setOpenError}
                    setErrorText={setOpenErrorText}
                />
            </Drawer>
        </>
    );
}

export default PublicPortfolio;