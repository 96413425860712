import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { deleteInstitutionAdvertisement } from '../../../services/advertisementsService';
import { AdvertisementReports } from './advertisementReports';
import { storageUrl } from '../../../constants/globals';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const drawerStyle = {
    width: '80%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
}

export function AdvertisementListItem({advertisement, setReloadList, setShowAdvertisement, setSelectedAdvertisement, setError, setErrorText}) {
    const [openDeleteAdvertisementModal, setOpenDeleteAdvertisementModal] = React.useState(false);
    const [openReportsDrawer, setOpenReportsDrawer] = React.useState(false);

    const handleCloseDrawer = () => {
        setOpenReportsDrawer(false);
    }
  
    const handleOpenReportsDrawer = () => {
        setOpenReportsDrawer(true);
    };

    const handleCloseDeleteAdvertisementModal = () => {
        setOpenDeleteAdvertisementModal(false);
    };
    
    const editAdvertisement = () => {
        setShowAdvertisement(true);
        setSelectedAdvertisement(advertisement);
    }

    const deleteAdvertisement = () => {
        deleteInstitutionAdvertisement(localStorage.getItem('token'), advertisement?.id).then(advertisement => {
            if (advertisement.status) {
                setReloadList(advertisement);
                handleCloseDeleteAdvertisementModal();
            } else {
                setError(true);
                setErrorText('There was an error deleting the advertisement.');
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error deleting the advertisement.');
        });
    };
    
    return (
        <>
            <Paper sx={{ p: 0 }}>
                {advertisement?.image && (
                    <Box
                        component="div"
                        onClick={() => handleOpenReportsDrawer({})}
                        sx={{
                            maxWidth: '100%',
                            maxHeight: '210px',
                            overflow:'hidden',
                            borderRadius: '4px 4px 0 0'
                        }}
                    >
                        <Box
                            component="img"
                            sx={{
                                maxWidth: '100%',
                                cursor:'pointer',
                                borderRadius: '4px 4px 0 0'
                            }}
                            alt={advertisement?.headline}
                            src={storageUrl + advertisement?.image}
                        />
                    </Box>
                )}
                <Box
                    sx={{p: 1}}
                >
                    <Button
                        variant='text'
                        size='large'
                        color={'primary'}
                        onClick={() => handleOpenReportsDrawer({})}
                        sx={{
                            display: 'block',
                            padding:'0px',
                            fontWeight: 'bold',
                            width:'100%',
                            textAlign:'left',
                            fontSize: '22px'
                        }}
                    >
                        {advertisement?.headline}
                        <AssessmentIcon 
                            sx={{
                                margin: '0px 0px -5px 5px'
                            }}
                        />
                    </Button>
                    <Typography color='secondary'>
                        Price Per Click: <b>${advertisement?.price_per_click}</b>
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    {(!advertisement?.approved && !advertisement?.disapproved_reason) && (
                        <Chip
                            label='Pending Approval'
                            size='small'
                            color='primary'
                            sx={{color: '#fff', mr: 1, fontSize:'10px'}}
                        />
                    )}
                    {(!advertisement?.approved && advertisement?.disapproved_reason !== null) && (
                        <Tooltip 
                            arrow 
                            title={advertisement?.disapproved_reason}
                        >
                            <Chip
                                label='Disapproved'
                                size='small'
                                sx={{backgroundColor: '#FF0000', color: '#fff', mr: 1, fontSize:'10px'}}
                            />
                        </Tooltip>
                    )}
                    {(advertisement?.approved === 1 && !advertisement?.disapproved_reason) && (
                        <Chip
                            label='Approved'
                            size='small'
                            sx={{backgroundColor: '#007500', color: '#fff', mr: 1, fontSize:'10px'}}
                        />
                    )}
                    <Chip
                        label={advertisement?.approved ? 'Active' : 'Inactive'}
                        size='small'
                        color='secondary'
                        sx={{mr: 1, fontSize:'10px'}}
                    />
                </Box>
                <Grid container alignItems="center" justifyContent="center" spacing={0} sx={{mt:1}}>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<EditIcon />}
                            color='primary'
                            sx={{
                                width:'100%',
                                borderRadius: '0px 0px 0px 4px !important'
                            }}
                            onClick={editAdvertisement}
                        >
                            Edit
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button 
                            variant="contained" 
                            startIcon={<DeleteIcon />}
                            color='secondary'
                            sx={{
                                width:'100%',
                                borderRadius: '0px 0px 4px 0px !important'
                            }}
                            onClick={setOpenDeleteAdvertisementModal}
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
                <Modal
                open={openDeleteAdvertisementModal}
                onClose={handleCloseDeleteAdvertisementModal}
                >
                    <Box sx={style}>
                        <Grid container alignItems="center" justifyContent="center" spacing={3}>
                            <Grid item xs={12}>
                                <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                                    Are you sure you want to delete this advertisement?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    sx={{width:'100%'}}
                                    onClick={deleteAdvertisement}
                                >
                                    Delete Advertisement
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Paper>
            <Drawer
                open={openReportsDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <AdvertisementReports
                    advertisement={advertisement}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
        </>
    )
}