import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AdvertisementAdd } from './advertisementAdd';
import { AdvertisementDisplay } from './advertisementDisplay';

export function AdvertisementView({institution, advertisement, setAdvertisement, type, setShowAdvertisement, setSelectedAdvertisement, setError, setErrorText, setReloadList, handleCloseAddAdvertisementDrawer, setOpenSuccess}) { 
    const [sidebarWidth, setSidebarWidth] = useState(undefined);
    const [sidebarTop, setSidebarTop] = useState(undefined);
    const [sidebarBottom, setSidebarBottom] = useState(undefined);
    const [adHeight, setAdHeight] = useState(undefined);
    const [styles, setStyles] = useState({});
 
    React.useEffect(() => {
        const sidebarFixedEl = document.querySelector('.sidebar-fixed').getBoundingClientRect();
        const sidebarContentEl = document.querySelector('.sidebar-content').getBoundingClientRect();
        const sidebarEl = document.querySelector('.sidebar').getBoundingClientRect();
        const adEl = document.querySelector('.advertisement').getBoundingClientRect();
        setSidebarWidth(sidebarFixedEl.width);
        setSidebarTop(sidebarContentEl.top);
        setSidebarBottom(sidebarContentEl.bottom);
        setAdHeight(adEl.height);
    });
 
    React.useEffect(() => {
        if (!sidebarTop) return;

        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    }, [sidebarTop]);
 
    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        if((scrollTop - 100) >= sidebarBottom) {
            setStyles({
                '@media(min-width: 600px)': {
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: sidebarWidth,
                }
            });
        } else if (scrollTop >= sidebarTop - 10) {
            setStyles({
                '@media(min-width: 600px)': {
                    position: 'fixed',
                    top: '10px',
                    zIndex: '999',
                    animation: '500ms ease-in-out 0s normal none 1 running fadeInDown',
                    width: sidebarWidth,
                    right: '24px'
                }
            });
        } else {
            setStyles({});
        }
    }

    const goBackToList = () => {
        setShowAdvertisement(false);
        setSelectedAdvertisement(null);
    }
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Link onClick={goBackToList} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To All {type.charAt(0).toUpperCase() + type.slice(1) + 's'} 
                    </Link>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                </Grid>
            </Grid>
            <Grid 
                container 
                spacing={3}
                sx={{
                    position: 'relative',
                    '@media(max-width: 600px)': {
                        flexDirection: 'column-reverse !important'
                    }
                }}
                className='sidebar-container'
            >
                <Grid item xs={12} sm={8} className='sidebar-content'>
                    <Paper sx={{ p: 1 }}>
                        <AdvertisementAdd 
                            institution={institution}
                            advertisement={advertisement}
                            setAdvertisement={setAdvertisement}
                            setError={setError}
                            setErrorText={setErrorText}
                            type={type}
                            setReloadList={setReloadList}
                            handleCloseAddAdvertisementDrawer={handleCloseAddAdvertisementDrawer}
                            setOpenSuccess={setOpenSuccess}
                        />
                    </Paper>
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={4}
                    sx={styles}
                    className='sidebar'
                >
                    <AdvertisementDisplay 
                        advertisement={advertisement}
                        selectedCategory={null}
                        type={'preview'}
                        typeTitle={type.charAt(0).toUpperCase() + type.slice(1)}
                        setError={setError}
                        setErrorText={setErrorText}
                    />
                </Grid>
                <Grid 
                    item 
                    xs={12} 
                    sm={4}
                    className='sidebar-fixed'
                >
                </Grid>
            </Grid>
        </>
    );
}