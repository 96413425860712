import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';

export function AdvertisementAddCategories({categoryRegions, categoryOccupations, categoryInstitutions, categories, setCategories, handleCloseAddCategoryModal}) {
    const [categoryBuilder, setCategoryBuilder] = React.useState([{
        type: 'location',
        id: null,
        cities: [],
        occupations: [],
        institutions: []
    }]);

    React.useEffect(() => {
        setCategoryBuilder([{
            type: 'location',
            id: null,
            cities: [],
            occupations: [],
            institutions: []
        }]);
    }, []);

    const onTypeChange = (event, id) => {
        let clone = [...categoryBuilder];

        clone[id].type = event.target.value;
        clone[id].id = null;
        clone[id].cities = [];
        clone[id].occupations = [];
        clone[id].institutions = [];
        if (id === 0) {
            clone.splice(1);
        }
        setCategoryBuilder(clone);
    };
    
    const onSearchOptionChange = (event, values, id) => {
        let clone = [...categoryBuilder];
        clone[id].id = null;
        clone[id].cities = [];
        clone[id].occupations = [];
        clone[id].institutions = [];
        if (values?.id) {
            clone[id].id = values.id;
            if (id === 0) {
                clone.push({
                    type: null,
                    id: null,
                    cities: [],
                    occupations: [],
                    institutions: []
                });
            }
        } else {
            if (id === 0) {
                clone.splice(1);
            }
        }
        setCategoryBuilder(clone);
    }
    
    const onSearchOptionChangeMultiple = (event, values, id, category) => {
        let clone = [...categoryBuilder];
        if (values?.id) {
            if (clone[id][category?.type + 's'] !== undefined) {
                clone[id][category?.type + 's'].push(values.id);
            } else {
                clone[id][category?.type + 's'] = values.id;
            }
        }
        setCategoryBuilder(clone);
    }
    
    const onSearchOptionRemove = (option, id, category) => {
        let clone = [...categoryBuilder];
        let index = clone[id][category?.type + 's'].indexOf(option);
        if (index !== -1) {
            clone[id][category?.type + 's'].splice(index, 1);
        }
        setCategoryBuilder(clone);
    }
    
    const onSearchCitiesChange = (event, values, id) => {
        let clone = [...categoryBuilder];
        if (values?.id) {
            if (clone[id].cities !== undefined) {
                clone[id].cities.push(values.id);
            } else {
                clone[id].cities = values.id;
            }
        }
        setCategoryBuilder(clone);
    }
    
    const onRemoveCity = (city, id) => {
        let clone = [...categoryBuilder];
        let index = clone[id]?.cities.indexOf(city);
        if (index !== -1) {
            clone[id]?.cities.splice(index, 1);
        }
        setCategoryBuilder(clone);
    }

    const getSearchList = (id) => {
        if (categoryBuilder[id].type === 'location') {
            return categoryRegions;
        } else if (categoryBuilder[id].type === 'occupation') {
            return categoryOccupations;
        } else if (categoryBuilder[id].type === 'institution') {
            return categoryInstitutions;
        }
    }

    const isDisabled = () => {
        if (
            (
                categoryBuilder[0].id !== null && 
                categoryBuilder[0].type !== null
            ) && 
            (
                categoryBuilder[1]?.type === null && 
                categoryBuilder[1]?.id === null && 
                categoryBuilder[1]?.cities.length === 0 && 
                categoryBuilder[1]?.occupations.length === 0 && 
                categoryBuilder[1]?.institutions.length === 0
            )
        ) {
            return false;
        }
        if (
            (
                categoryBuilder[0].id !== null && 
                categoryBuilder[0].type !== null
            ) && 
            (
                categoryBuilder[1]?.type !== null && 
                (
                    categoryBuilder[1]?.id !== null || 
                    categoryBuilder[1]?.cities.length !== 0 || 
                    categoryBuilder[1]?.occupations.length !== 0 || 
                    categoryBuilder[1]?.institutions.length !== 0
                )
            )
        ) {
            return false;
        }
        return true;
    }

    const addCategory = () => {
        let clone = [...categories];
        let category = {
            category_type: categoryBuilder[0].type,
            category_id: categoryBuilder[0].id,
            category_multiple: categoryBuilder[0].cities,
            sub_category_type: null,
            sub_category_id: null,
            sub_category_multiple: null
        };
        if (categoryBuilder[1]?.type !== null) {
            let multiple = [];
            if (categoryBuilder[1].cities.length > 0) {
                multiple = categoryBuilder[1].cities;
            }
            if (categoryBuilder[1].occupations.length > 0) {
                multiple = categoryBuilder[1].occupations;
            }
            if (categoryBuilder[1].institutions.length > 0) {
                multiple = categoryBuilder[1].institutions;
            }
            category.sub_category_type = categoryBuilder[1].type;
            category.sub_category_id = categoryBuilder[1].id;
            category.sub_category_multiple = multiple;
        }
        clone.push(category);
        setCategories(clone);
        handleCloseAddCategoryModal()
    }

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{textAlign:'left'}}>
            <Grid item xs={12}>
                <Typography color='secondary' variant='h5' component='div' sx={{textAlign:'center'}}>
                    Add to a Category
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2 }} />
            </Grid>
            {categoryBuilder?.map((category, index) => (
                <>
                    <Grid item xs={12}>
                        <Typography color='secondary' variant='h6' component='div'>
                            {index === 0 ? '1. Start With a Main Category' : '2. Add Sub Category (Optional)'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{pt: '15px !important'}}>
                        <FormControl fullWidth>
                            <InputLabel id={`type-${index}`}>Type</InputLabel>
                            <Select
                                labelId={`type-${index}`}
                                id={`type-${index}`}
                                label="Type"
                                value={category.type}
                                onChange={(event) => onTypeChange(event, index)}
                                MenuProps={{
                                    style: {zIndex: 99999}
                                }}
                            >
                                {index !== 0 && (
                                    <MenuItem value={null}>None</MenuItem>
                                )}
                                {(index === 0 || (index !== 0 && (categoryBuilder[0].type === 'occupation' || categoryBuilder[0].type === 'institution'))) && (
                                    <MenuItem value={'location'}>Location</MenuItem>
                                )}
                                {(index === 0 || (index !== 0 && categoryBuilder[0].type === 'location')) && (
                                    <MenuItem value={'occupation'}>Occupation</MenuItem>
                                )}
                                {(index === 0 || (index !== 0 && categoryBuilder[0].type === 'location')) && (
                                    <MenuItem value={'institution'}>School or Institution</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    {category.type !== null && (
                        <>
                            {(index === 0 || (categoryBuilder[0].type === 'occupation' || categoryBuilder[0].type === 'institution')) ? (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        disablePortal
                                        id={`categories-${index}`}
                                        options={getSearchList(index)}
                                        name={`categories-${index}`}
                                        fullWidth
                                        onChange={(event, values) => onSearchOptionChange(event, values, index)}
                                        getOptionLabel={(option) => option ? option.name : ''}
                                        renderInput={(params) => <TextField {...params} label="Search..." />}
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            disablePortal
                                            id={`categories-${index}`}
                                            options={getSearchList(index)}
                                            name={`categories-${index}`}
                                            fullWidth
                                            onChange={(event, values) => onSearchOptionChangeMultiple(event, values, index, category)}
                                            getOptionLabel={(option) => option ? option.name : ''}
                                            renderInput={(params) => <TextField {...params} label="Search..." />}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {category.type === 'occupation' && (
                                            <>
                                                {category.occupations?.map((occupation) => (
                                                    <Chip
                                                        label={categoryOccupations?.find(catOcc => catOcc.id == occupation)?.name}
                                                        size='small'
                                                        onDelete={() => onSearchOptionRemove(occupation, index, category)}
                                                        sx={{mr:'5px', mb: '5px'}}
                                                    />
                                                ))}
                                            </>
                                        )}
                                        {category.type === 'institution' && (
                                            <>
                                                {category.institutions?.map((institution) => (
                                                    <Chip
                                                        label={categoryInstitutions?.find(catInst => catInst.id == institution)?.name}
                                                        size='small'
                                                        onDelete={() => onSearchOptionRemove(institution, index, category)}
                                                        sx={{mr:'5px', mb: '5px'}}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {(category.id !== null && category.type === 'location') && (
                        <>
                            <Grid item xs={12} sx={{pt:'10px !important'}}>
                                <Tooltip 
                                    arrow 
                                    title='If no cities or towns are chosen we will display this state-wide.'
                                    PopperProps={{style:{zIndex:9999}}}
                                >
                                    <Typography color='secondary' component='div' sx={{textAlign:'center'}}>
                                        Target specific cities/towns in {categoryRegions?.find(region => region.id === category.id)?.name}
                                        <HelpIcon
                                            fontSize='6px'
                                            color='secondary'
                                            sx={{ml:'5px', mb:'-2px'}}
                                        />
                                    </Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6} sx={{pt:'10px !important'}}>
                                <Autocomplete
                                    disablePortal
                                    id={`cities-${index}`}
                                    options={categoryRegions?.find(region => region.id === category.id)?.cities}
                                    name={`cities-${index}`}
                                    fullWidth
                                    onChange={(event, values) => {onSearchCitiesChange(event, values, index)}}
                                    getOptionLabel={(option) => option ? option.name : ''}
                                    renderInput={(params) => <TextField {...params} label="Search..." />}
                                />
                            </Grid>
                            <Grid item xs={6} sx={{pt:'10px !important'}}>
                                {category.cities?.map((city) => (
                                    <Chip
                                        label={categoryRegions?.find(region => region.id === category.id)?.cities?.find(region => region.id === city)?.name}
                                        size='small'
                                        onDelete={() => onRemoveCity(city, index)}
                                        sx={{mr:'5px', mb: '5px'}}
                                    />
                                ))}
                            </Grid>
                        </>
                    )}
                </>
            ))}
            <Grid item xs={12}>
                <Divider variant="fullWidth" sx={{ mb: 2 }} />
                <Button
                    variant='contained'
                    disableElevation
                    size='large'
                    sx={{width:'100%'}}
                    disabled={isDisabled()}
                    onClick={() => addCategory()}
                >
                    Add Category
                </Button>
            </Grid>
        </Grid>
    )
}