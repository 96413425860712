import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import MailIcon from '@mui/icons-material/Mail';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import { OverviewComponent } from './educatorViewComponents/overviewComponent';
import { TasksComponent } from './educatorViewComponents/tasksComponent';
import { MessagesComponent } from './educatorViewComponents/messagesComponent';
import { DocumentsComponent } from './educatorViewComponents/documentsComponent';
import { SuggestionsComponent } from './educatorViewComponents/suggestionsComponent';
import { activateEducators, deactivateEducators } from '../../../../services/studentActionService';

const navigationStyle = {
    '@media (max-width: 899px)': {
        display: 'block',
        float: 'right'
    },
    'display': 'none'
}

const sidebarStyle = {
    '@media (max-width: 899px)': {
        display: 'none'
    },
    borderLeft: '1px solid #e0e0e0',
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function EducatorView({user, educator, setViewEducator}) {
    const [openOverview, setOpenOverview] = React.useState(true);
    const [openTasks, setOpenTasks] = React.useState(false);
    const [openMessages, setOpenMessages] = React.useState(false);
    const [openDocuments, setOpenDocuments] = React.useState(false);
    const [openSuggestions, setOpenSuggestions] = React.useState(false);
    const [openPopper, setOpenPopper] = React.useState(null);
    const [isActive, setIsActive] = React.useState(1);
    const [deactivateModal, setDeactivateModal] = React.useState(false);
    const [activateModal, setActivateModal] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const handleCloseDeactivateModal = () => setDeactivateModal(false);
    const handleCloseActivateModal = () => setActivateModal(false);
    const [drawerState, setDrawerState] = React.useState(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    React.useEffect(() => {
        setIsActive(educator?.active);
    }, []);

    const open = Boolean(openPopper);

    const handleClickPopper = (event, task) => {
        setOpenPopper(event.currentTarget);
    };

    const handleClosePopper = () => {
        setOpenPopper(null);
    };

    const handleOpenDeactivateModal = () => {
        setDeactivateModal(true);
        handleClosePopper();
    };

    const handleOpenActivateModal = () => {
        setActivateModal(true);
        handleClosePopper();
    };

    const toggleDrawer = (direction) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerState(direction);
    };

    const handleDeactivateEducator = () => {
        let data = new URLSearchParams({
            'id' : educator?.id
        });
        deactivateEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                setIsActive(0);
                setErrorText('');
                handleCloseDeactivateModal();
            } else {
                setErrorText(update.message);
            }
        });
    };

    const handleActivateEducator = () => {
        let data = new URLSearchParams({
            'id' : educator?.id
        });
        activateEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                setIsActive(1);
                setErrorText('');
                handleCloseActivateModal();
            } else {
                setErrorText(update.message);
            }
        });
    };

    const handleNavigationChange = (page) => {
        setOpenOverview(false);
        setOpenMessages(false);
        setOpenTasks(false);
        setOpenDocuments(false);
        setOpenSuggestions(false);

        if (page === 'overview') {
            setOpenOverview(true);
        }

        if (page === 'messages') {
            setOpenMessages(true);
        }

        if (page === 'tasks') {
            setOpenTasks(true);
        }

        if (page === 'documents') {
            setOpenDocuments(true);
        }

        if (page === 'suggestions') {
            setOpenSuggestions(true);
        }
    };

    const navigationList = (
        <Box
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List sx={{pt:0, pb:0}}>
            <ListItemButton
                selected={openOverview}
                onClick={() => handleNavigationChange('overview')}
            >
                <ListItemIcon>
                    <LineAxisIcon />
                </ListItemIcon>
                <ListItemText primary='Overview' />
            </ListItemButton>
            <ListItemButton
                selected={openMessages}
                onClick={() => handleNavigationChange('messages')}
                disabled={(user?.account_locked || user?.account_soft_locked) ? true : false}
            >
                <ListItemIcon>
                    <MailIcon />
                </ListItemIcon>
                <ListItemText primary='Messages' />
            </ListItemButton>
            <ListItemButton
                selected={openTasks}
                onClick={() => handleNavigationChange('tasks')}
                disabled={(user?.account_locked || user?.account_soft_locked) ? true : false}
            >
                <ListItemIcon>
                    <FormatListNumberedIcon />
                </ListItemIcon>
                <ListItemText primary='Tasks' />
            </ListItemButton>
            <ListItemButton
                selected={openDocuments}
                onClick={() => handleNavigationChange('documents')}
                disabled={(user?.account_locked || user?.account_soft_locked) ? true : false}
            >
                <ListItemIcon>
                    <FilePresentIcon />
                </ListItemIcon>
                <ListItemText primary='Documents' />
            </ListItemButton>
            <ListItemButton
                selected={openSuggestions}
                onClick={() => handleNavigationChange('suggestions')}
                disabled={(user?.account_locked || user?.account_soft_locked) ? true : false}
            >
                <ListItemIcon>
                    <LightbulbIcon />
                </ListItemIcon>
                <ListItemText primary='Suggestions' />
                <Chip 
                    label="BETA"
                    color='primary'
                    size='small'
                    sx={{
                        color: 'white',
                    }}
                />
            </ListItemButton>
          </List>
        </Box>
    );

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Link onClick={() => setViewEducator(false)} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To All Educators
                    </Link>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 4 }} />
            <Paper sx={{p: 2}}>
                


                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item xs={12} sm={9}>
                        <Typography color='secondary' variant='h4' component='div'>
                            {educator?.first_name} {educator?.last_name} {isActive === 0 && ('(Inactive)')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3} textAlign={'right'}>
                        <IconButton aria-label="delete" onClick={(event) => handleClickPopper(event)}>
                            <MoreVertIcon />
                        </IconButton>
                        <Popover
                            open={open}
                            anchorEl={openPopper}
                            onClose={handleClosePopper}
                            elevation={0}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                {isActive === 1 && (
                                    <Button
                                        underline='none'
                                        color='inherit'
                                        sx={{
                                            width:'150px',
                                            display:'block',
                                            p: 1,
                                            border: '1px solid #ccc',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#FF0000',
                                                color: '#fff',
                                            },
                                        }}
                                        size='small'
                                        onClick={handleOpenDeactivateModal}
                                    >
                                        Deactivate Educator
                                    </Button>
                                )}
                                {isActive === 0 && (
                                    <Tooltip title={educator?.deactivated_by !== user?.id ? 'This educator removed you from their network. You cannot re-add them. You will need to contact them to reactivate you.' : ''} arrow>
                                        <Button
                                            underline='none'
                                            color='inherit'
                                            sx={{
                                                width:'150px',
                                                display:'block',
                                                p: 1,
                                                border: '1px solid #ccc',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#03C04A',
                                                    color: '#fff',
                                                },
                                            }}
                                            size='small'
                                            disabled={educator?.deactivated_by !== user?.id ? true : false}
                                            onClick={handleOpenActivateModal}
                                        >
                                            Activate Educator
                                        </Button>
                                    </Tooltip>
                                )}
                            </Box>
                        </Popover>
                    </Grid>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container spacing={0}>
                    <Grid 
                        item 
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        <IconButton
                            size="small"
                            sx={navigationStyle}
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon sx={{ fontSize: '30px'}} />
                        </IconButton>
                        {openOverview && (
                            <OverviewComponent
                                user={user}
                                educator={educator}
                            />
                        )}
                        {openMessages && (
                            <MessagesComponent
                                user={user}
                                educator={educator}
                                setOpenErrorModal={setOpenErrorModal}
                                setOpenErrorModalText={setOpenErrorModalText}
                            />
                        )}
                        {openTasks && (
                            <TasksComponent
                                user={user}
                                educator={educator}
                                setOpenErrorModal={setOpenErrorModal}
                                setOpenErrorModalText={setOpenErrorModalText}
                            />
                        )}
                        {openDocuments && (
                            <DocumentsComponent
                                user={user}
                                educator={educator}
                                setOpenErrorModal={setOpenErrorModal}
                                setOpenErrorModalText={setOpenErrorModalText}
                            />
                        )}
                        {openSuggestions && (
                            <SuggestionsComponent
                                user={user}
                                educator={educator}
                                setOpenErrorModal={setOpenErrorModal}
                                setOpenErrorModalText={setOpenErrorModalText}
                            />
                        )}
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        sx={sidebarStyle}
                    >
                        {navigationList}
                    </Grid>
                </Grid>

            </Paper>
            <Modal
                open={deactivateModal}
                onClose={handleCloseDeactivateModal}
            >
                <Box sx={style}>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                    <Typography variant="h6" component="h2">
                        Are you sure you want to remove this educator from your network?
                    </Typography>
                    <Grid container spacing={2} sx={{mt:1}}>
                        <Grid item xs={12} sm={6}>
                            <Button 
                                variant='contained'
                                color='primary'
                                disableElevation
                                sx={{width:'100%'}}
                                onClick={handleCloseDeactivateModal}
                            >
                                No, Go Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={'right'}>
                            <Button 
                                variant='outlined'
                                color='secondary'
                                sx={{width:'100%'}}
                                onClick={handleDeactivateEducator}
                            >
                                Deactivate
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={activateModal}
                onClose={handleCloseActivateModal}
            >
                <Box sx={style}>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                    <Typography variant="h6" component="h2">
                        Are you sure you want to reactivate this educator into your network? This educator will be able to interact with you again.
                    </Typography>
                    <Grid container spacing={2} sx={{mt:1}}>
                        <Grid item xs={12} sm={6}>
                            <Button 
                                variant='outlined'
                                color='secondary'
                                sx={{width:'100%'}}
                                onClick={handleCloseActivateModal}
                            >
                                No, Go Back
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={'right'}>
                            <Button 
                                variant='contained'
                                color='primary'
                                sx={{width:'100%'}}
                                onClick={handleActivateEducator}
                                disableElevation
                            >
                                Activate
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                </Typography>
                </Box>
            </Modal>
            <Drawer
                open={drawerState}
                onClose={toggleDrawer(false)}
                anchor='right'
                sx={{zIndex: 99999}}
                PaperProps={{
                    sx: {
                        width: '300px',
                    }
                }}
            >
                {navigationList}
            </Drawer>
        </>
    )
}