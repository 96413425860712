import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { getFavorites, addFavorite, deleteFavorite } from '../../../services/favoriteService';

export function AddRemoveFavorite({allFavorites, setAllFavorites, potentialFavorite, size, setError, setErrorText, type}) {
    const [isFavorited, setIsFavorited] = React.useState({});

    React.useEffect(() => {
        allFavorites.filter(function (favorite) {
            if (
                favorite.id === potentialFavorite.id && 
                favorite.type === potentialFavorite.type
            ) {
                setIsFavorited(favorite);
            }
        });
    }, [allFavorites, setAllFavorites]);

    const handleAddFavorite = () => {
        let data = new URLSearchParams({
            'item_id':potentialFavorite?.id,
            'type':potentialFavorite?.type
        });

        addFavorite(localStorage.getItem('token'), data).then(favorite => {
            if (favorite?.status === true) {
                setIsFavorited({
                    title: potentialFavorite?.title,
                    type: potentialFavorite?.type,
                    id: potentialFavorite?.id
                });
                let selectedType = type;
                if (!selectedType) {
                    selectedType = '*';
                }
                getFavorites(localStorage.getItem('token'), selectedType).then(newFavorites => {
                    setAllFavorites(newFavorites.data);
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error.');
                });
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error.');
        });
    };

    const handleDeleteFavorite = () => {
        let selectedType = type;
        if (!selectedType) {
            selectedType = '*';
        }
        getFavorites(localStorage.getItem('token'), selectedType).then(favorites => {
            favorites.data?.filter(function (favorite) {
                if (
                    favorite.id === potentialFavorite.id && 
                    favorite.type === potentialFavorite.type
                ) {
                    setAllFavorites(favorites.data);
                    deleteFavorite(localStorage.getItem('token'), favorite?.favorite_id).then(deletedFavorite => {
                        if (deletedFavorite?.status === true) {
                            setIsFavorited({});
                            getFavorites(localStorage.getItem('token'), selectedType).then(newFavorites => {
                                setAllFavorites(newFavorites.data);
                            }).catch(err => {
                                setError(true);
                                setErrorText('There was an error.');
                            });
                        } else {
                            setError(true);
                            setErrorText(deletedFavorite?.message);
                        }
                    }).catch(err => {
                        setError(true);
                        setErrorText('There was an error.');
                    });
                }
            });
        }).catch(err => {
            setError(true);
            setErrorText('There was an error.');
        });
    }

    return (
        <>
            {Object.keys(isFavorited).length !== 0 ? (
                <Tooltip 
                    title={isFavorited?.title ? 'Unfavorite ' + isFavorited?.title : 'Unfavorite'}
                    PopperProps={{style:{zIndex:9999}}}
                    arrow
                >
                    <IconButton 
                        color="primary"
                        size={size}
                        onClick={handleDeleteFavorite}
                    >
                        <StarIcon size={size} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip 
                    title={potentialFavorite?.title ? 'Favorite ' + potentialFavorite?.title : 'Favorite'}
                    PopperProps={{style:{zIndex:9999}}}
                    arrow
                >
                    <IconButton 
                        color="primary"
                        size={size}
                        onClick={handleAddFavorite}
                    >
                        <StarBorderIcon size={size} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )
}