import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import TerminalIcon from '@mui/icons-material/Terminal';
import WorkIcon from '@mui/icons-material/Work';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Popover from '@mui/material/Popover';
import StarIcon from '@mui/icons-material/Star';
import { getCareerTestResults } from '../../../services/careerPickerService';
import { getEducationTestResults } from '../../../services/educationPickerService';
import { educationPickerDisclaimer } from '../../../components/global/educationPickerDisclaimer';
import { careerPickerDisclaimer } from '../../../components/global/careerPickerDisclaimer';
import { getOccupationsList } from '../../../services/employmentService';
import { getInstitutionsList } from '../../../services/educationService';
import { updateInstitution } from '../../../services/educationService';
import { updateOccupation } from '../../../services/employmentService';
import { addFavorite } from '../../../services/favoriteService';
import { Link } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'scroll'
};

export function TestResultsModal({user, setError, setErrorText, handleTestChoiceClose}) {
    const [occupations, setOccupations] = React.useState([]);
    const [institutions, setInstitutions] = React.useState([]);
    const [needsChosenCareer, setNeedsChosenCareer] = React.useState(false);
    const [needsChosenEducation, setNeedsChosenEducation] = React.useState(false);
    const [chosenCareer, setChosenCareer] = React.useState(false);
    const [chosenEducation, setChosenEducation] = React.useState(false);
    const [hardChosenCareer, setHardChosenCareer] = React.useState(false);
    const [hardChosenEducation, setHardChosenEducation] = React.useState(false);
    const [showBurst, setShowBurst] = React.useState(true);
    const [careerTestResults, setCareerTestResults] = React.useState([]);
    const [numberCareerSuggestion, setNumberCareerSuggestion] = React.useState(0);
    const [favoritedCareerSuggestions, setFavoritedCareerSuggestions] = React.useState([]);
    const [educationTestResults, setEducationTestResults] = React.useState([]);
    const [numberEducationSuggestion, setNumberEducationSuggestion] = React.useState(0);
    const [favoritedEducationSuggestions, setFavoritedEducationSuggestions] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowBurst(false);
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    React.useEffect(() => {
        getOccupationsList().then(getOccupationsList => {
          setOccupations(getOccupationsList.data);
        });
        getInstitutionsList('*').then(getInstitutionsList => {
            setInstitutions(getInstitutionsList.data);
        });
        if (user?.investments[0].occupation === "0") {
            setNeedsChosenCareer(true);
            getCareerTestResults(localStorage.getItem('token')).then(results => {
              if (results.status == true) {
                setCareerTestResults(results.data);
              } else {
                setError(true);
                setErrorText(results.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
        } else {
            setChosenCareer(user.investments[0].occupation);
            setHardChosenCareer(true);
        }
        if (user?.investments[0].institution === "0") {
            setNeedsChosenEducation(true);
        } else {
            setChosenEducation(user.investments[0].institution);
            setHardChosenEducation(true);
        }
        if (user?.investments[0].institution === "0") {
            getEducationTestResults(localStorage.getItem('token')).then(results => {
              if (results.status == true) {
                setEducationTestResults(results.data);
              } else {
                setError(true);
                setErrorText(results.message);
              }
            }).catch(err => {
              localStorage.removeItem('token');
              window.location.href = '/login';
            });
        } else {
            setChosenEducation(user.investments[0].institution);
            setHardChosenEducation(true);
        }
    }, []);

    const onOccupationChange = (event, values) => {
      if (values) {
        setChosenCareer(values.occupation_code);
        setNeedsChosenCareer(false);
        checkAndSubmit(chosenEducation, values.occupation_code);
      }
    }

    const onInstitutionChange = (event, values) => {
      if (values) {
        setChosenEducation(values.unitId);
        setNeedsChosenEducation(false);
        checkAndSubmit(values.unitId, chosenCareer);
      }
    }

    const updateInstitutions = (event, values) => {
      var value = '*';
      if (event.target.value) {
        value = event.target.value;
      }
      getInstitutionsList(value).then(getInstitutionsList => {
        setInstitutions(getInstitutionsList.data);
      });
    }

    const getPersonalityPercentage = (number) => {
        let totalTestAnswers = 0;
        Object.keys(careerTestResults.results.personality).forEach(function(key) {
            totalTestAnswers += careerTestResults.results.personality[key];
        });
        if (totalTestAnswers === 0) {
            return 0;
        }
        return Math.ceil((number / totalTestAnswers) * 100);
    };

    const handleCareerRadioClick = (question, answer, index) => {
        if (numberCareerSuggestion.length !== (index + 1)) {
            setTimeout(function() {
                setNumberCareerSuggestion(index + 1);
            }, 300);
        }
        let suggestions = structuredClone(favoritedCareerSuggestions);
        if (answer === 1) {
            let addSuggestion = true;
            suggestions.forEach(suggestion => {
                if (suggestion.id === question.id) {
                    addSuggestion = false;
                }
            });
            if (addSuggestion) {
                suggestions.push(question);
            }
            setFavoritedCareerSuggestions(suggestions);
        } else {
            let newSuggestions = [];
            for (let i = 0; i < suggestions.length; i++) {
                if (suggestions[i].id !== question.id) {
                    newSuggestions.push(suggestions[i]);
                }
            }
            setFavoritedCareerSuggestions(newSuggestions);
        }
    }

    const handleCareerBackButton = (index) => {
        setNumberCareerSuggestion(index - 1);
    }

    const handleSelectChosenCareer = (career) => {
        setChosenCareer(career.occupation_code);
        setNeedsChosenCareer(false);
        checkAndSubmit(chosenEducation, career.occupation_code);
    }

    const handleEducationRadioClick = (question, answer, index) => {
        if (numberEducationSuggestion.length !== (index + 1)) {
            setTimeout(function() {
                setNumberEducationSuggestion(index + 1);
            }, 300);
        }
        let suggestions = structuredClone(favoritedEducationSuggestions);
        if (answer === 1) {
            let addSuggestion = true;
            suggestions.forEach(suggestion => {
                if (suggestion.id === question.id) {
                    addSuggestion = false;
                }
            });
            if (addSuggestion) {
                suggestions.push(question);
            }
            setFavoritedEducationSuggestions(suggestions);
        } else {
            let newSuggestions = [];
            for (let i = 0; i < suggestions.length; i++) {
                if (suggestions[i].id !== question.id) {
                    newSuggestions.push(suggestions[i]);
                }
            }
            setFavoritedEducationSuggestions(newSuggestions);
        }
    }

    const handleEducationBackButton = (index) => {
        setNumberEducationSuggestion(index - 1);
    }

    const handleSelectChosenEducation = (education) => {
        setChosenEducation(education.id);
        setNeedsChosenEducation(false);
        checkAndSubmit(education.id, chosenCareer);
    }

    const checkAndSubmit = (chosenEd, chosenCar) => {
        if ((chosenEd && chosenCar)) {
            let data = new URLSearchParams({
                'institution':chosenEd
            });
            updateInstitution(localStorage.getItem('token'), user.investments[0].id, data).then(results => {
                if (results.status == true) {
                    let data = new URLSearchParams({
                        'occupation': chosenCar,
                        'industry': null
                    });
                    return updateOccupation(localStorage.getItem('token'), user.investments[0].id, data);
                } else {
                    setError(true);
                    setErrorText(results.message);
                }
            }).then(results => {
                if (results.status == true) {
                    let careerPromises = favoritedCareerSuggestions.map(data => {
                        let dataParams = new URLSearchParams({
                            'item_id': data.occupation_code,
                            'type': 2
                        });
                        return addFavorite(localStorage.getItem('token'), dataParams);
                    });
                    let educationPromises = favoritedEducationSuggestions.map(data => {
                        let dataParams = new URLSearchParams({
                            'item_id': data.id,
                            'type': 1
                        });
                        return addFavorite(localStorage.getItem('token'), dataParams);
                    });
                    return Promise.all([...careerPromises, ...educationPromises]);
                } else {
                    setError(true);
                    setErrorText(results.message);
                }
            }).then(favoritesResults => {
                favoritesResults.forEach(favorite => {
                    if (!favorite?.status) {
                        setError(true);
                        setErrorText(favorite.message);
                    }
                });
                window.location.reload();
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    }

    const getPersonalityTooltip = (type) => {
        switch(type) {
            case 'artistic':
                return 'People who prefer creative and expressive activities. They enjoy working in unstructured environments where they can use their imagination and originality. They are innovative, intuitive, and open-minded.';
            case 'conventional':
                return 'People who prefer structured, rule-regulated tasks and working with data. They enjoy organizing information and performing tasks that follow set procedures. They are detail-oriented, orderly, and efficient.';
            case 'enterprising':
                return 'People who enjoy leading, persuading, and managing others. They are drawn to activities that involve starting and carrying out projects, often in a business setting. They are ambitious, assertive, and energetic.';
            case 'investigative':
                return 'People who enjoy thinking, researching, and solving problems. They prefer activities that involve theory, science, and intellectual pursuits. They are analytical, curious, and methodical.';
            case 'realistic':
                return 'People who prefer physical activities that require skill, strength, and coordination. They enjoy working with their hands, tools, machines, and animals. They often like to work outdoors and are practical and mechanical.';
            case 'social':
                return 'People who like to work with others to help them learn and grow. They enjoy teaching, counseling, and providing information. They are empathetic, patient, and communicative.';
            default:
              return 'not found'
        }
    };

    const handlePopoverOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    return (
        <Box sx={style}>
            {showBurst && (
                <div className="burst">
                    <ul className="topLeft">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <ul className="middleRight">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                    <ul className="bottomLeft">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
            )}
            {(needsChosenCareer && !hardChosenCareer) && (
                <>
                    {(careerTestResults?.results?.suggestions.length > 0) && (
                        <>
                            <Typography 
                                color='primary' 
                                variant='h3' 
                                component='div' 
                                sx={{
                                    textAlign:'center', 
                                    mb: 2,
                                    fontSize: {
                                        xs: '24px',
                                        sm: '30px',
                                        md: '46px'
                                    }
                                }}
                            >
                                You've got matches!
                            </Typography>
                            <Typography 
                                color='secondary' 
                                variant='h5' 
                                component='div' 
                                sx={{
                                    textAlign:'center',
                                    fontSize: {
                                        xs: '16px',
                                        sm: '20px',
                                        md: '25px'
                                    }
                                }}
                            >
                                Based on your quiz answers these occupations may be a good fit based on your personality! Check them out below!
                            </Typography>
                        </>
                    )}
                    <Grid container spacing={2} sx={{mt:2, mb:2}}>
                    {Object.keys(careerTestResults).length !== 0 && Object.keys(careerTestResults?.results?.personality).map(k => (
                        <Tooltip title={getPersonalityTooltip(k)}>
                            <Grid item xs={6} sm={4} md={2} sx={{textAlign:'center'}} key={k}>
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress
                                        value={getPersonalityPercentage(careerTestResults?.results?.personality[k])}
                                        variant="determinate"
                                        size={120}
                                        thickness={1.5}
                                        sx={{backgroundColor:'#F2F2F2', borderRadius: '150px'}}
                                    />
                                    <Box
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                    {k === 'artistic' && (
                                        <ColorLensIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    {k === 'conventional' && (
                                        <LocalShippingIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    {k === 'enterprising' && (
                                        <WorkIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    {k === 'investigative' && (
                                        <TerminalIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    {k === 'realistic' && (
                                        <EngineeringIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    {k === 'social' && (
                                        <ConnectWithoutContactIcon
                                            color="secondary"
                                            sx={{fontSize:80}}
                                        />
                                    )}
                                    </Box>
                                </Box>
                                <Typography color='secondary' variant='h5' component='div' sx={{textAlign:'center'}}>
                                    {k.charAt(0).toUpperCase() + k.slice(1)}
                                </Typography>
                            </Grid>
                        </Tooltip>
                    ))}
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 4, mb: 2 }} />
                    <Grid container spacing={2} sx={{mt:2, mb:4}}>
                    {careerTestResults?.results?.suggestions.map((suggestion, masterIndex) => (
                        <>
                            <>
                            {masterIndex === numberCareerSuggestion && (
                            <>
                                <Grid item xs={12} sm={3} md={4}>
                                </Grid>
                                <Grid 
                                    item 
                                    xs={12} 
                                    sm={6} 
                                    md={4}
                                    key={suggestion.id}
                                >
                                    <Typography 
                                        component='div'
                                        color='secondary'
                                        sx={{
                                            fontSize:'20px',
                                            mb:2,
                                            textAlign:'center',
                                        }}
                                    >
                                        {masterIndex + 1} / {careerTestResults?.results?.suggestions.length}
                                    </Typography>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            backgroundColor: '#fdfdfd',
                                            ...(chosenCareer === suggestion.id &&
                                                {border: '3px solid #fa7918 !important',}),
                                            ...(chosenCareer !== suggestion.id &&
                                            {border: '1px solid #e5e5e5',}),
                                            p: 2,
                                            '&:hover': {
                                                boxShadow: '1px 1px 1px #ccc'
                                            }
                                        }}
                                    >
                                        <Typography component='div' sx={{fontWeight:'bold', textAlign:'center'}}>
                                            {suggestion.occupation_title}
                                        </Typography>
                                        <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                        <Table size='small'>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Projected Annual Wage</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>${
                                                        Number(suggestion?.median_annual_wage ?? 0).toLocaleString() ?? "N/A"
                                                    }</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Current Estimated Number of Jobs</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>{suggestion?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Projected Number of Jobs (10 Years)</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>{suggestion?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>

                                        <FormControl
                                            sx={{ mt: 2, width:'100%', textAlign:'center', display:'block' }}
                                        >
                                            <RadioGroup 
                                                row
                                                value={suggestion.id}
                                                sx={{
                                                    display:'inline-block'
                                                }}
                                            >
                                                <FormControlLabel
                                                    control={<Radio 
                                                        icon={<CloseIcon sx={{fontSize:'60px'}} />}
                                                        checkedIcon={<CloseIcon sx={{fontSize:'60px'}}  /> }
                                                        sx={{
                                                            border:'2px solid'
                                                        }}
                                                        onClick={() => handleCareerRadioClick(suggestion, 0, masterIndex)}
                                                    />} 
                                                    sx={{
                                                        p:0,
                                                        ml:2,
                                                        mr:2
                                                    }}
                                                />
                                                <FormControlLabel
                                                    control={<Radio 
                                                        icon={<CheckIcon sx={{fontSize:'60px'}}  />}
                                                        checkedIcon={<CheckIcon sx={{fontSize:'60px'}}  />}
                                                        sx={{
                                                            border:'2px solid'
                                                        }}
                                                        onClick={() => handleCareerRadioClick(suggestion, 1, masterIndex)}
                                                    />} 
                                                    sx={{
                                                        p:0,
                                                        ml:2,
                                                        mr:2
                                                    }}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                        {masterIndex !== 0 && (
                                            <Link 
                                                sx={{
                                                    display:'block',
                                                    mt:2,
                                                    cursor:'pointer',
                                                    textAlign:'center'
                                                }}
                                                color='secondary'
                                                onClick={() => handleCareerBackButton(masterIndex)}
                                            >
                                                <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back
                                            </Link>
                                        )}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                </Grid>
                            </>
                            )}
                            </>
                        </>
                    ))}
                    <>
                    {careerTestResults?.results?.suggestions.length === numberCareerSuggestion && (
                        <>
                            {favoritedCareerSuggestions.length > 0 ? (
                                <>
                                <Typography 
                                    color='secondary' 
                                    variant='h5' 
                                    component='div' 
                                    sx={{
                                        textAlign:'center', 
                                        maxWidth:'800px', 
                                        margin:'0px auto 20px auto',
                                        fontSize: {
                                            xs: '16px',
                                            sm: '20px',
                                            md: '25px'
                                        }
                                    }}
                                >
                                    Please choose the top career you would like to look at out of your favorited matches! Don't worry, you can always change and compare with others later.
                                </Typography>
                                <Grid container spacing={2}>
                                {favoritedCareerSuggestions.map((favorite) => (
                                    <Grid 
                                        item 
                                        xs={12} 
                                        sm={3} 
                                        md={3}
                                        key={favorite.id}
                                    >
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                backgroundColor: '#fdfdfd',
                                                ...(chosenCareer === favorite.id &&
                                                    {border: '3px solid #fa7918 !important',}),
                                                ...(chosenCareer !== favorite.id &&
                                                {border: '1px solid #e5e5e5',}),
                                                p: 2,
                                                '&:hover': {
                                                    boxShadow: '1px 1px 1px #ccc'
                                                },
                                                position:'relative'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    position:'absolute',
                                                    right: 1,
                                                    top: 1,
                                                    zIndex: 9
                                                }}
                                            >
                                                <StarIcon
                                                    size='large'
                                                    color='primary'
                                                />
                                            </Box>
                                            <Typography component='div' sx={{fontWeight:'bold', textAlign:'center'}}>
                                                {favorite.occupation_title}
                                            </Typography>
                                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                            <Table size='small'>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{pl:0}}>
                                                        <Typography fontWeight='bold' color='secondary'>Projected Annual Wage</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{pr:0}} align='right'>
                                                        <Typography fontWeight='bold' color='primary'>${
                                                            Number(favorite?.median_annual_wage ?? 0).toLocaleString() ?? "N/A"
                                                        }</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{pl:0}}>
                                                        <Typography fontWeight='bold' color='secondary'>Current Estimated Number of Jobs</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{pr:0}} align='right'>
                                                        <Typography fontWeight='bold' color='primary'>{favorite?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{pl:0}}>
                                                        <Typography fontWeight='bold' color='secondary'>Projected Number of Jobs (10 Years)</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{pr:0}} align='right'>
                                                        <Typography fontWeight='bold' color='primary'>{favorite?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                sx={{ mt: 2, mb: 2 }}
                                                disableElevation
                                                onClick={() => handleSelectChosenCareer(favorite)}
                                            >
                                                Select As Primary
                                            </Button>
                                        </Paper>
                                    </Grid>
                                ))}
                                </Grid>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        maxWidth:'800px',
                                        margin:'20px auto 20px auto',
                                    }}
                                >
                                    <Typography 
                                        color='secondary' 
                                        variant='h5' 
                                        component='div' 
                                        sx={{
                                            textAlign:'center', 
                                            fontSize: {
                                                xs: '16px',
                                                sm: '20px',
                                                md: '25px'
                                            }
                                        }}
                                    >
                                        We our sorry our algorithm couldn't find a good match for you. To continue, please choose from any career path. Our artificial intelligence will analyze as you go along and offer better suggestions in the future!
                                    </Typography>
                                    <Autocomplete
                                        disablePortal
                                        id="occupation"
                                        options={occupations}
                                        name="occupation"
                                        fullWidth
                                        sx={{
                                            mt:5
                                        }}
                                        onChange={onOccupationChange}
                                        getOptionLabel={occupations => occupations.occupation_title}
                                        renderInput={(params) => <TextField {...params} required label="Desired Occupation" />}
                                    />
                                </Box>
                            )}
                        </>
                    )}
                    </>
                    </Grid>
                </>
            )}
            {(needsChosenEducation && chosenCareer && !hardChosenEducation) && (
                <>
                {(educationTestResults?.results?.suggestions.length > 0) && (
                    <>
                        <Typography 
                            color='primary' 
                            variant='h3' 
                            component='div' 
                            sx={{
                                textAlign:'center', 
                                mb: 2,
                                fontSize: {
                                    xs: '24px',
                                    sm: '30px',
                                    md: '46px'
                                }
                            }}
                        >
                            You've got matches!
                        </Typography>
                        <Typography 
                            color='secondary' 
                            variant='h5' 
                            component='div' 
                            sx={{
                                textAlign:'center',
                                fontSize: {
                                    xs: '16px',
                                    sm: '20px',
                                    md: '25px'
                                }
                            }}
                        >
                            Based on your quiz answers these schools may be a good fit!
                        </Typography>
                        <Divider variant="fullWidth" sx={{ mt: 4, mb: 2 }} />
                    </>
                )}
                {educationTestResults?.results?.errors.map((error, index) => (
                    <Alert severity="warning" index={index}>{error}</Alert>
                ))}
                <Grid container spacing={2} sx={{mt:2, mb:4}}>
                {educationTestResults?.results?.suggestions.map((suggestion, masterIndex) => (
                    <>
                    {masterIndex === numberEducationSuggestion && (
                    <>
                        <Grid item xs={12} sm={3} md={4}>
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            sm={6} 
                            md={4}
                            key={suggestion.id}
                        >

                            <Typography 
                                component='div'
                                color='secondary'
                                sx={{
                                    fontSize:'20px',
                                    mb:2,
                                    textAlign:'center',
                                }}
                            >
                                {masterIndex + 1} / {educationTestResults?.results?.suggestions.length}
                            </Typography>
                            <Paper
                                elevation={0}
                                sx={{
                                    backgroundColor: '#fdfdfd',
                                    ...(chosenCareer === suggestion.id &&
                                        {border: '3px solid #fa7918 !important',}),
                                    ...(chosenCareer !== suggestion.id &&
                                    {border: '1px solid #e5e5e5',}),
                                    p: 2,
                                    '&:hover': {
                                        boxShadow: '1px 1px 1px #ccc'
                                    }
                                }}
                            >
                                <Typography component='div' sx={{fontWeight:'bold', textAlign:'center'}}>
                                    {suggestion.college_name}<br />
                                    {suggestion?.alerts.map((alert, index) => (
                                        <>
                                        <WarningAmberIcon 
                                            color='warning'
                                            aria-owns={open ? 'mouse-over-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                            sx={{
                                                cursor:'pointer'
                                            }}
                                        />
                                        <Popover
                                            id="mouse-over-popover"
                                            sx={{
                                                pointerEvents: 'none'
                                            }}
                                            open={open}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }}
                                            onClose={handlePopoverClose}
                                            disableRestoreFocus
                                        >
                                                <Typography
                                                    index={index}
                                                    color='secondary'
                                                    sx={{
                                                        width:'200px',
                                                        padding: '10px',
                                                        fontSize: '12px',
                                                        ...(index !== suggestion.length &&
                                                        {borderBottom: '1px solid #e5e5e5',}),
                                                    }}
                                                >
                                                    {alert}
                                                </Typography>
                                        </Popover>
                                        </>
                                    ))}
                                </Typography>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                <Table size='small'>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold' color='secondary'>Graduation Rate</Typography>
                                            </TableCell>
                                            <TableCell sx={{pr:0}} align='right'>
                                            <Typography fontWeight='bold' color='primary'>{(suggestion?.graduation_rate !== '0%') ? suggestion?.graduation_rate : 'Unknown'}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold' color='secondary'>Acceptance Rate</Typography>
                                            </TableCell>
                                            <TableCell sx={{pr:0}} align='right'>
                                            <Typography fontWeight='bold' color='primary'>{(suggestion?.acceptance_rate !== null) ? (suggestion?.acceptance_rate * 100).toFixed(2) + '%' : 'Unknown'}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{pl:0}}>
                                            <Typography fontWeight='bold' color='secondary'>Average Cost Of Attendance (Per Year)</Typography>
                                            </TableCell>
                                            <TableCell sx={{pr:0}} align='right'>
                                            <Typography fontWeight='bold' color='primary'>${suggestion?.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <FormControl
                                    sx={{ mt: 2, width:'100%', textAlign:'center', display:'block' }}
                                >
                                    <RadioGroup 
                                        row
                                        value={suggestion.id}
                                        sx={{
                                            display:'inline-block'
                                        }}
                                    >
                                        <FormControlLabel
                                            control={<Radio 
                                                icon={<CloseIcon sx={{fontSize:'60px'}} />}
                                                checkedIcon={<CloseIcon sx={{fontSize:'60px'}}  /> }
                                                sx={{
                                                    border:'2px solid'
                                                }}
                                                onClick={() => handleEducationRadioClick(suggestion, 0, masterIndex)}
                                            />} 
                                            sx={{
                                                p:0,
                                                ml:2,
                                                mr:2
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Radio 
                                                icon={<CheckIcon sx={{fontSize:'60px'}}  />}
                                                checkedIcon={<CheckIcon sx={{fontSize:'60px'}}  />}
                                                sx={{
                                                    border:'2px solid'
                                                }}
                                                onClick={() => handleEducationRadioClick(suggestion, 1, masterIndex)}
                                            />} 
                                            sx={{
                                                p:0,
                                                ml:2,
                                                mr:2
                                            }}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {masterIndex !== 0 && (
                                    <Link 
                                        sx={{
                                            display:'block',
                                            mt:2,
                                            cursor:'pointer',
                                            textAlign:'center'
                                        }}
                                        color='secondary'
                                        onClick={() => handleEducationBackButton(masterIndex)}
                                    >
                                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back
                                    </Link>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                        </Grid>
                    </>
                    )}
                    </>
                ))}
                {educationTestResults?.results?.suggestions.length === numberEducationSuggestion && (
                    <>
                        {favoritedEducationSuggestions.length > 0 ? (
                            <>
                            <Typography 
                                color='secondary' 
                                variant='h5' 
                                component='div' 
                                sx={{
                                    textAlign:'center', 
                                    maxWidth:'800px', 
                                    margin:'0px auto 20px auto',
                                    fontSize: {
                                        xs: '16px',
                                        sm: '20px',
                                        md: '25px'
                                    }
                                }}
                            >
                                Please choose the top institution you would like to look at out of your favorited matches! Don't worry, you can always change and compare with others later.
                            </Typography>
                            <Grid container spacing={2}>
                            {favoritedEducationSuggestions.map((favorite) => (
                                <Grid 
                                    item 
                                    xs={12} 
                                    sm={3} 
                                    md={3}
                                    key={favorite.id}
                                >
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            backgroundColor: '#fdfdfd',
                                            ...(chosenEducation === favorite.id &&
                                                {border: '3px solid #fa7918 !important',}),
                                            ...(chosenEducation !== favorite.id &&
                                            {border: '1px solid #e5e5e5',}),
                                            p: 2,
                                            '&:hover': {
                                                boxShadow: '1px 1px 1px #ccc'
                                            },
                                            position:'relative'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position:'absolute',
                                                right: 1,
                                                top: 1,
                                                zIndex: 9
                                            }}
                                        >
                                            <StarIcon
                                                size='large'
                                                color='primary'
                                            />
                                        </Box>
                                        <Typography component='div' sx={{fontWeight:'bold', textAlign:'center'}}>
                                            {favorite.college_name}<br />
                                            {favorite?.alerts.map((alert, index) => (
                                                <>
                                                    <WarningAmberIcon 
                                                        color='warning'
                                                        aria-owns={open ? 'second-mouse-over-popover' : undefined}
                                                        aria-haspopup="true"
                                                        onMouseEnter={handlePopoverOpen}
                                                        onMouseLeave={handlePopoverClose}
                                                        sx={{
                                                            cursor:'pointer'
                                                        }}
                                                    />
                                                    <Popover
                                                        id="second-mouse-over-popover"
                                                        sx={{
                                                            pointerEvents: 'none'
                                                        }}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left'
                                                        }}
                                                        onClose={handlePopoverClose}
                                                        disableRestoreFocus
                                                    >
                                                            <Typography
                                                                index={index}
                                                                color='secondary'
                                                                sx={{
                                                                    width:'200px',
                                                                    padding: '10px',
                                                                    fontSize: '12px',
                                                                    ...(index !== favorite.length &&
                                                                    {borderBottom: '1px solid #e5e5e5',}),
                                                                }}
                                                            >
                                                                {alert}
                                                            </Typography>
                                                    </Popover>
                                                </>
                                            ))}
                                        </Typography>
                                        <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                                        <Table size='small'>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Graduation Rate</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>{(favorite?.graduation_rate !== '0%') ? favorite?.graduation_rate : 'Unknown'}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Acceptance Rate</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>{(favorite?.acceptance_rate !== null) ? (favorite?.acceptance_rate * 100).toFixed(2) + '%' : 'Unknown'}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{pl:0}}>
                                                    <Typography fontWeight='bold' color='secondary'>Average Cost Of Attendance (Per Year)</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{pr:0}} align='right'>
                                                    <Typography fontWeight='bold' color='primary'>${favorite?.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, mb: 2 }}
                                            disableElevation
                                            onClick={() => handleSelectChosenEducation(favorite)}
                                        >
                                            Select As Primary
                                        </Button>
                                    </Paper>
                                </Grid>
                            ))}
                            </Grid>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    maxWidth:'800px',
                                    margin:'20px auto 20px auto',
                                }}
                            >
                                <Typography 
                                    color='secondary' 
                                    variant='h5' 
                                    component='div' 
                                    sx={{
                                        textAlign:'center', 
                                        fontSize: {
                                            xs: '16px',
                                            sm: '20px',
                                            md: '25px'
                                        }
                                    }}
                                >
                                    We our sorry our algorithm couldn't find a good match for you. To continue, please choose from any institution. Our artificial intelligence will analyze as you go along and offer better suggestions in the future!
                                </Typography>
                                <Typography 
                                    color='secondary' 
                                    variant='h5' 
                                    component='div' 
                                    sx={{
                                        textAlign:'center', 
                                        fontSize: {
                                            xs: '12px',
                                            sm: '12px',
                                            md: '14px'
                                        },
                                        mt: 2,
                                        fontStyle: 'italic'
                                    }}
                                >
                                    Please note, that we may not have the institution you're looking for in our database. If you search and it doesn't come up please be patient. Our software will grab information real time. Doing so will help other students looking for that institution in the future!
                                </Typography>
                                <Autocomplete
                                    disablePortal
                                    id="institute"
                                    options={institutions}
                                    name="institute"
                                    fullWidth
                                    sx={{
                                        mt:5
                                    }}
                                    onChange={onInstitutionChange}
                                    getOptionLabel={institutions => institutions.name}
                                    renderInput={(params) => <TextField {...params} onChange={updateInstitutions} required label="Desired Institution" />}
                                />
                            </Box>
                        )}
                    </>
                )}
                </Grid>
                </>
            )}
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
            {(needsChosenCareer && !hardChosenCareer) && (
                <>{careerPickerDisclaimer}</>
            )}
            {(needsChosenEducation && chosenCareer && !hardChosenEducation) && (
                <>{educationPickerDisclaimer}</>
            )}
            </Grid>
        </Box>
    )
}