import { apiUrl } from '../constants/globals';

export async function getCareerTest() {
    try {
        const response = await fetch(apiUrl + '/students/career/test', {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateCareerTestAnswers(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/career/test', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getCareerTestResults(token) {
    try {
        const response = await fetch(apiUrl + '/students/career/test/results', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}