import { apiUrl } from '../constants/globals';

export async function getInstitutionsList(query) {
    try{
        const response = await fetch(apiUrl + '/getInstitutionsList/' + query);
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateInstitution(token, investmentId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/institution/' + investmentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getInstitution(token, investmentId) {
    try {
        const response = await fetch(apiUrl + '/students/account/institution/' + investmentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentInstitutionByEmployer(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/institution/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
