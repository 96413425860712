import { apiUrl } from '../constants/globals';

export async function getAllUserTasks(token) {
    try {
        const response = await fetch(apiUrl + '/user/all/tasks/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserTasksByReferrer(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function generateTasks(token, id) {
    try {
        const response = await fetch(apiUrl + '/generate/tasks/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function uploadSuggestions(token, data) {
    try {
        const response = await fetch(apiUrl + '/upload/suggestions/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEmployeeTasksByStudent(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addTask(token, data) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateTask(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function completeTask(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/complete/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function archiveTask(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/archive/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteTask(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/tasks/delete/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}