import React, { Suspense } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { userAccount, checkType } from '../../../../services/userService';
import { getInvoices } from '../../../../services/studentBillingService';

const Invoices = () => {
    const [user, setUser] = React.useState(false);
    const [invoices, setInvoices] = React.useState([]);
    const [error, setError] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const handleCloseError = () => setError(false);
  
    if (!localStorage.getItem('token')) {
        window.location.href = '/login';
    }
  
    React.useEffect(() => {
      checkType(localStorage.getItem('token')).then(requestData => {
        if (requestData.status == true) {
          if (requestData.data?.type === 2) {
            window.location.href = '/educator/dashboard';
          }
        }
      });
      userAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
          setUser(userData.data);
        } else {
          setError(true);
          setErrorText(userData.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
      getInvoices(localStorage.getItem('token')).then(invoices => {
          if (invoices.status == true) {
                setInvoices(invoices.data);
          } else {
                setError(true);
                setErrorText(invoices.errors);
          }
      });
    }, []);

    const convertDate = (invoiceDate) => {
        let date = new Date(invoiceDate * 1000);
        return date.toLocaleString("default", { month: "long" })
                + ' ' +
                date.toLocaleString("default", { day: "numeric" })
                + ', ' +
                date.toLocaleString("default", { year: "numeric" });
    }

    const handleDownloadInvoice = (e, file) => {
        window.location.href = file;
    }

    return (
        <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
            <Typography color='secondary' variant='h4' component='div'>
                Your Invoices
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{ p: 2 }}>
                <Box sx={{ width: '100%' }}>
                    {invoices?.length > 0 ? (
                        <Grid container>
                            {invoices?.map((invoice, index) => (
                                <Container 
                                    key={index} 
                                    maxWidth={false} 
                                    disableGutters={true} 
                                    sx={[
                                        {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                        {p: 1, mt:1, mb:1, border:'1px solid #E5E5E5', borderRadius:1}
                                    ]}
                                >
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={10}>
                                            <Typography variant='h6' color="secondary" component='div' sx={{mb: 1}}>
                                            Invoice <b>#{invoice.number}</b>
                                            </Typography>
                                            <Typography color='secondary' component={'span'}>
                                            ${invoice?.total ? invoice?.total?.toString().substring(0, (invoice?.total?.toString().length) - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0} - {convertDate(invoice.created)}
                                            &nbsp;<Chip 
                                                    label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                                    color={invoice.status === 'paid' ? 'primary' : 'secondary'}
                                                    size="small" 
                                                    sx={{
                                                        color:'white',
                                                        fontSize: '1'
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2} align='right'>
                                            <IconButton aria-label="edit" color="secondary" onClick={e => handleDownloadInvoice(e, invoice.invoice_pdf)}>
                                                <FileDownloadIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Container>
                            ))}
                        </Grid>
                    ) : (
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                        You have no invoices at this time.
                        </Typography>
                    )}
                </Box>
            </Paper>
            <Snackbar
            open={error}
            onClose={handleCloseError}
            autoHideDuration={6000}
            >
            <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                {errorText}
            </Alert>
            </Snackbar>
        </Container>
    );
};
  
  export default Invoices;