import { apiUrl } from '../constants/globals';

export async function getInstitution(token) {
    try {
        const response = await fetch(apiUrl + '/educator/institution/account', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateInstitutionAccount(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/institution/account/', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateInstitutionAdvertisingData(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/institution/advertising/', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateInstitutionPhoto(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/institution/photo/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}