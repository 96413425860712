import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { educatorAccount, checkType } from '../../../services/userService';
import { getInstitution } from '../../../services/institutionService';
import { getAllInstitutionAdvertisements } from '../../../services/advertisementsService';
import { AdvertisementListItem } from '../../../components/global/advertisements/advertisementListItem';
import { AdvertisementAdd } from '../../../components/global/advertisements/advertisementAdd';
import { AdvertisementView } from '../../../components/global/advertisements/advertisementView';

const drawerStyle = {
  width: '60%',
  '@media (max-width: 800px)': {
      width: '100%'
  }
};

const Advertisements = () => {
  const [educator, setEducator] = React.useState({});
  const [institution, setInstitution] = React.useState({});
  const [advertisements, setAdvertisements] = React.useState([]);
  const [showAdvertisement, setShowAdvertisement] = React.useState(false);
  const [selectedAdvertisement, setSelectedAdvertisement] = React.useState(null);
  const [reloadList, setReloadList] = React.useState(false);
  const [openAddAdvertisementDrawer, setOpenAddAdvertisementDrawer] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openErrorText, setOpenErrorText] = React.useState('');
  const handleCloseError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleCloseSuccess = () => setOpenSuccess(false);
  const handleCloseAddAdvertisementDrawer = () => {
    setOpenAddAdvertisementDrawer(false);
    setShowAdvertisement(false);
    setSelectedAdvertisement(null);
  };

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.user_level !== 1 || userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
        setEducator(userData.data);
      } else {
        setOpenError(true);
        setOpenErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getInstitution(localStorage.getItem('token')).then(institutionData => {
      if (institutionData.status == true) {
        setInstitution(institutionData.data);
      } else {
        setOpenError(true);
        setOpenErrorText(institutionData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getAllInstitutionAdvertisements(localStorage.getItem('token'), 1).then(advertisementsData => {
      if (advertisementsData.status == true) {
        setAdvertisements(advertisementsData.data);
      } else {
        setOpenError(true);
        setOpenErrorText(advertisementsData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
      getAllInstitutionAdvertisements(localStorage.getItem('token'), 1).then(advertisementsData => {
        if (advertisementsData.status == true) {
          setAdvertisements(advertisementsData.data);
        } else {
          setOpenError(true);
          setOpenErrorText(advertisementsData.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
      setReloadList(false);
  }, [reloadList]);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      {!showAdvertisement ? (
        <>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Advertisements
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => setOpenAddAdvertisementDrawer(true)}
                disabled={educator?.active ? false : true}
              >
                Add Advertisement
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Grid container spacing={3}>
            {advertisements?.length > 0 ? (
              <>
                {advertisements?.map((advertisement, index) => (
                  <>
                    {(advertisement?.type === 1 || advertisement?.type === 3) && (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <AdvertisementListItem 
                          advertisement={advertisement}
                          setReloadList={setReloadList}
                          setShowAdvertisement={setShowAdvertisement}
                          setSelectedAdvertisement={setSelectedAdvertisement}
                          setError={setOpenError}
                          setErrorText={setOpenErrorText}
                          setOpenSuccess={setOpenSuccess}
                        />
                      </Grid>
                    )}
                  </>
                ))}
              </>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                    You currently have no advertisements added at this time.
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <AdvertisementView 
          institution={institution}
          advertisement={selectedAdvertisement}
          setAdvertisement={setSelectedAdvertisement}
          type={'advertisement'}
          setShowAdvertisement={setShowAdvertisement}
          setSelectedAdvertisement={setSelectedAdvertisement}
          setError={setOpenError}
          setErrorText={setOpenErrorText}
          setReloadList={setReloadList}
          handleCloseAddAdvertisementDrawer={handleCloseAddAdvertisementDrawer}
          setOpenSuccess={setOpenSuccess}
        />
      )}
      <Snackbar
        open={openError}
        onClose={handleCloseError}
        autoHideDuration={6000}
        sx={{zIndex: 99999}}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
        {openErrorText}
        </Alert>
      </Snackbar>
      <Snackbar
          open={openSuccess}
          onClose={handleCloseSuccess}
          autoHideDuration={6000}
          sx={{zIndex: 99999}}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
        Success
        </Alert>
      </Snackbar>
      <Drawer
          open={openAddAdvertisementDrawer}
          onClose={handleCloseAddAdvertisementDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseAddAdvertisementDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <AdvertisementAdd 
            institution={institution}
            advertisement={null}
            setAdvertisement={null}
            setError={setOpenError}
            setErrorText={setOpenErrorText}
            type={'advertisement'}
            setReloadList={setReloadList}
            handleCloseAddAdvertisementDrawer={handleCloseAddAdvertisementDrawer}
            setOpenSuccess={setOpenSuccess}
          />
      </Drawer>
    </Container>
  );
};

export default Advertisements;