import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { archiveTask, completeTask, deleteTask } from '../../../services/tasksService';
import { Chip } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 2 }}>
            {children}
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function TasksList({type, tasks, setSelectedTask, attachedUser, setReloadList, setOpenTaskDrawer, setError, setErrorText, removeGutters}) {
    const [mainTasks, setMainTasks] = React.useState([]);
    const [archivedTasks, setArchivedTasks] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [openPopper, setOpenPopper] = React.useState(null);
    const [popperTask, setPopperTask] = React.useState(null);

    React.useEffect(() => {
        let main = [];
        let archived = [];

        tasks?.map(task => {
            if (task?.archived) {
                archived.push(task);
            } else {
                main.push(task);
            }
        });
        setMainTasks(main);
        setArchivedTasks(archived);
    }, [tasks, setReloadList]);

    const canEdit = (task) => {
        if (attachedUser?.active === 0 || task?.educator?.active === 0) {
            return false;
        }
        if (type === 'student') {
            if (task?.educator_id === undefined || task?.educator_id === null || task?.educator_id === 'null') {
                return true;
            }

            if (task?.uploader_id === attachedUser?.id) {
                return true;
            }

            if (task?.user_id === attachedUser?.id) {
                return true;
            }
        } else if (type === 'self_educator') {
            if (task?.uploader_id === attachedUser?.id) {
                return true;
            }

            if (task?.educator_id === attachedUser?.id) {
                return true;
            }
        }
        return false;
    };

    const canDelete = (task) => {
        if (type === 'student') {
            if (task?.uploader_id === attachedUser?.id && task?.user_id === attachedUser?.id) {
                return true;
            }
        } else if (type === 'self_educator') {
            if (task?.uploader_id === attachedUser?.id && task?.educator_id === attachedUser?.id) {
                return true;
            }
        }
        return false;
    };

    const handleClickPopper = (event, task) => {
        setOpenPopper(event.currentTarget);
        setPopperTask(task);
    };

    const handleClosePopper = () => {
        setOpenPopper(null);
        setPopperTask(null);
    };

    const open = Boolean(openPopper);
  
    const handleTabChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    const onCompleteChange = (event, taskData) => {
        let value = 0;
        if (event.target.checked === false) {
            value = 0;
        } else if (event.target.checked === true) {
            value = 1;
        }
        let data = new URLSearchParams({
            'value':value,
        });
        
        completeTask(localStorage.getItem('token'), data, taskData?.id).then(task => {
            if (task.status) {
                setReloadList(task);
            } else {
                setError(true);
                setErrorText(task.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error updating the task.');
        });
    }

    const onArchiveChange = () => {
        let data = new URLSearchParams({
            'value':1,
        });
        
        archiveTask(localStorage.getItem('token'), data, popperTask?.id).then(task => {
            if (task.status) {
                setReloadList(task);
            } else {
                setError(true);
                setErrorText(task.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error updating the task.');
        });
        handleClosePopper();
    }

    const onDeleteChange = () => {
        deleteTask(localStorage.getItem('token'), popperTask?.id).then(task => {
            if (task.status) {
                setReloadList(task);
            } else {
                setError(true);
                setErrorText(task.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error updating the task.');
        });
        handleClosePopper();
    }
  
    const openTaskDrawer = (task) => {
        setSelectedTask(task);
        setOpenTaskDrawer(true);
    };

    const checkBoxText = (task) => {
        if (attachedUser?.active === 0) {
            return 'User is inactive.';
        }
        if (task?.educator?.active === 0) {
            return 'Educator is inactive.';
        }
        if (!task?.completed) {
            return 'Click to complete task.';
        }
        return '';
    };

    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate  = new Date(date);

        return formattedDate.toLocaleDateString('en-US', options);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Tasks" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
                    <Tab label="Archived Tasks" {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
                </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} sx={{position:'relative'}}>
                {mainTasks?.length > 0 ? (
                    <>
                        {mainTasks?.map((task, index) => (
                            <Container key={index} maxWidth={false} disableGutters={(type === 'student' && !removeGutters) ? false : true}>
                                <Grid 
                                    container 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    spacing={0}
                                >
                                    <Grid item xs={1}>
                                        <Tooltip 
                                            title={checkBoxText(task)}
                                            arrow
                                        >
                                            <Checkbox onChange={(event) => onCompleteChange(event, task)} checked={task?.completed ? true : false} disabled={!canEdit(task) ? true : false} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={canEdit(task) ? 10 : 11}>
                                        <Grid item>
                                            <Link onClick={() => openTaskDrawer(task)} color='primary' sx={{cursor:'pointer'}}>{task?.task.substr(0, 50)}{task?.task.length > 50 && "\u2026"}</Link>
                                        </Grid>
                                        <Grid item>
                                            {task?.due_date && (
                                                <Chip label={'Due On: ' + formatDate(task?.due_date)} size="small" sx={{mt:1, mr:1}} />
                                            )}
                                            {task?.educator?.id && (
                                                <Chip label={'Assigned By: ' + task?.educator?.first_name + ' ' + task?.educator?.last_name} size="small" sx={{mt:1, mr:1}} />
                                            )}
                                        </Grid>
                                    </Grid>
                                    {canEdit(task) && (
                                        <Grid item xs={1}>
                                            <IconButton aria-label="delete" aria-describedby={index} onClick={(event) => handleClickPopper(event, task)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Popover
                                                id={index}
                                                open={open}
                                                anchorEl={openPopper}
                                                onClose={handleClosePopper}
                                                elevation={0}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Box>
                                                    <Link
                                                        underline='none'
                                                        color='inherit'
                                                        sx={{
                                                            width:'100px',
                                                            display:'block',
                                                            p: 1,
                                                            border: '1px solid #ccc',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: '#ffaa7730',
                                                            },
                                                        }}
                                                        onClick={onArchiveChange}
                                                    >
                                                        Archive Task
                                                    </Link>
                                                    {canDelete(popperTask) && (
                                                        <Link
                                                            underline='none'
                                                            color='inherit'
                                                            sx={{
                                                                width:'100px',
                                                                display:'block',
                                                                p: 1,
                                                                border: '1px solid #ccc',
                                                                borderTop: '0px',
                                                                cursor: 'pointer',
                                                                '&:hover': {
                                                                    backgroundColor: '#FF0000',
                                                                    color: '#fff',
                                                                },
                                                            }}
                                                            onClick={onDeleteChange}
                                                        >
                                                            Delete Task
                                                        </Link>
                                                    )}
                                                </Box>
                                            </Popover>
                                        </Grid> 
                                    )}
                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                    </Grid>
                                </Grid>
                            </Container>
                        ))}
                    </>
                ) : (
                    <>
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                            {type === 'student' ? 'You do' : 'This student does'} not have any tasks{type !== 'student' && ' from this educator'}.<br />
                            {type === 'self_educator' && (
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    sx={{mt: 2}}
                                    onClick={() => openTaskDrawer({})}
                                    disabled={attachedUser?.active ? false : true}
                                >
                                    Add Task
                                </Button>
                            )}
                        </Typography>
                    </>
                )}
            </TabPanel>
            <TabPanel value={tabValue} index={1} sx={{position:'relative'}}>
                {archivedTasks?.length > 0 ? (
                    <>
                        {archivedTasks?.map((task, index) => (
                            <Container key={index} maxWidth={false} disableGutters={(type === 'student' && !removeGutters) ? false : true}>
                                <Grid 
                                    container 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    spacing={0}
                                >
                                    <Grid item xs={1}>
                                        <Checkbox onChange={(event) => onCompleteChange(event, task)} checked={task?.completed ? true : false} disabled={!canEdit(task) ? true : false} />
                                    </Grid>
                                    <Grid item xs={canDelete(task) ? 10 : 11}>
                                        <Grid item>
                                            <Link onClick={() => openTaskDrawer(task)} color='primary' sx={{cursor:'pointer'}}>{task?.task.substr(0, 50)}{task?.task.length > 50 && "\u2026"}</Link>
                                        </Grid>
                                        <Grid item>
                                            {task?.educator?.id && (
                                                <Chip label={'Assigned By: ' + task?.educator?.first_name + ' ' + task?.educator?.last_name} size="small" sx={{mt:1, mr:1}} />
                                            )}
                                        </Grid>
                                    </Grid>
                                    {canDelete(task) && (
                                        <Grid item xs={1}>
                                            <IconButton aria-label="delete" aria-describedby={index} onClick={(event) => handleClickPopper(event, task)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Popover
                                                id={index}
                                                open={open}
                                                anchorEl={openPopper}
                                                onClose={handleClosePopper}
                                                elevation={0}
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <Box>
                                                    <Link
                                                        underline='none'
                                                        color='inherit'
                                                        sx={{
                                                            width:'100px',
                                                            display:'block',
                                                            p: 1,
                                                            border: '1px solid #ccc',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: '#FF0000',
                                                                color: '#fff',
                                                            },
                                                        }}
                                                        onClick={onDeleteChange}
                                                    >
                                                        Delete Task
                                                    </Link>
                                                </Box>
                                            </Popover>
                                        </Grid> 
                                    )}
                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                                    </Grid>
                                </Grid>
                            </Container>
                        ))}
                    </>
                ) : (
                    <>
                        <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                            {type === 'student' ? 'You do' : 'This student does'} not have any archived tasks{type !== 'student' && ' from this educator'}.<br />
                        </Typography>
                    </>
                )}
            </TabPanel>
        </Box>
    )
}