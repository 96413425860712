import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getAllPublicUserPortfolioItems } from '../../../../../services/portfolioService';
import { PortfolioItemList } from '../../../../../components/global/portfolio/portfolioList';
import { PortfolioItemView } from '../../../../../components/global/portfolio/portfolioView';

export function PortfolioComponent({student, selectedEducator, setOpenTasks, setError, setErrorText}) {
    const [portfolioItems, setPortfolioItems] = React.useState([]);
    const [selectedPortfolioItem, setSelectedPortfolioItem] = React.useState({});
    const [type, setType] = React.useState('employee');
    const [reloadList, setReloadList] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const drawerStyle = {
        width: '60%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }
  
    const handleCloseDrawer = () => {
      setSelectedPortfolioItem({});
      setOpenDrawer(false);
    }
  
    const handleOpenDrawer = (portfolioItem) => {
      setSelectedPortfolioItem(portfolioItem);
      setOpenDrawer(true);
    }

    React.useEffect(() => {
        getAllPublicUserPortfolioItems(student?.id).then(portfolioItems => {
              if (portfolioItems.status == true) {
                setPortfolioItems(portfolioItems.data);
              } else {
                setError(true);
                setErrorText(portfolioItems.message);
              }
          }).catch(err => {
            setError(true);
            setErrorText(err);
          });
          setReloadList(false);
    }, [selectedEducator, setOpenTasks, reloadList]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Student Portfolio
                    </Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <PortfolioItemList
                type={type}
                student={student}
                portfolioItemList={portfolioItems}
                setPortfolioItemList={setPortfolioItems}
                setReloadList={setReloadList}
                handleOpenDrawer={handleOpenDrawer}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Drawer
                open={openDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <PortfolioItemView
                    type={type}
                    student={student}
                    selectedPortfolioItem={selectedPortfolioItem}
                    handleCloseDrawer={handleCloseDrawer}
                    setReloadList={setReloadList}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
        </>
    )
}