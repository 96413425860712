import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { updateIncome } from '../../services/employmentService';
import { userAccount } from '../../services/userService';

export const IncomeModal = () => {

  const [income, setIncome] = React.useState(null);
  const [yearlyIncreasePercentage, setYearlyIncreasePercentage] = React.useState(null);
  const [investmentId, setInvestmentId] = React.useState(null);

  React.useEffect(() => {
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const handleUpdateIncome = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'custom_wage':income,
      'yearly_increase_percentage':yearlyIncreasePercentage
    });

    updateIncome(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  const onIncomeChange = (event, values) => {
    setIncome(null);
    if (event.target.value) {
      setIncome(event.target.value);
    }
  }

  const onYIPChange = (event, values) => {
    setYearlyIncreasePercentage(null);
    if (event.target.value) {
      setYearlyIncreasePercentage(event.target.value);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center;' }}>
          Add a custom income here. NOTE: This will affect your investment projections.
        </Typography>
        <TextField
          name="income"
          required
          fullWidth
          id="income"
          label="Income"
          autoFocus
          sx={{ mb: 4 }}
          type="number"
          onChange={onIncomeChange}
        />
        <TextField
          name="yearlyIncreasePercentage"
          required
          fullWidth
          id="yearlyIncreasePercentage"
          label="Yearly Increase Percentage"
          sx={{ mb: 4 }}
          type="number"
          onChange={onYIPChange}
        />
        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          onClick={handleUpdateIncome}
          disabled={income ? false : true}
        >
          Save Income
        </Button>
      </Box>
    </React.Fragment>
  );
};
