import { apiUrl } from '../constants/globals';

export async function getFavorites(token, type) {
    try {
        const response = await fetch(apiUrl + '/students/favorites/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentFavoritesAsEducator(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/favorites/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addFavorite(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/favorites/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteFavorite(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/favorites/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}