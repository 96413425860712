import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { careerPickerDisclaimer } from '../../../components/global/careerPickerDisclaimer';
import { getCareerTest } from '../../../services/careerPickerService';
import { Link } from '@mui/material';

export function CareerDecider({handleComplete, handleCareerFindModal, occupationDeciderData, setOccupationDeciderData, setOpenModal, setOpenModalText}) {
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [careerTest, setCareerTest] = React.useState([]);
    const [numberQuestion, setNumberQuestion] = React.useState(0);

    React.useEffect(() => {
        getCareerTest(localStorage.getItem('token')).then(test => {
            if (test.status == true) {
                let buildSelectedAnswers = {};
                setCareerTest(test.data);
                test.data?.forEach(question => {
                    buildSelectedAnswers[question.id] = occupationDeciderData[question.id] ? occupationDeciderData[question.id] : null;
                });
                setOccupationDeciderData(buildSelectedAnswers);
                let formCompletion = true;
                for (var key in buildSelectedAnswers) {
                    if (buildSelectedAnswers[key] === null) {
                        formCompletion = false;
                    }
                };
                setFormCompleted(formCompletion);
            } else {
                setOpenModal(true);
                setOpenModalText(test.message);
            }
        });
    }, []);

    const handleRadioClick = (question, answer, index) => {
        if (careerTest.length !== (index + 1)) {
            setTimeout(function() {
                setNumberQuestion(index + 1);
            }, 300);
        }
        let main = structuredClone(occupationDeciderData);
        main[question?.id] = answer?.id;
        setOccupationDeciderData(main);
        let formCompletion = true;
        for (var key in main) {
            if (main[key] === null) {
                formCompletion = false;
            }
        };
        setFormCompleted(formCompletion);
    }

    const handleBackButton = (index) => {
        setNumberQuestion(index - 1);
    }

    return (
        <>
            <Typography 
                variant="h4" 
                sx={{
                    textAlign:'center',
                    fontSize: {
                        xs: '18px',
                        sm: '22px',
                        md: '30px'
                    }
                }}
            >
            Answer a few questions and we will suggest a career.
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            {careerTest?.length > 0 ? (
                <>
                    {careerTest?.map((question, masterIndex) => (
                        <>
                        {masterIndex === numberQuestion && (
                            <Grid container spacing={2} sx={{mt:5, mb: 5}} key={masterIndex}>
                                <Grid item xs={12} sm={2} md={3}>
                                </Grid>
                                <Grid item xs={12} sm={8} md={6}>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            backgroundColor: '#fdfdfd',
                                            border: '1px solid #e5e5e5',
                                            textAlign:'center',
                                            pl: 2,
                                            pr:2,
                                            pt:8,
                                            pb:8,
                                            '&:hover': {
                                                boxShadow: '1px 1px 1px #ccc'
                                            }
                                        }}
                                    >
                                    <Typography 
                                        component='div'
                                        color='secondary'
                                        sx={{
                                            fontSize:'20px',
                                            mb:2
                                        }}
                                    >
                                    {masterIndex + 1} / {careerTest.length}
                                    </Typography>
                                    <Typography 
                                        component='div'
                                        sx={{
                                            fontSize:'30px',
                                            mb:2
                                        }}
                                    >
                                    {question.title}.
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup 
                                            row
                                            value={occupationDeciderData[question.id]}
                                        >
                                            <Grid container spacing={5}>
                                            {question?.answers?.slice().reverse().map((answer, childIndex) => (
                                                <Grid item xs={6}>
                                                <FormControlLabel 
                                                    value={answer.id} 
                                                    control={<Radio 
                                                        icon={answer.title === 'No' ? <CloseIcon sx={{fontSize:'60px'}} /> : <CheckIcon sx={{fontSize:'60px'}}  />}
                                                        checkedIcon={answer.title === 'No' ? <CloseIcon sx={{fontSize:'60px'}}  /> : <CheckIcon sx={{fontSize:'60px'}}  />}
                                                        sx={{
                                                            border:'2px solid'
                                                        }}
                                                    />} 
                                                    key={childIndex}
                                                    onClick={() => handleRadioClick(question, answer, masterIndex)}
                                                    sx={{
                                                        p:0,
                                                        m:0
                                                    }}
                                                />
                                                </Grid>
                                            ))}
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                    {masterIndex !== 0 && (
                                        <Link 
                                            sx={{
                                                display:'block',
                                                mt:2,
                                                cursor:'pointer'
                                            }}
                                            onClick={() => handleBackButton(masterIndex)}
                                            color='secondary'
                                        >
                                            <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back
                                        </Link>
                                    )}
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={2} md={3}>
                                </Grid>
                            </Grid>
                        )}
                        </>
                    ))}
                </>
            ) : (
                <>
                    <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                    Quiz is loading...
                    </Typography>
                </>
            )}
            <Grid container spacing={2} sx={{ px: {
                xs:1,
                sm:6,
                md:12,
                lg:25,
                xl:30
            },
            mt: 3, mb: 2
            }}>
                <Grid item xs={12} sm={6}>
                    <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    onClick={handleCareerFindModal(false)}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                    fullWidth
                    variant="contained"
                    disableElevation
                    onClick={handleComplete}
                    disabled={formCompleted ? false : true}
                    >
                    Next Step
                    </Button>
                </Grid>
                {careerPickerDisclaimer}
            </Grid>
        </>
    )
}