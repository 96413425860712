import React, { Suspense } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteBillingMethod } from '../../../../../../services/studentBillingService';
import { CardAdd } from './add';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { stripePublicKey } from '../../../../../../constants/globals';

const stripePromise = loadStripe(stripePublicKey);

const drawerStyle = {
    width: '80%',
    '@media (max-width: 800px)': {
        width: '100%'
    }
}

export function CardList({subscription, cards, setCards, setError, setErrorText}) {
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
    }

    const deleteCard = (card) => {
        let cardData = new URLSearchParams({
            'card':card
        });

        deleteBillingMethod(localStorage.getItem('token'), cardData).then(response => {
            if (response.status == true) {
                const newCardList = cards.filter((cardList) => cardList.id !== card);
                setCards(newCardList);
            } else {
                setError(true);
                setErrorText(response.errors);
            }
        });
    };

    return (
        <>
            <Typography color='secondary' variant='h6' component='div'>
                Payment Methods
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 1, mb: 2 }} />
            {cards?.length > 0 ? (
                <>
                    {cards?.map((card, index) => (
                        <Grid 
                            key={index} 
                            container 
                            p={1} 
                            mb={2}
                            alignItems="center"
                            sx={[
                                {'&:hover': {boxShadow:'1px 1px #E5E5E5'}},
                                {border:'1px solid #E5E5E5', borderRadius:1}
                            ]}
                        >
                            <Grid item xs={9}>
                                {card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)} &#8226; &#8226; &#8226; &#8226; {card.card.last4}
                                {card?.is_primary && (
                                    <>
                                        &nbsp; <Chip 
                                                    label="Used For Your Subscription" 
                                                    color="primary" 
                                                    size="small" 
                                                    sx={{
                                                        color:'white',
                                                        fontSize: '1'
                                                    }}
                                                />
                                    </>
                                )}
                                <br />
                                <Typography fontSize="14px">
                                    Expires On {card.card.exp_month}/{card.card.exp_year}
                                </Typography>
                                <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }}></Divider>
                                <Typography fontSize="14px">
                                    {card.billing_details.address.line1}, {card.billing_details.address.city} {card.billing_details.address.state}, {card.billing_details.address.postal_code}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sx={{textAlign: 'right'}}>
                                <Tooltip arrow title={card?.is_primary ? "You cannot delete a primary card. You must add a new card and add it as the primary card." : ""}>
                                    <span>
                                        <Button
                                            color="secondary"
                                            disabled={card?.is_primary && true}
                                            onClick={() => {deleteCard(card?.id)}}
                                        >
                                            <DeleteIcon />
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                </>
            ) : (
                <Typography
                    color='secondary'
                    component='div'
                    sx={{ mt: 3, mb: 3, textAlign:'center'}}
                >
                    No Payment Methods Found
                </Typography>
            )}
            <Button 
                variant="text" 
                sx={{fontWeight:'bold'}}
                onClick={setOpenDrawer}
            >
                + Add A New Payment Method
            </Button>
            <Drawer
                open={openDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {Object.keys(subscription).length !== 0 && (
                    <Elements 
                        stripe={stripePromise} 
                        options={{
                            mode: 'payment', 
                            amount: subscription?.plan.amount,
                            currency: 'usd',
                            paymentMethodCreation: 'manual',
                        }}
                    >
                        <CardAdd
                            subscription={subscription}
                            setCards={setCards}
                            handleCloseDrawer={handleCloseDrawer}
                            setError={setError}
                            setErrorText={setErrorText}
                        />
                    </Elements>
                )}
            </Drawer>
        </>
    );
};