import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { storageUrl } from '../../../../constants/globals';
import { revokeEducatorInvite, approveEducators, disapproveEducators } from '../../../../services/studentActionService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function EducatorList({educators, user, setViewEducator}) {
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    const revokeEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        revokeEducatorInvite(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(update?.message);
            }
        });
    };

    const disapproveEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        disapproveEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(update?.message);
            }
        })
    };

    const approveEducatorConnection = (id) => {
        let data = new URLSearchParams({
            'id':id
        });

        approveEducators(localStorage.getItem('token'), data).then(update => {
            if (update?.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(update?.message);
            }
        })
    };

    return (
        <>
            {educators?.length > 0 ? (
                <Grid container>
                    <Grid item xs={12} sx={{padding:1}}>
                        {educators?.map((educator, index) => (
                            <Box sx={{ width:'100%'}} key={index} >
                                <Grid 
                                    container 
                                    spacing={2}
                                    alignItems="center" 
                                    justifyContent="center" 
                                >
                                    <Grid item xs={12} sm={8}>
                                        
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={12} sm={4} md={3} textAlign={'center'}>

                                                <div
                                                    style={{
                                                        height: "70px",
                                                        width: "70px",
                                                        border: "1px solid #777",
                                                        borderRadius: "200px",
                                                        overflow: "hidden",
                                                        cursor: "pointer",
                                                        position: "relative",
                                                        margin:'auto'
                                                    }}
                                                >
                                                    {!educator?.profile_image && (
                                                    <>
                                                        <PersonIcon
                                                            fontSize="large"
                                                            style={{
                                                                position: "absolute",
                                                                top: "34%",
                                                                left: "26%",
                                                                transform: "translateX(-50%) translateY(-50%)",
                                                                zIndex: 0,
                                                                color: "#999",
                                                                transform: "scale(2.5)"
                                                            }}
                                                        />
                                                    </>
                                                    )}
                                                    {educator?.profile_image && (
                                                    <>
                                                        <img
                                                        src={storageUrl + educator?.profile_image}
                                                        style={{
                                                            height: "100%",
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translateX(-50%) translateY(-50%)",
                                                            zIndex: 1
                                                        }}
                                                        />
                                                    </>
                                                    )}
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9}>
                                                <Typography color='secondary' variant='h6'>{educator?.first_name} {educator?.last_name}</Typography>
                                                <Typography color='secondary' component='div' sx={{mb:1}}>{educator?.institution?.institution_name}</Typography>
                                                {educator?.active === 1 && (
                                                    <Chip size='small' sx={{backgroundColor:'green', color:'#fff', mr:1, mb:1}} label='Active' />
                                                )}
                                                {educator?.active === 0 && (
                                                    <Chip size='small' label='Inactive' sx={{mr:1, mb:1}} />
                                                )} 
                                                {educator?.pending === 1 && (
                                                    <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Your Approval' />
                                                )}
                                                {educator?.pending === 2 && (
                                                    <Chip size='small' color='primary' sx={{color:'#fff', mr:1, mb:1}} label='Pending Educator Approval' />
                                                )}
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} sm={4} textAlign={'right'}>
                                        {educator?.pending === 1 ? (
                                            <>
                                                <Tooltip arrow title="This educator would like to connect, click to approve them. NOTE: By approving this educator they will be able to see your profile including scholarships and loans.">
                                                    <IconButton
                                                        variant='contained'
                                                        size='small'
                                                        disabled={user?.account_locked !== true ? false : true}
                                                        onClick={() => { approveEducatorConnection(educator.id) }}
                                                    >
                                                        <CheckIcon sx={{color:'green'}} />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip arrow title="This educator would like to connect, click to disapprove them.">
                                                    <IconButton
                                                        variant='contained'
                                                        size='small'
                                                        disabled={user?.account_locked !== true ? false : true}
                                                        onClick={() => { disapproveEducatorConnection(educator.id) }}
                                                    >
                                                        <CloseIcon sx={{color:'red'}} />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant='contained'
                                                    disableElevation
                                                    size='small'
                                                    disabled={!user?.account_locked && educator?.pending !== 2 ? false : true}
                                                    onClick={() => { setViewEducator(educator) }}
                                                >
                                                    View
                                                </Button>
                                                {educator?.pending === 2 && (
                                                    <Tooltip arrow title="Revoke your request to connect with this educator.">
                                                        <IconButton
                                                            variant='contained'
                                                            size='small'
                                                            disabled={user?.account_locked !== true ? false : true}
                                                            onClick={() => { revokeEducatorConnection(educator?.id) }}
                                                        >
                                                            <CloseIcon sx={{color:'red'}} />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                {index !== educators?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                            </Box>
                        ))}
                    </Grid>
                </Grid>
            ) : (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                You currently have no educators at this time. You can add one by entering their email above.
                </Typography>
            )}
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                </Typography>
                </Box>
            </Modal>
        </>
    )
}