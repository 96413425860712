import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { getOccupationsList } from '../../../../services/employmentService';
import { getComparisons, getCalculatorComparison, addSuggestion } from '../../../../services/suggestionsService';
import { updateOccupation } from '../../../../services/employmentService';
import { getFavorites } from '../../../../services/favoriteService';
import { AddRemoveFavorite } from '../../favorites/addRemoveFavorite';
import { CalculatorComparisonBlock } from './investmentCalculatorView/calculator';

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;

        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
                {value}
            </text>
        );
    }
}

export function OccupationBlock({type, selectedSuggestion, student, setReloadList, handleCloseDrawer, setError, setErrorText}) {
    const [occupations, setOccupations] = React.useState([]);
    const [occupation, setOccupation] = React.useState(null);
    const [comparison, setComparison] = React.useState({});
    const [calculatorComparison, setCalculatorComparison] = React.useState({});
    const [investmentId, setInvestmentId] = React.useState(null);
    const [favorites, setFavorites] = React.useState([]);
    const [comparisonDescription, setComparisonDescription] = React.useState(null);

    const year = new Date().getFullYear();
    const beginningYear = Math.floor(year/10)*10;
    const endYear = Math.ceil(year/10)*10;

    React.useEffect(() => {
        getOccupationsList().then(getOccupationsList => {
            setOccupations(getOccupationsList.data);
        });
        
        if (Object.keys(selectedSuggestion).length !== 0) {
            let comparisonData = structuredClone(selectedSuggestion);
            comparisonData.details['graph_data'] = buildGraphData(comparisonData.details.current_selection, comparisonData.details.suggestion);
            setComparison(comparisonData);
            getCalculatorComparison(localStorage.getItem('token'), student?.id, 'occupation', selectedSuggestion.suggestion_id?.replace(/\./g, '-')).then(comparison => {
                if (comparison.status == true) {
                    setCalculatorComparison(comparison.data);
                } else {
                    setError(true);
                    setErrorText(comparison.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
        };
        if (type === 'student') {
            setInvestmentId(student.investments[0].id);
            getFavorites(localStorage.getItem('token'), 2).then(favorites => {
                if (favorites.status == true) {
                    setFavorites(favorites.data);
                } else {
                    setError(true);
                    setErrorText(favorites.message);
                }
            }).catch(err => {
                localStorage.removeItem('token');
                window.location.href = '/login';
            });
        }
    }, []);

    const handleAddSuggestion = () => {
        let data = new URLSearchParams({
            'suggestion_id':comparison?.suggestion_id,
            'type':comparison?.type,
            'description':comparisonDescription,
            'attached_user': type === 'student' ? null : student?.id
        });
        addSuggestion(localStorage.getItem('token'), data).then(suggestion => {
            if (suggestion.status) {
                setReloadList(suggestion);
                handleCloseDrawer();
            } else {
                setError(true);
                setErrorText(suggestion.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error adding the suggestion.');
        });
        handleCloseDrawer();
    };

    const handleUpdateOccupation = (event) => {
        let data = new URLSearchParams({
            'occupation':comparison?.suggestion_id,
            'industry':null
        });

        updateOccupation(localStorage.getItem('token'), investmentId, data).then(suggestion => {
            setReloadList(suggestion);
            handleCloseDrawer();
        }).catch(err => {
            setError(true);
            setErrorText('There was an error.');
        });
    };

    const onSuggestionDescriptionChange = (event) => {
        setComparisonDescription(event.target.value);
    };

    const onOccupationChange = (event, values) => {
        setOccupation(null);
        if (values && values.occupation_code !== null) {
            setOccupation(values.occupation_code);
            getComparisons(localStorage.getItem('token'), student.id, values.occupation_code?.replace(/\./g, '-'), 'occupation').then(comparisonData => {
                if (comparisonData.status == true) {
                    let comparisons = structuredClone(comparisonData.data);
                    comparisons.details['graph_data'] = buildGraphData(comparisons.details.current_selection, comparisons.details.suggestion);
                    setComparison(comparisons);
                    getCalculatorComparison(localStorage.getItem('token'), student?.id, 'occupation', comparisonData.data.suggestion_id?.replace(/\./g, '-')).then(comparison => {
                        if (comparison.status == true) {
                            setCalculatorComparison(comparison.data);
                        } else {
                            setError(true);
                            setErrorText(comparison.message);
                        }
                    }).catch(err => {
                        localStorage.removeItem('token');
                        window.location.href = '/login';
                    });
                } else {
                    setError(true);
                    setErrorText(comparisonData.message);
                    handleCloseDrawer();
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
                handleCloseDrawer();
            });
        }
    }

    const buildGraphData = (current, selected) => {
        let graphData= [];
        for (var i = beginningYear; i <= endYear; i++) {
            graphData.push({'year': i});
        }
        for (let i = 0; i < graphData.length; i++) {
            let yearlyCurrentEmploymentIncrease = current.employment_current + ((current.employment_change / (endYear - beginningYear)) * i);
            let yearlySelectedEmploymentIncrease = selected.employment_current + ((selected.employment_change / (endYear - beginningYear)) * i);
            graphData[i]['current'] = yearlyCurrentEmploymentIncrease;
            graphData[i]['selected'] = yearlySelectedEmploymentIncrease;
        }
        return graphData;
    }

    const getComparisonPercentage = (suggested, current) => {
        return Math.ceil(((suggested - current) / current) * 100);
    }

    const getComparison = (suggested, current) => {
        return suggested - current;
    }

    const getTrainingComparison = (suggested, current) => {
        if (suggested != 1 && suggested != 6) {
            return 'Most likely paid training';
        }
        if (suggested == 1) {
            return 'Training unlikely paid';
        }
        return 'N/A';
    }

    return (
        <Box sx={{ width: '100%', position:'relative' }}>
            {Object.keys(selectedSuggestion).length === 0 && (
                <Autocomplete
                    disablePortal
                    id="occupation"
                    options={occupations}
                    name="occupation"
                    fullWidth
                    onChange={onOccupationChange}
                    getOptionLabel={occupations => occupations.occupation_title}
                    renderInput={(params) => <TextField {...params} required label="Desired Occupation" />}
                    sx={{mb: 2}}
                />
            )}
            {Object.keys(comparison).length === 0 ? (
                <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 20, pb: 20 }}>
                    Start searching for an occupation to compare with what {type === 'student' ? 'you have' : 'this student has'} selected.
                </Typography>
            ) : (
                <>
                    {comparison?.description && comparison?.description !== null && (
                        <>
                            <Typography color='primary' variant='h6' component='div' sx={{mt: '-20px'}}>
                                Note from educator:
                            </Typography>
                            <Typography component='div'>
                                {comparison?.description}
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 1, mb: 1 }} />
                        </>
                    )}
                    {(type === 'student' && Object.keys(comparison).length !== 0) && (
                        <Box
                            sx={{
                                position:'absolute',
                                right: 1,
                                top: Object.keys(selectedSuggestion).length !== 0 ? 5 : 1,
                                zIndex: 9
                            }}
                        >
                            <AddRemoveFavorite
                                allFavorites={favorites}
                                setAllFavorites={setFavorites}
                                potentialFavorite={{
                                    id: comparison?.suggestion_id,
                                    type: 2,
                                    title: comparison?.details?.suggestion?.occupation_title
                                }}
                                size={'small'}
                                setError={setError}
                                setErrorText={setErrorText}
                            />
                        </Box>
                    )}
                    {Object.keys(calculatorComparison).length !== 0 && (
                        <CalculatorComparisonBlock
                            comparison={{
                                'current': comparison?.details?.current_selection?.occupation_title,
                                'suggestion': comparison?.details?.suggestion?.occupation_title
                            }}
                            calculatorComparison={calculatorComparison}
                            type={'occupation'}
                        />
                    )}
                    <Table size='small' sx={{mb:2}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>Further Breakdown</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>{comparison?.details?.suggestion?.occupation_title}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>{comparison?.details?.current_selection?.occupation_title} (Selected By {type === 'student' ? 'You' : 'Student'})</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Comparison Data</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Annual Wage</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>${comparison?.details?.suggestion?.median_annual_wage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>${comparison?.details?.current_selection?.median_annual_wage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.median_annual_wage, comparison?.details?.current_selection?.median_annual_wage)}%
                                        {getComparisonPercentage(comparison?.details?.suggestion?.median_annual_wage, comparison?.details?.current_selection?.median_annual_wage) > 0 && (
                                            <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                        )}
                                        {getComparisonPercentage(comparison?.details?.suggestion?.median_annual_wage, comparison?.details?.current_selection?.median_annual_wage) < 0 && (
                                            <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Current Employment</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.suggestion?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.current_selection?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_current, comparison?.details?.current_selection?.employment_current)}%
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_current, comparison?.details?.current_selection?.employment_current) > 0 && (
                                            <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                        )}
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_current, comparison?.details?.current_selection?.employment_current) < 0 && (
                                            <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Projected Employment</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.suggestion?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.current_selection?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_projected, comparison?.details?.current_selection?.employment_projected)}%
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_projected, comparison?.details?.current_selection?.employment_projected) > 0 && (
                                            <ArrowUpwardIcon sx={{ color: 'green', mb: '-5px', ml: '5px' }} />
                                        )}
                                        {getComparisonPercentage(comparison?.details?.suggestion?.employment_projected, comparison?.details?.current_selection?.employment_projected) < 0 && (
                                            <ArrowDownwardIcon sx={{ color: 'red', mb: '-5px', ml: '5px' }} />
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Required Education</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.suggestion?.entry_level_education}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.current_selection?.entry_level_education}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparison(comparison?.details?.suggestion?.education_code, comparison?.details?.current_selection?.education_code) < 0 && (
                                            'Greater Investment Required'
                                        )}
                                        {getComparison(comparison?.details?.suggestion?.education_code, comparison?.details?.current_selection?.education_code) > 0 && (
                                            'Less Investment Required'
                                        )}
                                        {getComparison(comparison?.details?.suggestion?.education_code, comparison?.details?.current_selection?.education_code) == 0 && (
                                            'N/A'
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>Work Experience Requirements</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.suggestion?.work_experience_requirements}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.current_selection?.work_experience_requirements}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getComparison(comparison?.details?.suggestion?.work_experience_code, comparison?.details?.current_selection?.work_experience_code) > 0 && (
                                            'More Training Required'
                                        )}
                                        {getComparison(comparison?.details?.suggestion?.work_experience_code, comparison?.details?.current_selection?.work_experience_code) < 0 && (
                                            'Less Training Required'
                                        )}
                                        {getComparison(comparison?.details?.suggestion?.work_experience_code, comparison?.details?.current_selection?.work_experience_code) == 0 && (
                                            'N/A'
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight='bold' color='secondary'>On The Job Training Provided</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.suggestion?.on_the_job_training}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold'>{comparison?.details?.current_selection?.on_the_job_training}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography fontWeight='bold' color='primary'>
                                        {getTrainingComparison(comparison?.details?.suggestion?.training_code, comparison?.details?.current_selection?.training_code)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Typography color='secondary' variant='h6' component='div' sx={{mb:1}}>
                        Projected Jobs Next 10 Years
                    </Typography>
                    <Box sx={{ width:'100%', height: '455px' }}>
                        <ResponsiveContainer width='100%' height='100%'>
                            <LineChart
                            data={comparison.details.graph_data}
                            margin={{
                                top: 5,
                                left: 0,
                                bottom: 5
                            }}
                            >
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='year' />
                            <YAxis />
                            <Legend />
                            <Line type='monotone' dataKey='current' name={comparison?.details?.current_selection?.occupation_title} stroke='#333' label={<CustomizedLabel />} />
                            <Line type='monotone' dataKey='selected' name={comparison?.details?.suggestion?.occupation_title} stroke='#fa7918' label={<CustomizedLabel />} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 1 }} />
                    {type === 'student' && (
                        <Button
                            variant="contained"
                            type="submit"
                            disableElevation
                            size="large"
                            fullWidth
                            onClick={handleUpdateOccupation}
                        >
                            Change To This Occupation
                        </Button>
                    )}
                    {type === 'self_educator' && Object.keys(selectedSuggestion).length === 0 && (
                        <>
                            <TextField
                                name="description"
                                fullWidth
                                id="description"
                                label="Add A Note"
                                type="text"
                                multiline
                                rows={5}
                                value={comparisonDescription}
                                onChange={onSuggestionDescriptionChange}
                                sx={{mb: 2}}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                disableElevation
                                size="large"
                                fullWidth
                                onClick={handleAddSuggestion}
                            >
                                Suggest This Occupation
                            </Button>
                        </>
                    )}
                </>
            )}
        </Box>
    )
}