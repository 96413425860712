import { Link, Box, Divider, Paper, Typography, Chip } from '@mui/material';
import { appUrl } from '../../constants/globals';
import React from 'react';

export const Notifications = ({notifications}) => {

    const parseDate = (date) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let d = new Date(date);
        return months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
    }

  return (
    <Paper sx={{
        p:1, 
        border:'1px solid #F1F1F1', 
        minWidth:'200px',
        maxWidth:'200px',
        maxHeight:'300px',
        overflowY: 'scroll'
    }}>
        {notifications && notifications?.length > 0 ? (
            <>
                {notifications?.map((notification, index) => (
                    <Box 
                        sx={{ width: '100%'}} 
                        key={index}
                    >
                        <Link href={appUrl + '/' + notification.deep_link} color="secondary" sx={{fontSize: '14px', '&:hover': {color: '#fa7918'}}}  underline="none">
                            {notification.notification} - {parseDate(notification.created_at)}&nbsp;
                            {notification?.seen === 0 && (
                                <Chip color='primary' size='small' sx={{color:'#fff', fontSize: '10px', height:'18px'}} label='New!' />
                            )}
                        </Link>
                        {index !== notifications?.length - 1 ? <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />: null}
                    </Box>
                ))}
            </>
        ) : (
            <Typography sx={{textAlign:'center', pt:2, pb:2}} color={'secondary'}>
                No notifications found!
            </Typography>
        )}
    </Paper>
  );
};