import * as React from 'react';
import Typography from '@mui/material/Typography';

export const studentDisclaimer = (
  <React.Fragment>
    <Typography component='div' sx={{ textAlign: 'center', fontSize: 10, mt: 4 }}>
      <strong>DISCLAIMER:</strong> Until you add in loans or scholarships, our software assumes you are taking out a Direct Subsidized loan (using the current federal interest rate) with a 12 year Standard Fixed repayment plan on the total yearly tuition (four years if Bachelor's degree, six for Master's degree and 12 for Doctorate) of your chosen institution. Our data is refreshed on a day to day basis and may not be accurate to real time. College numbers may not be 100% accurate and may not be the exact amount you will pay, it is advised that you consult a guidance counselor for that information. This software is not intended to replace a financial advisor.
    </Typography>
  </React.Fragment>
);
