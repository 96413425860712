import { apiUrl } from '../constants/globals';

export async function getAllUserDocuments(token) {
    try {
        const response = await fetch(apiUrl + '/user/all/documents/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserDocumentsByReferrer(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/documents/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function downloadEmployeeDocument(token, employeeId, documentId, filename) {
    try {
        const response = await fetch(apiUrl + '/user/download/employee/documents/' + employeeId + '/' + documentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
    } catch(error) {
        return error;
    }
}

export async function downloadDocument(token, id, filename) {
    try {
        const response = await fetch(apiUrl + '/user/download/documents/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
    } catch(error) {
        return error;
    }
}

export async function getEmployeeDocumentsByStudent(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/user/documents/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addDocument(token, data) {
    try {
        const response = await fetch(apiUrl + '/user/documents/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateDocument(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/user/documents/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteDocument(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/documents/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}