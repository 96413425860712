import React, { PureComponent } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { getSubscription, createSubscription, removeSubscription, getPromoCode, updateDefaultCardBasedOnSubscription } from '../../../../../services/educatorBillingService';
import { educatorPaymentsUrl } from '../../../../../constants/globals';
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const SubscribeComponent = ({priceID, productID, products, setChosenPrice, setChosenProduct}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [selectedProductData, setSelectedProductData] = React.useState(null);
    const [selectedPricingData, setSelectedPricingData] = React.useState(null);
    const [stripeComplete, setStripeComplete] = React.useState(false);
    const [paymentComplete, setPaymentComplete] = React.useState(false);
    const [addressComplete, setAddressComplete] = React.useState(false);
    const [completedButtonText, setCompletedButtonText] = React.useState('Submit');
    const [promoCode, setPromoCode] = React.useState(null);

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    const handleRemoveChosenPricing = () => {
        setChosenPrice(null);
        setChosenProduct(null);
    };

    React.useEffect(() => {
        setStripeComplete(false);
        if (paymentComplete && addressComplete) {
            setStripeComplete(true);
        }
    });

    const paymentElementChange = (element) => {
        setPaymentComplete(false);
        if (!element.empty && element.complete) {
            setPaymentComplete(true);
        }
    }

    const addressElementChange = (element) => {
        setAddressComplete(false);
        if (!element.empty && element.complete) {
            setAddressComplete(true);
        }
    }

    const onPromoCodeChange = (event, values) => {
      setPromoCode(null);
      if (event.target.value) {
        setPromoCode(event.target.value);
      }
    }

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setStripeComplete(false);
        setAddressComplete(false);
        setPaymentComplete(false);
        setCompletedButtonText('Working');

        if (promoCode) {
            let promoData = new URLSearchParams({
                'promocode':promoCode
            });
            await getPromoCode(localStorage.getItem('token'), promoData).then(promocode => {
                if (promocode.status === false) {
                    setOpenErrorModal(true);
                    setOpenErrorModalText('Please select a valid promo code.');
                    setStripeComplete(true);
                    setAddressComplete(true);
                    setPaymentComplete(true);
                    setCompletedButtonText('Submit');
                } else {
                    checkOldSubscriptions(promocode.data.id);
                }
            }).catch((error) => {
                setOpenErrorModal(true);
                setOpenErrorModalText('Please select a valid promo code.');
            });
        } else {
            checkOldSubscriptions(null);
        }
    };

    const checkOldSubscriptions = async (promocode) => {
        await getSubscription(localStorage.getItem('token')).then(subscription => {
            if (
                subscription.status == true && Object.keys(subscription.data).length !== 0 && (subscription.data.status != 'incomplete' || subscription.data.items.data[0].price.id !== priceID)
            ) {
                removeSubscription(localStorage.getItem('token')).then(subscription => {
                    submitNewSubscription(promocode);
                });
            } else if (
                subscription.status == true && Object.keys(subscription.data).length !== 0 && subscription.data.status == 'incomplete' && subscription.data.items.data[0].price.id === priceID
            ) {
                if(subscription.data?.latest_invoice?.payment_intent === null) {
                    window.location.reload();
                } else {
                    submitPayment(subscription.data);
                }
            } else {
                submitNewSubscription(promocode);
            }
        });
    }

    const submitNewSubscription = async (promocode) => {
        let subscriptionData = new URLSearchParams({
            'price':priceID,
            'promocode':promocode
        });

        await createSubscription(localStorage.getItem('token'), subscriptionData).then(subscription => {
            if (subscription.status == true) {
                if(subscription.data?.latest_invoice?.payment_intent === null) {
                    window.location.reload();
                } else {
                    submitPayment(subscription.data);
                }
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(subscription.errors);
            }
        });
    }

    const submitPayment = async (subscription) => {
        const {error: submitError} = await elements.submit();
        if (submitError) {
            setOpenErrorModal(true);
            setOpenErrorModalText('Error submitting payment, please try again.');
            return;
        }
                
        const paymentResult = await stripe.confirmPayment({
            elements,
            clientSecret: subscription?.latest_invoice?.payment_intent?.client_secret,
            confirmParams: {
                return_url: educatorPaymentsUrl,
            },
            redirect: 'if_required'
        });
        
        if (paymentResult?.error) {
            setOpenErrorModal(true);
            setOpenErrorModalText(paymentResult.error.message);
        } else {
            if (paymentResult?.paymentIntent.status === "succeeded") {
                getSubscription(localStorage.getItem('token')).then(subscription => {
                    if (subscription?.data?.default_payment_method !== null || subscription?.data?.latest_invoice?.payment_intent?.payment_method !== null) {
                        let cardData = new URLSearchParams({
                            'subscription':subscription?.data.id
                        });
                        updateDefaultCardBasedOnSubscription(localStorage.getItem('token'), cardData).then(card => {
                            if (card.status !== true) {
                                setOpenErrorModal(true);
                                setOpenErrorModalText(card.errors);
                            } else {
                                window.location.reload();
                            }
                        });
                    } else {
                        window.location.reload();
                    }
                });
            }
        }

        setStripeComplete(true);
        setAddressComplete(true);
        setPaymentComplete(true);
        setCompletedButtonText('Submit');
    }

    React.useEffect(() => {
        products.forEach(product => {
            if (product.id === productID) {
                setSelectedProductData(product);
                product.pricing.forEach(pricing => {
                    if (pricing.id === priceID) {
                        setSelectedPricingData(pricing);
                    }
                });
            }
        });
    }, []);

    return (
        <>
            <Typography color='secondary' variant='h4' component='div'>
                Your Subscription
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Paper sx={{ p: 2 }}>
                <Grid item xs={12}>
                    <Link onClick={handleRemoveChosenPricing} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                        <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Choose a different subscription
                    </Link>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                </Grid>
                <FormControl style={{minWidth: '100%'}}>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Billing Information</Divider>
                            <AddressElement 
                                options={{mode: 'billing'}}
                                onChange={addressElementChange}
                            />
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Payment Information</Divider>
                            <PaymentElement
                                onChange={paymentElementChange}
                            />
                            <Typography color={'primary'} component="p" sx={{fontSize:'12px', fontWeight:'bold', mt:1}}>
                                This card will now be the default card for both your subscription and your advertising balance.
                            </Typography>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Promo Code</Divider>
                            <Grid container>
                                <TextField
                                    fullWidth
                                    id="promocode"
                                    name="promocode"
                                    label="Enter A Promo Code"
                                    onChange={onPromoCodeChange}
                                />

                                <Typography color={'secondary'} component="p" sx={{fontSize:'12px', mt:2}}>
                                    Promo code will be applied to the total after checkout.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Purchase Information</Divider>
                            <Grid container alignItems="center">
                                <Grid item xs={9}>
                                    <strong>{selectedProductData?.name} Tier</strong>
                                    <p>{selectedProductData?.description}</p>
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    ${selectedPricingData?.unit_amount.toString().substring(0, selectedPricingData?.unit_amount.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    &nbsp;/&nbsp;{selectedPricingData?.recurring.interval}
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }}>Total Due Today</Divider>
                            <Grid container>
                                <Grid item xs={9}>
                                    Subtotal:
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    ${selectedPricingData?.unit_amount.toString().substring(0, selectedPricingData?.unit_amount.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container>
                                <Grid item xs={9}>
                                    Taxes:
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    $0.00
                                </Grid>
                            </Grid>
                            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                            <Grid container sx={{mb:2}}>
                                <Grid item xs={9}>
                                    <strong>Grand Total:</strong>
                                </Grid>
                                <Grid item xs={3} sx={{textAlign: 'right'}}>
                                    <strong>${selectedPricingData?.unit_amount.toString().substring(0, selectedPricingData.unit_amount.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.00</strong>
                                </Grid>
                            </Grid>
                            <Button 
                                fullWidth
                                disableElevation
                                variant='contained'
                                disabled={stripeComplete ? false : true}
                                onClick={handleSubmit}
                            >
                                {completedButtonText}
                            </Button>
                            <Typography color={'secondary'} component="p" sx={{fontSize:'12px', textAlign:'center', mt:2}}>
                                Once you click register you will be charged immediately and a recurring amount 
                                will be charged to your account monthly. You can cancel or update your subscription at any time.
                            </Typography>
                        </Grid>
                    </Grid>
                </FormControl>
                <Modal
                    open={openErrorModal}
                    onClose={handleCloseErrorModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        {openErrorModalText}
                        </Typography>
                    </Box>
                </Modal>
            </Paper>
        </>
    )
};

export default SubscribeComponent;
