import { apiUrl } from '../constants/globals';

export async function getScholarships(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarships/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentScholarships(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/scholarships/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getScholarship(token, scholarshipId) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarships/' + scholarshipId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addScholarship(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarships/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateScholarship(token, scholarshipId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarships/' + scholarshipId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteScholarship(token, scholarshipId) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarships/' + scholarshipId, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getScholarshipTypes(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/scholarshipTypes/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
