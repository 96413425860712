import { apiUrl } from '../constants/globals';

export async function getSponsoredInstitutions(token, investmentId) {
    try {
        const response = await fetch(apiUrl + '/advertisements/sponsoredInstitutions/' + investmentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getSponsoredOccupations(token, investmentId) {
    try {
        const response = await fetch(apiUrl + '/advertisements/sponsoredOccupations/' + investmentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getSponsoredLoans(token) {
    try {
        const response = await fetch(apiUrl + '/advertisements/sponsoredLoans/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getSponsoredScholarships(token) {
    try {
        const response = await fetch(apiUrl + '/advertisements/sponsoredScholarships/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAdvertisements(token, type) {
    try {
        const response = await fetch(apiUrl + '/advertisements/get/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function clickAdvertisement(token, id, category) {
    try {
        const response = await fetch(apiUrl + '/advertisements/click/' + id + '/' + category, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAllInstitutionAdvertisements(token, type) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addInstitutionAdvertisement(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAdvertisementCategories(token, type) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/categories/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateInstitutionAdvertisement(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteInstitutionAdvertisement(token, id) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addInstitutionAdvertisementImage(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/image/' + id, {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteInstitutionAdvertisementImage(token, id) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/image/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAdvertisementReports(token, id, type) {
    try {
        const response = await fetch(apiUrl + '/educator/advertisements/reporting/' + id + '/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
