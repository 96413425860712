import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { addResumeItem, updateResumeItem } from '../../../services/resumeService';

export function ResumeItemView({type, student, selectedResumeItem, handleCloseDrawer, setReloadList, setError, setErrorText}) {
    const [jobName, setJobName] = React.useState(selectedResumeItem?.title);
    const [jobPosition, setJobPosition] = React.useState((selectedResumeItem?.job_title && selectedResumeItem?.job_title !== 'undefined') ? selectedResumeItem?.job_title : null);
    const [jobDescription, setJobDescription] = React.useState(selectedResumeItem?.description);
    const [jobLink, setJobLink] = React.useState(selectedResumeItem?.outbound_link);
    const [startDate, setStartDate] = React.useState(selectedResumeItem?.start_date);
    const [endDate, setEndDate] = React.useState(selectedResumeItem?.end_date);
    const [isPublic, setIsPublic] = React.useState(selectedResumeItem?.public);
    const [formCompleted, setFormCompleted] = React.useState(false);

    React.useEffect(() => {
        setFormCompleted(false);
        if (jobName) {
            setFormCompleted(true);
        }
    });

    const onJobNameChange = (event) => {
        setJobName(event.target.value);
    };

    const onJobPositionChange = (event) => {
        setJobPosition(event.target.value);
    };

    const onJobDescriptionChange = (event) => {
        setJobDescription(event.target.value);
    };

    const onJobLinkChange = (event) => {
        setJobLink(event.target.value);
    };

    const onStartDateChange = (date) => {
        setStartDate(date);
    };

    const onEndDateChange = (date) => {
        setEndDate(date);
    };

    const onPublicChange = (event) => {
        setIsPublic(event.target.value);
    };
    
    const formatFormDate = (date) => {
        return new Date(date);
    };

    const formatDate = (date) => {
        if (date === null) {
            return null;
        }

        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate  = new Date(date);
        var toLocale = formattedDate.toLocaleDateString('en-US', options);

        if (toLocale === 'Invalid Date') {
            return null;
        }

        return toLocale;
    };

    const handleSubmit = () => {
        let data = new URLSearchParams({
            'title':jobName,
            'job_title':jobPosition,
            'description':jobDescription,
            'start_date':formatDate(startDate),
            'end_date':formatDate(endDate),
            'outbound_link':jobLink,
            'public':isPublic
        });
        if (selectedResumeItem?.id) {
            updateResumeItem(localStorage.getItem('token'), data, selectedResumeItem?.id).then(resumeItem => {
                if (resumeItem.status === true) {
                    handleCloseDrawer();
                    setReloadList(resumeItem);
                } else {
                    setError(true);
                    setErrorText(resumeItem.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error updating your resume.');
            });
        } else {
            addResumeItem(localStorage.getItem('token'), data).then(resumeItem => {
                if (resumeItem.status === true) {
                    handleCloseDrawer();
                    setReloadList(resumeItem);
                    handleCloseDrawer();
                } else {
                    setError(true);
                    setErrorText(resumeItem.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error adding to your resume.');
            });
        }
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                        {Object.keys(selectedResumeItem).length === 0 ? 'Add Something To Your Resume' : 'Added On ' + formatDate(selectedResumeItem?.created_at)}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2 }} />
                </Grid>
                <Grid item xs={12} sm={type !== 'student' && !jobPosition ? 12 : 6}>
                    <TextField
                        name="title"
                        required={type === 'student' ? true : false}
                        fullWidth
                        id="title"
                        label="Job, Skill or Education"
                        type="text"
                        value={jobName}
                        onChange={onJobNameChange}
                        multiline
                        rows={1}
                        disabled={type === 'student' ? false : true}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                            }
                        }}
                    />
                </Grid>
                {(type === 'student' || jobPosition) && (
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="title"
                            fullWidth
                            id="title"
                            label="Position"
                            type="text"
                            value={jobPosition}
                            onChange={onJobPositionChange}
                            multiline
                            rows={1}
                            disabled={type === 'student' ? false : true}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                }
                            }}
                        />
                    </Grid>
                )}
                {(type === 'student' || jobDescription) && (
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="description"
                            fullWidth
                            id="description"
                            label="Description"
                            type="text"
                            multiline
                            rows={10}
                            value={jobDescription}
                            onChange={onJobDescriptionChange}
                            disabled={type === 'student' ? false : true}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                }
                            }}
                        />
                    </Grid>
                )}
                {(type === 'student' || jobLink) && (
                    <Grid item xs={12} sm={12}>
                        <Tooltip 
                            arrow 
                            title='Must include https://'
                            PopperProps={{style:{zIndex:9999}}}
                        >
                            <TextField
                                name="url"
                                fullWidth
                                id="url"
                                label="Relevant Url"
                                type="text"
                                value={jobLink}
                                onChange={onJobLinkChange}
                                multiline
                                rows={1}
                                disabled={type === 'student' ? false : true}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                            />
                        </Tooltip>
                    </Grid>
                )}
                {(type === 'student' || startDate) && (
                    <Grid item xs={12} sm={type !== 'student' && !endDate ? 12 : 6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label="Start Date"
                                disabled={type === 'student' ? false : true}
                                sx={{
                                    width:'100%',
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                                onChange={(newValue) => onStartDateChange(newValue)}
                                value={startDate ? formatFormDate(startDate) : null}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                {(type === 'student' || endDate) && (
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label="End Date"
                                disabled={type === 'student' ? false : true}
                                sx={{
                                    width:'100%',
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                                onChange={(newValue) => onEndDateChange(newValue)}
                                value={endDate ? formatFormDate(endDate) : null}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                {type === 'student' && (
                    <Grid item xs={12} sm={12}>
                        <Tooltip 
                            arrow 
                            title='This will make this resume item public to educators and employers.'
                            PopperProps={{style:{zIndex:9999}}}
                        >  
                            <FormControl fullWidth>
                                <InputLabel id="public">Public</InputLabel>
                                <Select
                                    labelId="public"
                                    id="public"
                                    label="Is Public"
                                    value={isPublic ? 1 : 0}
                                    onChange={onPublicChange}
                                    MenuProps={{
                                        style: {zIndex: 9999}
                                    }}
                                    sx={{
                                        textAlign:'left',
                                        '& .Mui-disabled': {
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                        }
                                    }}
                                >
                                    <MenuItem value={1}>Public</MenuItem>
                                    <MenuItem value={0}>Not Public</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                )}
                {type === 'student' && (
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            disableElevation
                            size='large'
                            sx={{width:'100%'}}
                            disabled={formCompleted ? false : true}
                            onClick={handleSubmit}
                        >
                            {Object.keys(selectedResumeItem).length === 0 ? 'Add To' : 'Update'} Resume
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    )
}