import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getInstitutionsList, updateInstitution } from '../../services/educationService';
import { userAccount } from '../../services/userService';

export const EducationModal = () => {

  const [institutions, setInstitutions] = React.useState([]);
  const [investmentId, setInvestmentId] = React.useState(null);
  const [institution, setInstitution] = React.useState(null);

  React.useEffect(() => {
    getInstitutionsList('*').then(getInstitutionsList => {
      setInstitutions(getInstitutionsList.data);
    });

    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const handleUpdateInstitution = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'institution':institution
    });

    updateInstitution(localStorage.getItem('token'), investmentId, data).then(update => {
      window.location.reload();
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  };

  const updateInstitutions = (event, values) => {
    var value = '*';
    if (event.target.value) {
      value = event.target.value;
    }
    getInstitutionsList(value).then(getInstitutionsList => {
      setInstitutions(getInstitutionsList.data);
    });
  }

  const onInstitutionChange = (event, values) => {
    setInstitution(null);
    if (values) {
      setInstitution(values.unitId);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
          Change your education institution here. NOTE: This will affect your investment projections.
        </Typography>
        <Autocomplete
          disablePortal
          id="institute"
          options={institutions}
          name="institute"
          fullWidth
          onChange={onInstitutionChange}
          getOptionLabel={institutions => institutions.name}
          renderInput={(params) => <TextField {...params} onChange={updateInstitutions} required label="Desired Institution" />}
          sx={{ mb: 4 }}
        />
        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          onClick={handleUpdateInstitution}
          disabled={institution ? false : true}
        >
          Save Education
        </Button>
      </Box>
    </React.Fragment>
  );
};
