import React, { PureComponent } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { educatorAccount, checkType } from '../../../../services/userService';
import { getSubscription, getProducts, getInvoices } from '../../../../services/educatorBillingService';
import { getInstitution } from '../../../../services/institutionService';
import { stripePublicKey } from '../../../../constants/globals';
import PricesComponent from './prices/prices';
import SubscribeComponent from './subscribe/subscribe';
import AccountComponent from './account/account';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const stripePromise = loadStripe(stripePublicKey);

const Subscription = () => {
  const [institution, setInstitution] = React.useState({});
  const [products, setProducts] = React.useState([]);
  const [invoices, setInvoices] = React.useState([]);
  const [chosenPrice, setChosenPrice] = React.useState(null);
  const [chosenProduct, setChosenProduct] = React.useState(null);
  const [stripeOptions, setStripeOptions] = React.useState({});
  const [recommendedProduct, setRecommendedProduct] = React.useState('Standard');
  const [subscriptionActivated, setSubscriptionActivated] = React.useState(false);
  const [subscriptionData, setSubscriptionData] = React.useState({});
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.user_level !== 1 || userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
    getProducts(localStorage.getItem('token')).then(products => {
      if (products.status == true) {
          setProducts(products.data);
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(products.message);
      }
    });
    getInstitution(localStorage.getItem('token')).then(institution => {
      if (institution.status == true) {
          setInstitution(institution.data);
          if (institution.data.actual_number_of_students > 0) {
            if (institution.data.actual_number_of_students > 200) {
              setRecommendedProduct('Enterprise');
            } else if (institution.data.actual_number_of_students > 100 && institution.data.actual_number_of_students <= 200) {
              setRecommendedProduct('Premium');
            } else if (institution.data.actual_number_of_students > 50 && institution.data.actual_number_of_students <= 100) {
              setRecommendedProduct('Standard Plus');
            } else {
              setRecommendedProduct('Standard');
            }
          } else {
            if (institution.data.students > 200) {
              setRecommendedProduct('Enterprise');
            } else if (institution.data.students > 100 && institution.data.students <= 200) {
              setRecommendedProduct('Premium');
            } else if (institution.data.students > 50 && institution.data.students <= 100) {
              setRecommendedProduct('Standard Plus');
            } else {
              setRecommendedProduct('Standard');
            }
          }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(institution.message);
      }
    });
    getInvoices(localStorage.getItem('token')).then(invoices => {
        if (invoices.status == true) {
            setInvoices(invoices.data);
        } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(invoices.errors);
        }
    });
    getSubscription(localStorage.getItem('token')).then(subscription => {
        if (subscription.status == true && Object.keys(subscription.data).length !== 0) {
          setSubscriptionData(subscription.data);
        }
    });
  }, []);

  return (
      <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
        {
          (Object.keys(subscriptionData).length === 0 || subscriptionData?.status === 'incomplete' || subscriptionData?.status === 'incomplete_expired')
          && !chosenPrice 
          && (
              <PricesComponent 
                products={products}
                institution={institution}
                invoices={invoices}
                recommendedProduct={recommendedProduct} 
                setChosenPrice={setChosenPrice} 
                setChosenProduct={setChosenProduct}
                setStripeOptions={setStripeOptions}
              />
            )
        }
        {
          (Object.keys(subscriptionData).length === 0 || subscriptionData?.status === 'incomplete' || subscriptionData?.status === 'incomplete_expired')
          && chosenPrice 
          && (
              <Elements stripe={stripePromise} options={stripeOptions}>
                <SubscribeComponent 
                  priceID={chosenPrice} 
                  productID={chosenProduct}
                  products={products}
                  setChosenPrice={setChosenPrice} 
                  setChosenProduct={setChosenProduct} 
                  setSubscriptionActivated={setSubscriptionActivated}
                />
              </Elements>
            )
        }
        {(products.length !== 0 && Object.keys(subscriptionData).length !== 0 && subscriptionData?.status !== 'incomplete' && subscriptionData?.status !== 'incomplete_expired') && (
          <AccountComponent
            subscription={subscriptionData}
            products={products}
            institution={institution}
          />
        )}
        <Modal
          open={openErrorModal}
          onClose={handleCloseErrorModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {openErrorModalText}
            </Typography>
          </Box>
        </Modal>
      </Container>
  );
};

export default Subscription;
