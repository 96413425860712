import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { getAdvertisementReports } from '../../../services/advertisementsService';
import { educatorAccount, checkType } from '../../../services/userService';
import { getReports } from '../../../services/educationActionsService';
import { 
  BarChart, 
  Bar,
  Tooltip,
  XAxis, 
  YAxis, 
  ResponsiveContainer, 
  CartesianGrid, 
  Legend 
} from 'recharts';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

const CustomizedYLabel = ({ text }) => {
  return (
      <text
          x={0}
          y={0}
          dx={-180}
          dy={25}
          transform="rotate(-90)"
      >            
          {text}
      </text>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Reports = () => {
  const [user, setUser] = React.useState({});
  const [reportType, setReportType] = React.useState('month');
  const [reports, setReports] = React.useState({});
  const [advertisementReports, setAdvertiementReports] = React.useState([]);
  const [reportsGraphData, setReportsGraphData] = React.useState({});
  const [advertisementReportsGraphData, setAdvertisementReportsGraphData] = React.useState([]);
  const [advertisementReportClicks, setAdvertisementReportClicks] = React.useState(0);
  const [advertisementReportAmount, setAdvertisementReportAmount] = React.useState(0);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if ((userData.data?.user_level !== 1 && userData.data?.user_level !== 2) || userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
        setUser(userData?.data);
        getReports(localStorage.getItem('token'), reportType).then(report => {
            if (report?.status == true) {
                setReports(report?.data);
                setReportsGraphData(transformData(report?.data));
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(report.message);
            }
        });
        getAdvertisementReports(localStorage.getItem('token'), '*', reportType).then(reports => {
          if (reports.status == true) {
            updateReportData(reports);
          } else {
            setOpenErrorModal(true);
            setOpenErrorModalText(reports.message);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const changeReport = (event, values) => {
      getReports(localStorage.getItem('token'), event.target.value).then(report => {
          if (report?.status == true) {
              setReportType(event.target.value);
              setReports(report?.data);
              setReportsGraphData(transformData(report?.data));
          } else {
              setOpenErrorModal(true);
              setOpenErrorModalText(report.message);
          }
      });
      getAdvertisementReports(localStorage.getItem('token'), '*', event.target.value).then(reports => {
        if (reports.status == true) {
          updateReportData(reports);
        } else {
          setOpenErrorModal(true);
          setOpenErrorModalText(reports.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
  }

  const transformData = (data) => {
      return {
          students: transformStudents(data?.students),
          educators: transformComplexData(data?.educators),
          documents: transformSimpleData(data?.documents),
          messages: transformSimpleData(data?.messages),
          suggestions: transformSimpleData(data?.suggestions),
          tasks: transformSimpleData(data?.tasks),
      }
  }

  const transformSimpleData = (data) => {
      let graphData = [];
      Object.keys(data?.graph_data).forEach(function(key){
          graphData.push({
              name: key,
              top: data?.graph_data[key],
          });
      });
      return graphData;
  }

  const transformStudents = (data) => {
      let graphData = [];
      Object.keys(data?.graph_data_total).forEach(function(key){
          graphData.push({
              name: key,
              active: data?.graph_data_active[key],
              inactive: data?.graph_data_inactive[key],
              pending: data?.graph_data_pending[key]
          });
      });
      return graphData;
  }

  const transformComplexData = (data) => {
      let graphData = [];
      Object.keys(data?.graph_data_total).forEach(function(key){
          graphData.push({
              name: key,
              top: data?.graph_data_active[key],
              bottom: data?.graph_data_inactive[key]
          });
      });
      return graphData;
  }

  const updateReportData = (reports) => {
      setAdvertiementReports(reports?.data);
      let clicks = 0;
      let amount = 0;
      let graphData = [];
      Object.keys(reports?.data).forEach(function(key){
          clicks += reports?.data[key].clicks;
          amount += reports?.data[key].amount;
          graphData.push({
              name: key,
              clicks: reports?.data[key].clicks,
              amount: reports?.data[key].amount
          });
      });
      setAdvertisementReportClicks(clicks);
      setAdvertisementReportAmount(amount);
      setAdvertisementReportsGraphData(graphData);
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const reportTimeText = () => {
      switch (reportType) {
          case 'week':
              return 'Past 7 Days';
          case 'month':
              return 'Past Month';
          case 'year':
              return 'Past Year';
          default:
              return 'Past Month';
      }
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            Reports
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
            <FormControl size='small'>
                <Select
                    labelId="reportType"
                    id="reportType"
                    onChange={changeReport}
                    value={reportType}
                    sx={{ 
                        color: '#777777', 
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { 
                            border: 0 
                        },
                        '&:hover': {
                            backgroundColor: '#f1f1f1',
                            '.MuiSelect-icon': { 
                                visibility: 'visible'
                            }
                        }
                    }}
                >
                    <MenuItem value='week'>
                        <ListItemText primary="Past 7 Days" />
                    </MenuItem>
                    <MenuItem value='month'>
                        <ListItemText primary="Past Month" />
                    </MenuItem>
                    <MenuItem value='year'>
                        <ListItemText primary="Past Year" />
                    </MenuItem>
                </Select>
            </FormControl>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Student Reports" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
            <Tab label="Advertisement Reports" {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <Typography 
            color='secondary' 
            variant='h6' 
            component='div'
            sx={{mb:2}}
          >
            Newly Added Students ({reportTimeText()})
          </Typography>
          <Box sx={{ width:'100%', height: '300px' }}>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  data={reportsGraphData?.students}
                  height={300}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis label={<CustomizedYLabel text='Students' />} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="inactive" stackId="a" name='Inactive' fill='#FF3131' />
                  <Bar dataKey="pending" stackId="a" name='Pending' fill='#777777' />
                  <Bar dataKey="active" stackId="a" name='Active' fill='#fa7918' />
                </BarChart>
              </ResponsiveContainer>
          </Box>
          <Typography 
            color='secondary' 
            variant='h6' 
            component='div'
            sx={{mb:2, mt:2}}
          >
            Newly Added Employees ({reportTimeText()})
          </Typography>
          <Box sx={{ width:'100%', height: '300px' }}>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart
                  data={reportsGraphData?.educators}
                  height={300}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis label={<CustomizedYLabel text='Employees' />} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                  <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                </BarChart>
              </ResponsiveContainer>
          </Box>

          <Grid container alignItems="center" justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography 
                  color='secondary' 
                  variant='h6' 
                  component='div'
                  sx={{mb:2, mt:2}}
                >
                  Messages Sent/Recieved ({reportTimeText()})
                </Typography>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        data={reportsGraphData?.messages}
                        height={300}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Messages' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                        <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                      </BarChart>
                    </ResponsiveContainer>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography 
                  color='secondary' 
                  variant='h6' 
                  component='div'
                  sx={{mb:2, mt:2}}
                >
                  Tasks Created ({reportTimeText()})
                </Typography>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        data={reportsGraphData?.tasks}
                        height={300}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Tasks' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                        <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                      </BarChart>
                    </ResponsiveContainer>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography 
                  color='secondary' 
                  variant='h6' 
                  component='div'
                  sx={{mb:2, mt:2}}
                >
                  Suggestions Created ({reportTimeText()})
                </Typography>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        data={reportsGraphData?.suggestions}
                        height={300}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Suggestions' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                        <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                      </BarChart>
                    </ResponsiveContainer>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography 
                  color='secondary' 
                  variant='h6' 
                  component='div'
                  sx={{mb:2, mt:2}}
                >
                  Documents Sent ({reportTimeText()})
                </Typography>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                      <BarChart
                        data={reportsGraphData?.documents}
                        height={300}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Documents' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                        <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                      </BarChart>
                    </ResponsiveContainer>
                </Box>
              </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Typography 
            color='secondary' 
            variant='h6' 
            component='div'
            sx={{mb:2}}
          >
            Advertisement Reports ({reportTimeText()})
          </Typography>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6} sx={{textAlign:'center'}}>
                <Typography color='secondary' variant='h5' component='div'>
                    Total Amount Spent
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                    ${advertisementReportAmount}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{textAlign:'center'}}>
                <Typography color='secondary' variant='h5' component='div'>
                    Total Number of Clicks
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                    {advertisementReportClicks}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                        data={advertisementReportsGraphData}
                        height={300}
                        margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Amount' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="clicks" stackId="a" name='Number of Clicks' fill='#fa7918' />
                        <Bar dataKey="amount" stackId="b" name='Amount Spent' fill='#777777' />
                    </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Paper>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Reports;
