import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import './footer.scss';

const Footer = () => {

  const flexContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  };

  const TikTokIcon = ({ color = "#FFFFFF" }) => {
    return (
      <svg
        fill={color}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        width="50%"
        height="50%"
      >
        <path d="M16.708 0.027c1.745-0.027 3.48-0.011 5.213-0.027 0.105 2.041 0.839 4.12 2.333 5.563 1.491 1.479 3.6 2.156 5.652 2.385v5.369c-1.923-0.063-3.855-0.463-5.6-1.291-0.76-0.344-1.468-0.787-2.161-1.24-0.009 3.896 0.016 7.787-0.025 11.667-0.104 1.864-0.719 3.719-1.803 5.255-1.744 2.557-4.771 4.224-7.88 4.276-1.907 0.109-3.812-0.411-5.437-1.369-2.693-1.588-4.588-4.495-4.864-7.615-0.032-0.667-0.043-1.333-0.016-1.984 0.24-2.537 1.495-4.964 3.443-6.615 2.208-1.923 5.301-2.839 8.197-2.297 0.027 1.975-0.052 3.948-0.052 5.923-1.323-0.428-2.869-0.308-4.025 0.495-0.844 0.547-1.485 1.385-1.819 2.333-0.276 0.676-0.197 1.427-0.181 2.145 0.317 2.188 2.421 4.027 4.667 3.828 1.489-0.016 2.916-0.88 3.692-2.145 0.251-0.443 0.532-0.896 0.547-1.417 0.131-2.385 0.079-4.76 0.095-7.145 0.011-5.375-0.016-10.735 0.025-16.093z"></path>
      </svg>
    );
  };

  const FacebookIcon = ({ color = "#FFFFFF" }) => {
    return (
      <svg
        fill={color}
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="60%"
        height="60%"
      >
        <path d="M12 2C6.5 2 2 6.5 2 12c0 5 3.7 9.1 8.4 9.9v-7H7.9V12h2.5V9.8c0-2.5 1.5-3.9 3.8-3.9 1.1 0 2.2.2 2.2.2v2.5h-1.3c-1.2 0-1.6.8-1.6 1.6V12h2.8l-.4 2.9h-2.3v7C18.3 21.1 22 17 22 12c0-5.5-4.5-10-10-10z"></path>
      </svg>
    );
  };

  return (
    <Box component="div" className="footer">
      <Box component="div" className="footer-links">
        <Grid container spacing={0} className="footer-links-inner">
          <Grid item md={4} sm={12}>
            <h3>Company Information</h3>
            <Box component="div" sx={{mt:1, mb:1}}>
              <Link href="https://educationadvisor.org/about-us/" sx={{ display: 'block' }} color="inherit" underline="hover">About Us</Link>
              <Link href="https://educationadvisor.org/contact-us/" sx={{ display: 'block' }} color="inherit" underline="hover">Contact Us</Link>
              <Link href="https://educationadvisor.org/about-us/#investors" sx={{ display: 'block' }} color="inherit" underline="hover">Investors</Link>
              <Link href="https://educationadvisor.org/company/news/" sx={{ display: 'block' }} color="inherit" underline="hover">News</Link>
              <Link href="https://educationadvisor.org/about-us/our-partners/" sx={{ display: 'block' }} color="inherit" underline="hover">Our Partners</Link>
            </Box>
          </Grid>
          <Grid item md={4} sm={12}>
            <h3>General Information</h3>
            <Box component="p">
              <Link href="https://educationadvisor.org/how-it-works/" sx={{ display: 'block' }} color="inherit" underline="hover">How It Works</Link>
              <Link href="https://educationadvisor.org/company/guides/" sx={{ display: 'block' }} color="inherit" underline="hover">Guides</Link>
              <Link href="https://educationadvisor.org/report-a-bug/" sx={{ display: 'block' }} color="inherit" underline="hover">Report A Bug</Link>
              <Link href="https://educationadvisor.org/frequently-asked-questions/" sx={{ display: 'block' }} color="inherit" underline="hover">Frequently Asked Questions</Link>
              <Link href="https://educationadvisor.org/advertising-with-us/" sx={{ display: 'block' }} color="inherit" underline="hover">Advertising With Us</Link>
            </Box>
          </Grid>
          <Grid item md={4} sm={12}>
            <h3>Connect With Us</h3>
            <Box component="div" sx={{mt:1, mb:2}}>
              <List style={flexContainer}>
                <ListItem sx={{width:'auto', m:0, p:0}}>
                  <ListItemButton component={Link} target="_blank" to="https://www.facebook.com/educationadvisorhq/" sx={{m:0, p:0, "&:hover": { backgroundColor: "transparent" } }}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: '#fa7918', transition: "transform 0.15s ease-in-out", "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}>
                        <FacebookIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{width:'auto', m:0, p:0}}>
                  <ListItemButton component={Link} target="_blank" to="https://twitter.com/edadvisorhq" sx={{m:0, p:0, "&:hover": { backgroundColor: "transparent" }}}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: '#fa7918', transition: "transform 0.15s ease-in-out", "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}>
                        <TwitterIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{width:'auto', m:0, p:0}}>
                  <ListItemButton component={Link} target="_blank" to="https://www.instagram.com/educationadvisorhq/" sx={{m:0, p:0, "&:hover": { backgroundColor: "transparent" }}}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: '#fa7918', transition: "transform 0.15s ease-in-out", "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}>
                        <InstagramIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{width:'auto', m:0, p:0}}>
                  <ListItemButton component={Link} target="_blank" to="https://www.tiktok.com/@educationadvisorhq" sx={{m:0, p:0, "&:hover": { backgroundColor: "transparent" }}}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: '#fa7918', transition: "transform 0.15s ease-in-out", "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}>
                        <TikTokIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{width:'auto', m:0, p:0}}>
                  <ListItemButton component={Link} target="_blank" to="https://www.linkedin.com/company/educationadvisorhq" sx={{m:0, p:0, "&:hover": { backgroundColor: "transparent" }}}>
                    <ListItemAvatar>
                      <Avatar sx={{backgroundColor: '#fa7918', transition: "transform 0.15s ease-in-out", "&:hover": { transform: "scale3d(1.05, 1.05, 1)" }}}>
                        <LinkedInIcon />
                      </Avatar>
                    </ListItemAvatar>
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
            <Box component="div">
              <form action="https://educationadvisor.org">
                <TextField
                  id="search-bar"
                  className="text"
                  placeholder="Search"
                  size="small"
                  name="s"
                  sx={{borderRadius: '4px 0px 0px 4px', backgroundColor:'#FFF', "& fieldset": { border: 'none' }}}
                />
                <IconButton type="submit" aria-label="search" sx={{backgroundColor: '#fa7918', "&:hover": { backgroundColor: "#fa7918" }, borderRadius: '0px 4px 4px 0px'}}>
                  <SearchIcon style={{ fill: "white" }} />
                </IconButton>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box component="div" className="copyright">
        <Box
          component="img"
          sx={{mt:1, mb:2}}
          alt="Education Advisor Footer Logo"
          src="https://educationadvisor.org/wp-content/uploads/2022/08/logo-wide-transparent-white-1024x182.png"
        />
        <Box component="div">
          <Link href="https://educationadvisor.org/terms-of-use/" color="inherit" underline="hover">Terms of Use</Link>&nbsp;-&nbsp;
          <Link href="https://educationadvisor.org/privacy-policy/" color="inherit" underline="hover">Privacy Policy</Link>&nbsp;-&nbsp;
          <Link href="https://educationadvisor.org/report-a-bug/" color="inherit" underline="hover">Report A Bug</Link>
        </Box>
        <Box component="div" sx={{mt:2, mb:1}}>&copy; {(new Date().getFullYear())} Education Technologies LLC. All Rights Reserved.</Box>
      </Box>
    </Box>
  );
};

export default Footer;
