import * as React from 'react';
import Typography from '@mui/material/Typography';

export const careerPickerDisclaimer = (
  <React.Fragment>
    <Typography component='div' sx={{ textAlign: 'center', fontSize: 10, mt: 2 }}>
        <strong>DISCLAIMER:</strong>
        The career recommendations provided by this software are based on algorithms and data analysis, along with the information provided by the user. While every effort has been made to ensure accuracy and relevance, it's important to understand that the suggestions generated by this software are not exhaustive and may not guarantee a perfect fit for every individual.

        Users should consider the recommendations as one of many resources in their career exploration journey. Factors such as personal preferences, skills, values, and external circumstances should also be taken into account when making important career decisions.

        The developers and creators of this software do not take responsibility for any decisions made solely based on the recommendations provided by the program. Users are encouraged to conduct further research, seek advice from career professionals, and evaluate multiple options before committing to a career path.

        By using this software, you acknowledge that the career suggestions are provided for informational purposes only and should not be considered as professional career counseling or advice.
    </Typography>
  </React.Fragment>
);