import React, { PureComponent } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { getStudentOccupationByEmployer } from '../../../../../services/employmentService';
import { getStudentInstitutionByEmployer } from '../../../../../services/educationService';
import { getStudentCalculationsByEmployer } from '../../../../../services/userService';

class CustomizedLabel extends PureComponent {
    render() {
        const { x, y, stroke, value } = this.props;
    
        return (
            <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor='middle'>
            {value}
            </text>
        );
    }
}

export function OverviewComponent({student, selectedEducator, setOpenOverview, setError, setErrorText}) {
    const [dashboardData, setDashboardData] = React.useState([]);
    const [educationData, setEducationData] = React.useState([]);
    const [occupationData, setOccupationData] = React.useState([]);
    const [loanAmount, setLoanAmount] = React.useState(0);

    React.useEffect(() => {
        if (selectedEducator?.id !== undefined) {
            getStudentCalculationsByEmployer(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(calculations => {
                if (calculations.status == true) {
                    setDashboardData(calculations.data);
                    setLoanAmount(Number(calculations.data.payoff_breakdown[0]?.la));
                } else {
                    setError(true);
                    setErrorText(calculations.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
            getStudentInstitutionByEmployer(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(institution => {
                if (institution.status == true) {
                    setEducationData(institution.data);
                } else {
                    setError(true);
                    setErrorText(institution.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
            getStudentOccupationByEmployer(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(occupation => {
                if (occupation.status == true) {
                    setOccupationData(occupation.data);
                } else {
                    setError(true);
                    setErrorText(occupation.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    }, [setOpenOverview, selectedEducator]);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Overview
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

            <Typography component='div' fontWeight='bold' color='secondary' textAlign='center'>
              STUDENTS INVESTMENT RATING
            </Typography>
            <Typography color='primary' variant='h1' component='div'  sx={{ mt: 0, mb: 2 }} fontSize='120px' textAlign='center'>
              {dashboardData.rating}
            </Typography>

            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

            <Grid container sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 355,
            }}>
                <ResponsiveContainer width='100%' height='100%'>
                <LineChart
                    data={dashboardData.payoff_breakdown}
                    margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='year' />
                    <YAxis domain={[0,Math.round(loanAmount/10000)*10000]} />
                    <Legend />
                    <Line type='monotone' dataKey='la' name='Loan Principal Amount' stroke='#fa7918' label={<CustomizedLabel />} />
                    <Line type='monotone' dataKey='pi' name='Projected Income' stroke='#777777' label={<CustomizedLabel />} />
                </LineChart>
                </ResponsiveContainer>
            </Grid>

            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

            <Typography component='div' variant='h6' textAlign='center' color='secondary'>
              Occupation Information
            </Typography>
            <Grid sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                        <TableCell>
                            <Typography fontWeight='bold'>Occupation Title</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.occupation_title}</Typography>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                            <Typography fontWeight='bold'>Current Estimated Number of Jobs</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_current?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                            <Typography fontWeight='bold'>Projected Number of Jobs (10 Years)</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_projected?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                            <Typography fontWeight='bold'>Projected Number of Openings</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography fontWeight='bold' color='primary'>{occupationData.occupation?.employment_change?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell>
                            <Typography fontWeight='bold'>Projected Annual Wage</Typography>
                        </TableCell>
                        <TableCell align='right'>
                            <Typography fontWeight='bold' color='primary'>${occupationData.occupation?.median_annual_wage?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Typography>
                        </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>

            <Typography component='div' variant='h6' textAlign='center' color='secondary'>
              Education Information
            </Typography>
            <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
              <Table size='small'>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight='bold'>School Name</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography fontWeight='bold' color='primary'>{educationData.college_name}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight='bold'>Yearly Tuition</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography fontWeight='bold' color='primary'>
                        {educationData.average_cost_of_attendance ? (
                          <>
                            ${educationData.average_cost_of_attendance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        ) : (
                          <>Not Found</>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight='bold'>Total Students Admitted</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography fontWeight='bold' color='primary'>
                        {educationData.total_admitted ? (
                          <>
                            {educationData.total_admitted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        ) : (
                          <>
                            Not Found
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight='bold'>Total Students Enrolled</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography fontWeight='bold' color='primary'>
                        {educationData.total_enrolled ? (
                          <>
                            {educationData.total_enrolled?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        ) : (
                          <>
                            Not Found
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight='bold'>Avg Earnings After Graduation</Typography>
                    </TableCell>
                    <TableCell align='right'>
                      <Typography fontWeight='bold' color='primary'>
                        {educationData.median_earnings_six_years_after_entry ? (
                          <>
                            ${educationData.median_earnings_six_years_after_entry?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        ) : (
                          <>
                            Not Found
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
        </>
    )
}