import React, { useState } from 'react';
import Container from '@mui/material/Container';
import { useParams } from "react-router-dom";
import { activate } from '../../../services/userService';
import './activation.scss';

const Activation = () => {
  const { token } = useParams();
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    activate(token).then(response => {
      setMessage(response.message);
    });
  }, []);

  return (
    <Container component="div" className="wrapper wrapper-small activation">
      <h1>{message}</h1>
    </Container>
  );
}

export default Activation;
