import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { getEmployeeTasksByStudent, getUserTasksByReferrer, generateTasks, uploadSuggestions } from '../../../../../services/tasksService';
import { TasksList } from '../../../../../components/global/tasks/tasksList';
import { TasksView } from '../../../../../components/global/tasks/tasksView';

export function TasksComponent({user, student, selectedEducator, setOpenTasks, setError, setErrorText}) {
    const [tasks, setTasks] = React.useState([]);
    const [suggestions, setSuggestions] = React.useState(null);
    const [selectedTask, setSelectedTask] = React.useState({});
    const [type, setType] = React.useState('employee');
    const [reloadList, setReloadList] = React.useState(false);
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
    const handleCloseDrawer = () => setOpenTaskDrawer(false);
    const [openSuggestionDrawer, setOpenSuggestionDrawer] = React.useState(false);
    const handleCloseSuggestionDrawer = () => setOpenSuggestionDrawer(false);
    const [checked, setChecked] = React.useState([]);
  
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        if (newChecked.length > 0) {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
    };

    const drawerStyle = {
        width: '60%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }
  
    const handleOpenTaskDrawer = (task) => {
        setSelectedTask(task);
        setOpenTaskDrawer(true);
    };
  
    const handleOpenSuggestionDrawer = (task) => {
        setOpenSuggestionDrawer(true);
        if (suggestions === null) {
            generateTasks(localStorage.getItem('token'), student?.id).then(suggestions => {
                if (suggestions.status == true) {
                    setSuggestions(suggestions.data);
                } else {
                    setError(true);
                    setErrorText(suggestions.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    };

    React.useEffect(() => {
        if (selectedEducator !== undefined) {
            if (user?.id === selectedEducator?.id) {
                setType('self_educator');
                getUserTasksByReferrer(localStorage.getItem('token'), student?.id).then(tasks => {
                    if (tasks.status == true) {
                        setTasks(tasks.data);
                    } else {
                        setError(true);
                        setErrorText(tasks.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            } else {
                setType('educator');
                getEmployeeTasksByStudent(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(tasks => {
                    if (tasks.status == true) {
                        setTasks(tasks.data);
                    } else {
                        setError(true);
                        setErrorText(tasks.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            }
        }
        setReloadList(false);
    }, [selectedEducator, setOpenTasks, reloadList]);

    const handleSubmit = () => {
        let selectedSuggestions = [];

        for (let i = 0; i < suggestions.length; i++) {
            for (let int = 0; int < checked.length; int++) {
                if (i === int) {
                    selectedSuggestions.push(suggestions[i]);
                }
            }
        }
        let data = new URLSearchParams({
            'tasks': JSON.stringify(selectedSuggestions),
            'attached_user': student?.id
        });

        uploadSuggestions(localStorage.getItem('token'), data).then(suggestions => {
            if (suggestions.status == true) {
                getUserTasksByReferrer(localStorage.getItem('token'), student?.id).then(tasks => {
                    if (tasks.status == true) {
                        setTasks(tasks.data);
                    } else {
                        setError(true);
                        setErrorText(tasks.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            } else {
                setError(true);
                setErrorText(suggestions.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
        handleCloseSuggestionDrawer();
        setSuggestions(null);
        setChecked([]);
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Tasks
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} align='right'>
                    {type === 'self_educator' && (
                        <>
                            <Tooltip title='Use our artificial intellegince "ROI the owl" to automatically suggest tasks for this student based on their prospective school and occupation. ROI is in beta so be patient with him!' arrow>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='small'
                                    sx={{ mr: 1 }}
                                    onClick={() => handleOpenSuggestionDrawer(true)}
                                    disabled={selectedEducator?.active ? false : true}
                                >
                                    Generate Tasks
                                </Button>
                            </Tooltip>
                            <Button
                                variant='contained'
                                disableElevation
                                size='small'
                                sx={{ mr: 1 }}
                                onClick={() => handleOpenTaskDrawer({})}
                                disabled={selectedEducator?.active ? false : true}
                            >
                                Add Task
                            </Button>
                        </>
                    )}
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <TasksList
                type={type}
                tasks={tasks}
                setSelectedTask={setSelectedTask}
                attachedUser={selectedEducator}
                setReloadList={setReloadList}
                setOpenTaskDrawer={setOpenTaskDrawer}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Drawer
                open={openTaskDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <TasksView 
                    type={type}
                    selectedTask={selectedTask}
                    attachedUser={selectedEducator}
                    attachedRecipient={student}
                    educators={[]}
                    handleCloseDrawer={handleCloseDrawer}
                    setReloadList={setReloadList}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
            <Drawer
                open={openSuggestionDrawer}
                onClose={handleCloseSuggestionDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseSuggestionDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                    <Grid item xs={12}>
                        <Typography color='secondary' variant='h5' component='div'>
                            Suggestions From ROI
                        </Typography>
                        <Divider variant="fullWidth" sx={{ mt: 2 }} />
                    </Grid>
                    <Grid item xs={12}>
                        {suggestions !== null ? (
                            <>
                                <List>
                                    {suggestions.map((suggestion, index) => (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                        >
                                            <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                                                <ListItemIcon>
                                                    <Checkbox
                                                    edge="start"
                                                    checked={checked.indexOf(index) !== -1}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': 'suggestion-' + index }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={'suggestion-' + index} primary={suggestion.task} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                                <Button
                                    variant='contained'
                                    disableElevation
                                    size='large'
                                    sx={{width:'100%', mt: 2}}
                                    disabled={formCompleted ? false : true}
                                    onClick={handleSubmit}
                                >
                                    Assign Selected Tasks To Student
                                </Button>
                            </>
                        ) : (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    textAlign: 'center',
                                    left: '0',
                                    right: '0',
                                    pl: 2,
                                    pr: 2
                                }}
                            >
                                <CircularProgress color="secondary" />
                                <Typography color='secondary' variant='h5' component='div' sx={{mt:2}}>
                                    Suggestions are loading. Please be patient with our artificial intellegince. It may take a couple minutes.
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Drawer>
        </>
    )
}