import React, { useState } from 'react';
import Chip from '@mui/material/Chip';

export function EducatorsComponent({loggedInUser, educators, selectedEducator, setSelectedEducator, setOpenUpdateModal}) {

    return (
        <>
            {educators?.map((educator, index) => (
                <Chip 
                    key={index}
                    label={educator.first_name + ' ' + educator.last_name + (loggedInUser.id === educator.id ? ' (You)' : '')} 
                    variant={selectedEducator.id === educator.id ? 'filled' : 'outlined'} 
                    onClick={() => setSelectedEducator(educator)}
                    color={selectedEducator.id === educator.id ? 'primary' : 'default'}
                    sx={{
                        mr: 1,
                        mb: 1,
                        fontSize: '16px',
                        border: selectedEducator.id === educator.id ? '1px solid #fa7918' : '1px solid #bdbdbd',
                        backgroundColor: selectedEducator.id === educator.id ? '#ffaa7799' : '#fff',
                        '&:hover': {
                            color: selectedEducator.id === educator.id ? '#fff' : 'rgba(0, 0, 0, 0.87)'
                        }
                    }}
                />
            ))}
            <Chip 
                label='+ Add An Educator'
                variant='outlined'
                onClick={() => setOpenUpdateModal(true)}
                sx={{
                    fontSize: '16px',
                    mb: 1,
                }}
            />
        </>
    )
}