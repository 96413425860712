const apiUrl = 'https://api.educationadvisor.org/api/v1';

const storageUrl = 'https://api.educationadvisor.org/storage/';

const appUrl = 'https://app.educationadvisor.org';

const marketingUrl = 'https://educationadvisor.org';

const educatorPaymentsUrl = 'https://app.educationadvisor.org/educator/billing/subscription';

const studentPaymentsUrl = 'https://app.educationadvisor.org/students/billing/subscription';

const stripePublicKey = 'pk_live_51LtxHjDz3y1vC6HIp8tRXfzvrYLSddF24zW48RujaWZs3mOXySEVCD6n8YSEXcXGBQWwKAJgeJ1nnIdvVeEgJRIx00Kv9HAW07';

const gaTrackingId =  "G-X62QCR2RM7";

const hubspotId = "45138085";

const dashboardMarketingBlockUrl = "https://educationadvisor.org/benefits-of-your-educator-or-school-using-education-advisor/";

export {
  apiUrl,
  storageUrl,
  appUrl,
  marketingUrl,
  educatorPaymentsUrl,
  studentPaymentsUrl,
  stripePublicKey,
  gaTrackingId,
  hubspotId,
  dashboardMarketingBlockUrl
}