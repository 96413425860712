import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { updateStudent } from '../../../../services/educationActionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

export function StudentEdit({student, educator, employees, handleCloseUpdateModal}) {
    const [errorText, setErrorText] = React.useState('');
    const [checkedEmployees, setCheckedEmployees] = React.useState([]);
    const [isActive, setIsActive] = React.useState(student?.active_institution === 1 ? true : false);
    const [deactivateEmployees, setDeactivateEmployees] = React.useState(false);

    React.useEffect(() => {
        let employeesToCheck = [];
        
        student.educators.forEach(educator => {
            if (educator?.active === 1) {
                employeesToCheck.push(educator.id);
            }
        });
        setCheckedEmployees(
            employeesToCheck
        );
    }, []);

    const handleEmployeeChange = (event) => {
      const {
        target: { value },
      } = event;
      
      setCheckedEmployees(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleSelectRender = (selected) => {
        let selectedNames = [];
        selected.forEach(id => {
            employees?.forEach(employee => {
                if (id === employee.id) {
                    selectedNames.push(employee.first_name + ' ' + employee.last_name);
                }
            });
        });
        return selectedNames.join(', ');
    };

    const getEmployeeName = (employee) => {
        let ending = '';
        let inactive = '';
        if (employee.id === educator.id) {
            ending = ' (You)';
        }
        student.educators.forEach(studentEducator => {
            if (employee.id === studentEducator.id) {
                if (studentEducator.active === 0) {
                    if (studentEducator.deactivated_by !== employee.id) {
                        inactive = ' (Removed By Student)';
                    } else {
                        inactive = ' (Assigned But Inactive)';
                    }
                }
            }
        });
        return employee.first_name + ' ' + employee.last_name + ending + inactive;
    }

    const getEmployeeDisabled = (employee) => {
        if (employee.account_locked || !employee.active) {
            return true;
        }
        student.educators.forEach(studentEducator => {
            if (employee.id === studentEducator.id) {
                if (studentEducator.active === 0) {
                    if (studentEducator.deactivated_by !== employee.id) {
                        return true;
                    }
                }
                return false;
            }
        });
    }

    const onActiveChange = (event, values) => {
        setDeactivateEmployees(false);
        if (!event.target.checked) {
            setDeactivateEmployees(true);
        }
        setIsActive(event.target.checked);
    }

    const handleUpdateStudents = (event) => {
        const controlledEmployees = '[' + [...new Set(checkedEmployees)].toString() + ']';
        let data = new URLSearchParams({
            'active':isActive,
            'educators':controlledEmployees
        });

        updateStudent(localStorage.getItem('token'), student?.id, data).then(update => {
            if (update?.status == true) {
                setCheckedEmployees([]);
                handleCloseUpdateModal();
                setErrorText();
                window.location.reload();
            } else {
                setErrorText(update.message);
            }
        });
    };

    return (
        <Box sx={style}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                        {student?.first_name} {student?.last_name}
                    </Typography>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                </Grid>
                {employees?.length > 0 && (educator?.user_level === 1 || educator?.user_level === 2) && (
                    <Grid item xs={12}>
                        <FormControl sx={{ width:'100%', textAlign: 'left' }}>
                            <InputLabel id="employees-label">Assigned and Activated Employees</InputLabel>
                            <Select
                                labelId="employees-label"
                                id="employees-checkbox"
                                multiple
                                required
                                fullWidth
                                value={checkedEmployees}
                                onChange={handleEmployeeChange}
                                input={<OutlinedInput label="Assigned and Activated Employees" />}
                                renderValue={(selected) => handleSelectRender(selected)}
                                disabled={deactivateEmployees}
                            >
                                {employees?.map((employee, index) => (
                                    <MenuItem key={employee.id} value={employee.id} disabled={getEmployeeDisabled(employee)}>
                                        <Checkbox checked={checkedEmployees.indexOf(employee.id) > -1} />
                                        <ListItemText primary={getEmployeeName(employee)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                    control={<Checkbox
                        value="allowPublic"
                        color="primary"
                        name="public"
                        checked={isActive ? true : false}
                        onChange={onActiveChange}
                    />}
                    label="Is student active within your intstitution?"
                    />
                    <Typography sx={{fontSize:'12px'}}>
                        WARNING: Turning this off will disable all employees from being able to interact with this student. You can reactivate the student at any time.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={handleUpdateStudents}
                        disabled={educator?.user_level === 3 ? true : false}
                    >
                        Update Student
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}