import React, { useState } from 'react';
import Container from '@mui/material/Container';
import './noPage.scss';

const NoPage = () => {
  return (
    <Container component="div" className="wrapper wrapper-small not-found">
      <h1>404 Page Not Found</h1>
    </Container>
  );
};

export default NoPage;
