import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { SuggestionsList } from '../../../../../components/global/suggestions/suggestionsList';
import { SuggestionsView } from '../../../../../components/global/suggestions/suggestionsView';
import { getUserSuggestionsByReferrer, getEmployeeSuggestionsByStudent, getSystemSuggestions } from '../../../../../services/suggestionsService';

export function SuggestionsComponent({user, student, selectedEducator, setOpenSuggestions, setError, setErrorText}) {
    const [suggestions, setSuggestions] = React.useState([]);
    const [systemSuggestions, setSystemSuggestions] = React.useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState({});
    const [specificType, setSpecificType] = React.useState(false);
    const [type, setType] = React.useState('employee');
    const [reloadList, setReloadList] = React.useState(false);
    const [openSuggestionsDrawer, setOpenSuggestionsDrawer] = React.useState(false);

    const handleCloseDrawer = () => {
        setOpenSuggestionsDrawer(false);
        setSelectedSuggestion({});
        setSpecificType(false);
    }
  
    const handleOpenSuggestionsDrawer = () => {
        setOpenSuggestionsDrawer(true);
    };

    const drawerStyle = {
        width: '80%',
        '@media (max-width: 800px)': {
            width: '100%'
        }
    }

    React.useEffect(() => {
        if (selectedEducator !== undefined) {
            getSystemSuggestions(localStorage.getItem('token'), student?.id).then(suggestions => {
                if (suggestions.status == true) {
                    setSystemSuggestions(suggestions.data);
                }
            });
            if (user?.id === selectedEducator?.id) {
                setType('self_educator');
                getUserSuggestionsByReferrer(localStorage.getItem('token'), student?.id).then(suggestions => {
                    if (suggestions.status == true) {
                        setSuggestions(suggestions.data);
                    } else {
                        setError(true);
                        setErrorText(suggestions.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            } else {
                setType('educator');
                getEmployeeSuggestionsByStudent(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(suggestions => {
                    if (suggestions.status == true) {
                        setSuggestions(suggestions.data);
                    } else {
                        setError(true);
                        setErrorText(suggestions.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            }
        }
        setReloadList(false);
    }, [selectedEducator, setOpenSuggestions, reloadList]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                <Typography color='secondary' variant='h5' component='div'>
                    <Tooltip title="Suggestions are based off this students currently selected occupation and school." placement="bottom" arrow>
                        Suggestions <InfoIcon color="secondary" sx={{mb:'-5px'}} />
                    </Tooltip>
                </Typography>
                </Grid>
                <Grid item xs={12} sm={6} align='right'>
                    {type === 'self_educator' && (
                        <Button
                            variant='contained'
                            disableElevation
                            size='small'
                            sx={{ mr: 1 }}
                            onClick={() => handleOpenSuggestionsDrawer({})}
                            disabled={selectedEducator?.active ? false : true}
                        >
                            Add Suggestion
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <SuggestionsList 
                type={type}
                suggestions={suggestions}
                setSelectedSuggestion={setSelectedSuggestion}
                systemSuggestions={systemSuggestions}
                attachedUser={selectedEducator}
                setReloadList={setReloadList}
                setOpenSuggestionsDrawer={setOpenSuggestionsDrawer}
                setSpecificType={setSpecificType}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Drawer
                open={openSuggestionsDrawer}
                onClose={handleCloseDrawer}
                anchor='right'
                sx={{zIndex: 9999}}
                PaperProps={{
                    sx: drawerStyle,
                }}
            >
                <DialogTitle 
                    disableTypography
                    sx={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        padding: '0 !important'
                    }}
                >
                    <IconButton onClick={handleCloseDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <SuggestionsView
                    type={type}
                    selectedSuggestion={selectedSuggestion}
                    specificType={specificType}
                    student={student}
                    setReloadList={setReloadList}
                    handleCloseDrawer={handleCloseDrawer}
                    setError={setError}
                    setErrorText={setErrorText}
                />
            </Drawer>
        </>
    )
}