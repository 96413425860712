import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import Modal from '@mui/material/Modal';
import { storageUrl } from '../../../../constants/globals';
import { Chip, Divider, Tooltip, Typography } from '@mui/material';
import { EmployeeEdit } from './employeeEdit';

export function EmployeePreview({employee, educator, setViewEmployee, key}) {
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleCloseEditModal = () => setOpenEditModal(false);

    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Paper sx={{p:1, position: 'relative'}}>
                    {employee?.is_primary === 1 && (
                        <Tooltip 
                            title="This is the primary user on the account. Payments are hooked up to this user."
                            arrow
                        >
                            <StarIcon 
                                color='primary'
                                sx={{
                                    position:'absolute',
                                    top:8,
                                    right:8
                                }}
                            />
                        </Tooltip>
                    )}
                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} md={4}>

                            <div
                                style={{
                                    height: "70px",
                                    width: "70px",
                                    border: "1px solid #777",
                                    borderRadius: "200px",
                                    overflow: "hidden",
                                    cursor: "pointer",
                                    position: "relative",
                                    display:'block',
                                    margin: 'auto'
                                }}
                            >
                                {!employee?.profile_image && (
                                <>
                                    <PersonIcon
                                        fontSize="large"
                                        style={{
                                            position: "absolute",
                                            top: "34%",
                                            left: "26%",
                                            transform: "translateX(-50%) translateY(-50%)",
                                            zIndex: 0,
                                            color: "#999",
                                            transform: "scale(2.5)"
                                        }}
                                    />
                                </>
                                )}
                                {employee?.profile_image && (
                                <>
                                    <img
                                    src={storageUrl + employee?.profile_image}
                                    style={{
                                        height: "100%",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 1
                                    }}
                                    />
                                </>
                                )}
                            </div>

                        </Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography color='secondary' variant='h6'>{employee?.first_name} {employee?.last_name} {employee?.id === educator?.id && ('(You)')}</Typography>
                            <Chip size='small' color={employee?.active === 1 ? 'success' : 'secondary'} label={employee?.active === 1 ? 'Active' : 'Inactive'} />&nbsp;
                            {employee?.user_level === 1 && (
                                <Chip size='small' label='Owner' />
                            )}  
                            {employee?.user_level === 2 && (
                                <Chip size='small' label='Manager' />
                            )} 
                            {employee?.user_level === 3 && (
                                <Chip size='small' label='Employee' />
                            )}     
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item sm={12} md={6}>
                            <Button
                                variant='contained'
                                disableElevation
                                size='large'
                                sx={{width:'100%'}}
                                disabled={educator?.active ? false : true}
                                onClick={() => { setViewEmployee(employee) }}
                            >
                                View
                            </Button>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Tooltip 
                                title={(educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 1 && educator?.is_primary === 0 && employee?.is_primary === 1) ? 'You do not have permission to edit this user.' : ""}
                                arrow
                            >
                                <span>
                                    <Button
                                        variant='outlined'
                                        disableElevation
                                        size='large'
                                        sx={{width:'100%'}}
                                        disabled={(educator?.active === 0) || (educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 1 && educator?.is_primary === 0 && employee?.is_primary === 1) || (employee?.id === educator?.id) ? true : false}
                                        onClick={setOpenEditModal}
                                    >
                                        Edit
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EmployeeEdit
                    employee={employee}
                    currentEducator={educator}
                />
            </Modal>
        </>
    )
}