import React, { useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { storageUrl } from '../../../constants/globals';
import { getAdvertisementCategories, addInstitutionAdvertisement, addInstitutionAdvertisementImage, updateInstitutionAdvertisement } from '../../../services/advertisementsService';
import { AdvertisementAddCategories } from './components/advertisementAddCategories';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  minWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export function AdvertisementAdd({institution, advertisement, setAdvertisement, setError, setErrorText, type, setReloadList, handleCloseAddAdvertisementDrawer, setOpenSuccess}) {
    const [advertisementCheck, setAdvertisementCheck] = React.useState(advertisement);
    const [headline, setHeadline] = React.useState(advertisement?.headline ? advertisement?.headline : '');
    const [description, setDescription] = React.useState(advertisement?.description ? advertisement?.description : '');
    const [link, setLink] = React.useState(advertisement?.link ? advertisement?.link : '');
    const [phone, setPhone] = React.useState(advertisement?.phone ? advertisement?.phone : '');
    const [amount, setAmount] = React.useState(advertisement?.amount ? advertisement?.amount : '');
    const [pricePerClick, setPricePerClick] = React.useState(advertisement?.price_per_click ? advertisement?.price_per_click : 0);
    const [active, setActive] = React.useState(advertisement?.active ? advertisement?.active : 0);
    const [categories, setCategories] = React.useState(advertisement?.categories ? advertisement?.categories : []);
    const [advertisementImage, setAdvertisementImage] = React.useState(advertisement?.image ? storageUrl + advertisement?.image : null);
    const [advertisementImageToUpload, setAdvertisementImageToUpload] = React.useState(null);
    const [openAddCategoryModal, setOpenAddCategoryModal] = React.useState(false);
    const [categoryRegions, setCategoryRegions] = React.useState([]);
    const [categoryOccupations, setCategoryOccupations] = React.useState([]);
    const [categoryInstitutions, setCategoryInstitutions] = React.useState([]);
    const handleOpenAddCategoryModal = () => setOpenAddCategoryModal(true);
    const handleCloseAddCategoryModal = () => setOpenAddCategoryModal(false);

    React.useEffect(() => {
        if (advertisement !== null) {
            let clone = structuredClone(advertisement);
            
            clone.headline = headline;
            clone.description = description;
            clone.link = link;
            clone.phone = phone;
            clone.amount = amount;

            setAdvertisement(clone);
        }
    }, [headline, description, link, phone, amount]);

    React.useEffect(() => {
        getAdvertisementCategories(localStorage.getItem('token'), 'region').then(locationData => {
            if (locationData.status == true) {
                setCategoryRegions(locationData.data);
            } else {
                setError(true);
                setErrorText(locationData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
        getAdvertisementCategories(localStorage.getItem('token'), 'institution').then(institutionData => {
            if (institutionData.status == true) {
                setCategoryInstitutions(institutionData.data);
            } else {
                setError(true);
                setErrorText(institutionData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
        getAdvertisementCategories(localStorage.getItem('token'), 'occupation').then(occupationData => {
            if (occupationData.status == true) {
                setCategoryOccupations(occupationData.data);
            } else {
                setError(true);
                setErrorText(occupationData.message);
            }
        }).catch(err => {
            localStorage.removeItem('token');
            window.location.href = '/login';
        });
    }, []);

    const imageUploader = React.useRef(null);

    const onHeadlineChange = (event) => {
        setHeadline(event.target.value);
    };

    const onDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const onLinkChange = (event) => {
        setLink(event.target.value);
    };

    const onPhoneChange = (newValue, info) => {
        setPhone(newValue);
    };

    const onAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const onPricePerClickChange = (event) => {
        setPricePerClick(event.target.value);
    };

    const onActiveChange = (event) => {
      setActive(event.target.value);
    };

    const handleImageUpload = (event) => {
        setAdvertisementImageToUpload(event.target?.files[0]);
        setAdvertisementImage(URL.createObjectURL(event.target?.files[0]));
    }

    const categoryLabel = (category) => {
        if (category.category_type === 'location') {
            let name = categoryRegions?.find(region => region.id === category.category_id)?.name;
            let cities = [];
            let subName = null;
            let subClasses = [];
            category.category_multiple.forEach(function(city) {
                cities.push(categoryRegions?.find(region => region.id === category.category_id)?.cities?.find(region => region.id === city)?.name);
            });
            let sentence = '';
            if (cities.length > 0) {
                sentence = 'Targeting students in ' + cities.join(', ');
            } else {
                sentence = 'Targeting students in ' + name;
            }
            if (category.sub_category_type) {
                if (category.sub_category_type === 'occupation') {
                    if (category.sub_category_id) {
                        subName = categoryOccupations?.find(occupation => occupation.id === category.sub_category_id)?.name;
                    }
                    category.sub_category_multiple.forEach(function(occupation) {
                        subClasses.push(categoryOccupations?.find(occ => occ.id === occupation)?.name);
                    });
                    if (subClasses.length > 0) {
                        sentence += ' who are interested in the following occupations: ' + subClasses.join(', ');
                    } else if (subName !== null) {
                        sentence += ' who are interested in the following occupation: ' + subName;
                    }
                }
                if (category.sub_category_type === 'institution') {
                    if (category.sub_category_id) {
                        subName = categoryInstitutions?.find(occupation => occupation.id === category.sub_category_id)?.name;
                    }
                    category.sub_category_multiple.forEach(function(institution) {
                        subClasses.push(categoryInstitutions?.find(inst => inst.id === institution)?.name);
                    });
                    if (subClasses.length > 0) {
                        sentence += ' who are interested in attending ' + subClasses.join(', ');
                    } else if (subName !== null) {
                        sentence += ' who are interested in attending ' + subName;
                    }
                }
            }
            return sentence;
        }
        if (category.category_type === 'occupation') {
            let name = categoryOccupations?.find(region => region.id === category.category_id)?.name;
            let subName = null;
            let subCities = [];
            if (category.sub_category_type) {
                subName = categoryRegions?.find(region => region.id === category.sub_category_id)?.name;
                category.sub_category_multiple.forEach(function(city) {
                    subCities.push(categoryRegions?.find(region => region.id === category.sub_category_id)?.cities?.find(region => region.id === city)?.name);
                });
            }
            let sentence = 'Targeting students looking at the following occupation: ' + name;
            if (subCities.length > 0) {
                sentence += ' and are located in ' + subCities.join(', ');
            } else if (subName !== null) {
                sentence += ' and are located in ' + subName;
            }
            return sentence;
        }
        if (category.category_type === 'institution') {
            let name = categoryInstitutions?.find(region => region.id === category.category_id)?.name;
            let subName = null;
            let subCities = [];
            if (category.sub_category_type) {
                subName = categoryRegions?.find(region => region.id === category.sub_category_id)?.name;
                category.sub_category_multiple.forEach(function(city) {
                    subCities.push(categoryRegions?.find(region => region.id === category.sub_category_id)?.cities?.find(region => region.id === city)?.name);
                });
            }
            let sentence = 'Targeting students looking to go to ' + name;
            if (subCities.length > 0) {
                sentence += ' and are located in ' + subCities.join(', ');
            } else if (subName !== null) {
                sentence += ' and are located in ' + subName;
            }
            return sentence;
        }
    }
    
    const onDeleteCategory = (index) => {
        let clone = [...categories];
        clone.splice(index, 1);
        setCategories(clone);
    }

    const isDisabled = () => {
        if (type !== 'scholarship') {
            if (pricePerClick > parseInt(institution?.daily_spend_limit)) {
                return true;
            }
        }
        
        if (advertisement) {
            let updatedCheck = {
                'headline': headline,
                'description': description,
                'link': link,
                'phone': phone,
                'amount': amount,
                'price_per_click': pricePerClick,
                'categories': JSON.stringify(categories),
                'active': active,
            };
            let actualCheck = {
                'headline': advertisementCheck?.headline ? advertisementCheck?.headline : '',
                'description': advertisementCheck?.description ? advertisementCheck?.description : '',
                'link': advertisementCheck?.link ? advertisementCheck?.link : '',
                'phone': advertisementCheck?.phone ? advertisementCheck?.phone : '',
                'amount': advertisementCheck?.amount ? advertisementCheck?.amount : '',
                'price_per_click': advertisementCheck?.price_per_click ? advertisementCheck?.price_per_click : '',
                'categories': JSON.stringify(advertisementCheck?.categories),
                'active': advertisementCheck?.active ? advertisementCheck?.active : 0,
            };
            if (JSON.stringify(actualCheck) === JSON.stringify(updatedCheck)) {
                return true;
            }
        }
        if (type === 'scholarship') {
            if (
                headline &&
                (phone || link) &&
                categories.length > 0
            ) {
                return false;
            }
        } else {
            if (
                headline &&
                (phone || link) &&
                pricePerClick &&
                categories.length > 0
            ) {
                return false;
            }
        }
        return true;
    }

    const addAdvertisement = () => {
        let ppc = pricePerClick;
        if (type === 'scholarship') {
            ppc = 0;
        }
        let data = new URLSearchParams({
            'headline': headline,
            'description': description,
            'link': link,
            'phone': phone,
            'amount': amount,
            'type': type,
            'price_per_click': ppc,
            'categories': JSON.stringify(categories),
            'active': active,
        });

        if (advertisement) {
            updateInstitutionAdvertisement(localStorage.getItem('token'), data, advertisement?.id).then(advertisement => {
                if (advertisement.status == true) {
                    if (advertisementImageToUpload !== null) {
                        const data = new FormData();
                        data.append('image', advertisementImageToUpload);
                        addInstitutionAdvertisementImage(localStorage.getItem('token'), data, advertisement?.data?.id).then(advertisementImage => {
                        }).catch(err => {
                            setError(true);
                            setErrorText(err);
                        });
                    }
                    setAdvertisementCheck(advertisement?.data);
                    setOpenSuccess(true);
                } else {
                    setError(true);
                    setErrorText(advertisement.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        } else {
            addInstitutionAdvertisement(localStorage.getItem('token'), data).then(advertisement => {
                if (advertisement.status == true) {
                    if (advertisementImageToUpload !== null) {
                        const data = new FormData();
                        data.append('image', advertisementImageToUpload);
                        addInstitutionAdvertisementImage(localStorage.getItem('token'), data, advertisement?.data?.id).then(advertisementImage => {
                        }).catch(err => {
                            setError(true);
                            setErrorText(err);
                        });
                    }
                    setOpenSuccess(true);
                    setReloadList(advertisement?.data);
                    handleCloseAddAdvertisementDrawer();
                } else {
                    setError(true);
                    setErrorText(advertisement.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                {!advertisement && (
                    <Grid item xs={12}>
                        <Typography color='secondary' variant='h5' component='div'>
                            Add {type === 'advertisement' ? 'An' : 'A'} {type.charAt(0).toUpperCase() + type.slice(1)}
                        </Typography>
                        <Divider variant="fullWidth" sx={{ mt: 2 }} />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="title"
                        required={true}
                        fullWidth
                        id="title"
                        label="Headline"
                        type="text"
                        value={headline}
                        onChange={onHeadlineChange}
                        multiline
                        rows={1}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="description"
                        fullWidth
                        id="description"
                        label="Description"
                        type="text"
                        multiline
                        rows={10}
                        value={description}
                        onChange={onDescriptionChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip 
                        arrow 
                        title='You must specify either a url/landing page or a phone number'
                        PopperProps={{style:{zIndex:9999}}}
                    >
                        <TextField
                            name="link"
                            fullWidth
                            id="link"
                            label="Url/Landing Page"
                            type="text"
                            value={link}
                            onChange={onLinkChange}
                            required
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip 
                        arrow 
                        title='You must specify either a url/landing page or a phone number'
                        PopperProps={{style:{zIndex:9999}}}
                    >
                        <MuiTelInput
                            defaultCountry="US"
                            value={phone}
                            onChange={onPhoneChange}
                            fullWidth
                            label="Phone Contact"
                            required
                        />
                    </Tooltip>
                </Grid>
                {(pricePerClick > parseInt(institution?.daily_spend_limit)) && (
                    <Grid item xs={12}>
                        <Typography 
                            component='div'
                            sx={{
                                fontSize:'12px', 
                                color: '#FF0000'
                            }}
                        >
                            The price per click cannot be more than your daily spend limit.
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="amount"
                        fullWidth
                        id="amount"
                        label={type === 'job' ? 'Wage' : 'Amount'}
                        type="number"
                        value={amount}
                        onChange={onAmountChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Tooltip 
                        arrow 
                        title={type === 'scholarship' ? 'We do not accept money for scholarships. They are free to add. Scholarships will still go through a verification process.' : 'The amount of money that will be taken out of your advertising balance per click.'}
                        PopperProps={{style:{zIndex:9999}}}
                    >
                        <TextField
                            name="price_per_click"
                            fullWidth
                            id="price_per_click"
                            label="Price Per Click"
                            type="number"
                            value={pricePerClick}
                            required={true}
                            disabled={type === 'scholarship' ? true : false}
                            onChange={onPricePerClickChange}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            ref={imageUploader}
                            style={{
                            display: "none"
                            }}
                        />
                        <div
                            style={{
                            height: "210px",
                            width: "100%",
                            border: "1px dashed #777",
                            overflow: "hidden",
                            cursor: "pointer",
                            position: "relative"
                            }}
                            onClick={() => imageUploader.current.click()}
                        >
                            {!advertisementImage && (
                            <>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "0",
                                        right: "0",
                                        textAlign: "center",
                                        transform: "translateY(-50%)",
                                        zIndex: 0
                                    }}
                                >
                                    <Typography 
                                        color='secondary' 
                                        variant='h5' 
                                        component='div'
                                    >
                                        Add An Image
                                    </Typography>
                                    <Typography 
                                        color='secondary' 
                                        component='div'
                                        sx={{fontSize:'12px', mt:1}}
                                    >
                                        (Recommended width 500px and height 210px)
                                    </Typography>
                                </div>
                            </>
                            )}
                            {advertisementImage && (
                                <>
                                    <img
                                    src={advertisementImage}
                                    style={{
                                        height: "100%",
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateX(-50%) translateY(-50%)",
                                        zIndex: 1
                                    }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Tooltip 
                        arrow 
                        title='Once approved this will become active in your selected categories.'
                        PopperProps={{style:{zIndex:9999}}}
                    >  
                        <FormControl fullWidth>
                            <InputLabel id="active">Active</InputLabel>
                            <Select
                                labelId="active"
                                id="active"
                                label="Active"
                                value={active ? 1 : 0}
                                onChange={onActiveChange}
                                MenuProps={{
                                    style: {zIndex: 9999}
                                }}
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={0}>Not Active</MenuItem>
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Divider variant="fullWidth" sx={{ 
                        mt: 2, 
                        mb: 2, 
                        whiteSpace: 'wrap !important',
                        '&::before': {
                            width: '20%'
                        },
                        '&::after': {
                            width: '20%'
                        }
                    }}>
                        Where Would You Like Your Listing To Appear?*
                    </Divider>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {(categories && categories.length > 0) ? (
                        <>
                            {categories?.map((category, index) => (
                                <Tooltip 
                                    arrow 
                                    title={categoryLabel(category)}
                                    PopperProps={{style:{zIndex:9999}}}
                                    key={index}
                                >
                                    <Chip
                                        label={categoryLabel(category)}
                                        size='large'
                                        onDelete={() => onDeleteCategory(index)}
                                        sx={{mr:'5px', mb: '5px'}}
                                    />
                                </Tooltip>
                            ))}
                        </>
                    ) : (
                        <Typography 
                            color='secondary' 
                            component='div'
                            sx={{textAlign: 'center'}}
                        >
                            You currently have no categories for where you want your listing to show up.<br />Please add some to upload your {type}.
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}}>
                    <Button
                        variant='outlined'
                        disableElevation
                        size='large'
                        color='secondary'
                        onClick={handleOpenAddCategoryModal}
                    >
                        Add Category
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        disableElevation
                        size='large'
                        sx={{width:'100%'}}
                        disabled={isDisabled()}
                        onClick={() => addAdvertisement()}
                    >
                        {advertisement ? 'Update' : 'Add'} {type.charAt(0).toUpperCase() + type.slice(1)}
                    </Button>
                </Grid>
            </Grid>
            <Modal
                open={openAddCategoryModal}
                onClose={handleCloseAddCategoryModal}
                sx={{zIndex:9999}}
            >
                <Box sx={style}>
                    <AdvertisementAddCategories
                        categoryRegions={categoryRegions}
                        categoryOccupations={categoryOccupations}
                        categoryInstitutions={categoryInstitutions}
                        categories={categories}
                        setCategories={setCategories}
                        handleCloseAddCategoryModal={handleCloseAddCategoryModal}
                    />
                </Box>
            </Modal>
        </>
    )
}