import React, { Suspense } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { userAccount, checkType } from '../../../services/userService';
import { DocumentsAdd } from '../../../components/global/documents/documentsAdd';
import { DocumentsList } from '../../../components/global/documents/documentsList';
import { getAllUserDocuments } from '../../../services/documentsService';
import { getEducators } from '../../../services/studentActionService';

const StudentDocuments = () => {
  const [user, setUser] = React.useState(false);
  const [educators, setEducators] = React.useState([]);
  const [documents, setDocuments] = React.useState([]);
  const [type, setType] = React.useState('student');
  const [updateList, setUpdateList] = React.useState(false);
  const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);
  const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const handleCloseError = () => setError(false);

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUser(userData.data);
        getEducators(localStorage.getItem('token')).then(educators => {
          if (educators.status == true) {
            setEducators(educators.data);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setError(true);
        setErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    getAllUserDocuments(localStorage.getItem('token'), user?.id).then(documents => {
        if (documents.status == true) {
            setDocuments(documents.data);
        } else {
            setError(true);
            setErrorText(documents.message);
        }
    }).catch(err => {
        setError(true);
        setErrorText(err);
    });
  }, [user, openAddDocumentModal, updateList]);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>

      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            Documents
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
          <Button
            variant='contained'
            disableElevation
            size='large'
            onClick={() => setOpenAddDocumentModal(true)}
            disabled={!user?.account_locked ? false : true}
          >
            Add Document
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper>
        <Box sx={{ width: '100%', pt: 2, pb: 2 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <DocumentsList
                type={type}
                documents={documents}
                attachedUser={user}
                setUpdateList={setUpdateList}
                setOpenAddDocumentModal={setOpenAddDocumentModal}
                setError={setError}
                setErrorText={setErrorText}
            />
          </Suspense>
        </Box>
      </Paper>
      <Modal
        open={openAddDocumentModal}
        onClose={handleCloseAddDocumentModal}
      >
          <DocumentsAdd
              type={type}
              attachedUser=''
              educators={educators}
              setError={setError}
              setErrorText={setErrorText}
              handleCloseAddDocumentModal={handleCloseAddDocumentModal}
          />
      </Modal>
      <Snackbar
        open={error}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentDocuments;
