import React, { PureComponent, Suspense } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Modal from '@mui/material/Modal';
import { checkType, educatorAccount } from '../../../services/userService';
import { getStudents, getStudentsByEmployee, getEmployees } from '../../../services/educationActionsService';
import { StudentAdd } from './components/studentAdd';
import { StudentView } from './components/studentView';

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Students = () => {
  const [educator, setEducator] = React.useState(false);
  const [viewStudent, setViewStudent] = React.useState(false);
  const [employees, setEmployees] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);
  const [allStudents, setAllStudents] = React.useState({});
  const [assignedStudents, setAssignedStudents] = React.useState({});
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleCloseAddModal = () => setOpenAddModal(false);
  const StudentList = React.lazy(() => import('./components/studentList'));

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/student/dashboard';
        }
      }
    });
    educatorAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked) {
          window.location.href = '/educator/dashboard';
        }
        setEducator(userData.data);
        if (userData.data?.user_level === 1 || userData.data?.user_level === 2) {
          getStudents(localStorage.getItem('token')).then(studentsData => {
            if (studentsData.status == true) {
              setAllStudents(studentsData.data);
            }
          });
          getEmployees(localStorage.getItem('token')).then(employees => {
            if (employees.status == true) {
              setEmployees(employees.data);
            }
          })
        }
        getStudentsByEmployee(localStorage.getItem('token'), userData.data.id).then(studentsData => {
          if (studentsData.status == true) {
            setAssignedStudents(studentsData.data);
          }
        });
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>
      {viewStudent ? (
        <Suspense fallback={<div>Loading...</div>}>
          <StudentView 
            student={viewStudent}
            educator={educator}
            employees={employees}
            selectedEducatorId={educator.id}
            setViewStudent={setViewStudent}
          />
        </Suspense>
      ) : (
        <>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Students
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => setOpenAddModal(true)}
                disabled={educator?.active || educator?.account_locked ? false : true}
              >
                Add Student
              </Button>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Paper>
              {educator?.user_level === 1 || educator?.user_level === 2 ? (
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="All" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
                            <Tab label="Assigned To You" {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <StudentList
                          studentList={allStudents}
                          educator={educator}
                          employees={employees}
                          educatorType={'institution'}
                          setViewStudent={setViewStudent}
                        />
                      </Suspense>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <StudentList
                          studentList={assignedStudents}
                          educator={educator}
                          employees={employees}
                          educatorType={'educator'}
                          setViewStudent={setViewStudent}
                        />
                      </Suspense>
                    </TabPanel>
                </Box>
              ) : (
                <Box sx={{ width: '100%' }}>
                  <TabPanel value={tabValue} index={0}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <StudentList
                        studentList={assignedStudents}
                        educator={educator}
                        employees={employees}
                        educatorType={'educator'}
                        setViewStudent={setViewStudent}
                      />
                    </Suspense>
                  </TabPanel>
                </Box>
              )}
          </Paper>
        </>
      )}
      <Modal
          open={openAddModal}
          onClose={handleCloseAddModal}
      >
        <>
          <StudentAdd
            educator={educator}
            employees={employees}
            handleCloseAddModal={handleCloseAddModal}
          />
        </>
      </Modal>
    </Container>
  );
};

export default Students;
