import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { userAccount, checkType } from '../../../services/userService';
import { getEducators } from '../../../services/studentActionService';
import { getAllUserTasks } from '../../../services/tasksService';
import { TasksList } from '../../../components/global/tasks/tasksList';
import { TasksView } from '../../../components/global/tasks/tasksView';

const StudentToDo = () => {
  const [user, setUser] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [educators, setEducators] = React.useState([]);
  const [type, setType] = React.useState('student');
  const [reloadList, setReloadList] = React.useState(false);
  const [openTaskDrawer, setOpenTaskDrawer] = React.useState(false);
  const handleCloseDrawer = () => setOpenTaskDrawer(false);
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const handleCloseError = () => setError(false);

  const drawerStyle = {
      width: '60%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  const handleOpenTaskDrawer = (task) => {
      setSelectedTask(task);
      setOpenTaskDrawer(true);
  };

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUser(userData.data);
        getEducators(localStorage.getItem('token')).then(educators => {
          if (educators.status == true) {
            setEducators(educators.data);
          }
        }).catch(err => {
          localStorage.removeItem('token');
          window.location.href = '/login';
        });
      } else {
        setError(true);
        setErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    getAllUserTasks(localStorage.getItem('token')).then(tasks => {
          if (tasks.status == true) {
              setTasks(tasks.data);
          } else {
              setError(true);
              setErrorText(tasks.message);
          }
      }).catch(err => {
          setError(true);
          setErrorText(err);
      });
      setReloadList(false);
  }, [user, setOpenTaskDrawer, reloadList]);

  return (
    <Container sx={{ mt: 4, mb: 4 }} maxWidth='xl'>

      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography color='secondary' variant='h4' component='div'>
            Your Tasks
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} align='right'>
          <Button
            variant='contained'
            disableElevation
            size='large'
            onClick={() => handleOpenTaskDrawer({})}
            disabled={!user?.account_locked ? false : true}
          >
            Add Task
          </Button>
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
      <Paper>
        <TasksList
            type={type}
            tasks={tasks}
            setSelectedTask={setSelectedTask}
            attachedUser={user}
            setReloadList={setReloadList}
            setOpenTaskDrawer={setOpenTaskDrawer}
            setError={setError}
            setErrorText={setErrorText}
        />
      </Paper>
      <Drawer
          open={openTaskDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <TasksView 
              type={type}
              selectedTask={selectedTask}
              attachedUser={user}
              attachedRecipient={{}}
              educators={educators}
              handleCloseDrawer={handleCloseDrawer}
              setReloadList={setReloadList}
              setError={setError}
              setErrorText={setErrorText}
          />
      </Drawer>
      <Snackbar
        open={error}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {errorText}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentToDo;
