import React, { useState, Suspense } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Divider, Tooltip, Link, Button, Modal, Chip, Typography, Paper, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { EmployeeEdit } from './employeeEdit';
import { storageUrl } from '../../../../constants/globals';
import { getStudentsByEmployee } from '../../../../services/educationActionsService';
import { StudentView } from '../../students/components/studentView';
import { getEducatorDashboard } from '../../../../services/educationActionsService';
import { 
  BarChart, 
  Bar,
  XAxis, 
  YAxis, 
  ResponsiveContainer, 
  CartesianGrid, 
  Legend 
} from 'recharts';

const CustomizedYLabel = ({ text }) => {
  return (
      <text
          x={0}
          y={0}
          dx={-180}
          dy={25}
          transform="rotate(-90)"
      >            
          {text}
      </text>
  );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export function EmployeeFull({employees, employee, educator, setViewUser}) {
    const [reportType, setReportType] = React.useState('month');
    const [reports, setReports] = React.useState({});
    const [reportsGraphData, setReportsGraphData] = React.useState({});
    const [assignedStudents, setAssignedStudents] = React.useState({});
    const [viewStudent, setViewStudent] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const handleCloseEditModal = () => setOpenEditModal(false);
    const [tabValue, setTabValue] = React.useState(0);
  
    const handleTabChange = (event, newTabValue) => {
      setTabValue(newTabValue);
    };

    const StudentList = React.lazy(() => import('../../students/components/studentList'));

    React.useEffect(() => {
        getStudentsByEmployee(localStorage.getItem('token'), employee.id).then(employeeStudents => {
            if (employeeStudents.status == true) {
                setAssignedStudents(employeeStudents.data);
            }
        });
        getEducatorDashboard(localStorage.getItem('token'), reportType, employee?.id).then(report => {
            if (report?.status == true) {
                setReports(report?.data);
                setReportsGraphData(transformData(report?.data));
            }
        });
    }, []);

    const changeReport = (event, values) => {
        getEducatorDashboard(localStorage.getItem('token'), event.target.value, employee?.id).then(report => {
            if (report?.status == true) {
                setReportType(event.target.value);
                setReports(report?.data);
                setReportsGraphData(transformData(report?.data));
            }
        });
    }

    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate = new Date(date);
    
        return formattedDate.toLocaleDateString('en-US', options);
    };

    const transformData = (data) => {
        return {
            documents: transformSimpleData(data?.documents),
            messages: transformSimpleData(data?.messages),
            suggestions: transformSimpleData(data?.suggestions),
            tasks: transformSimpleData(data?.tasks),
        }
    }
  
    const transformSimpleData = (data) => {
        let graphData = [];
        Object.keys(data?.graph_data).forEach(function(key){
            graphData.push({
                name: key,
                top: data?.graph_data[key],
            });
        });
        return graphData;
    }

    const reportTimeText = () => {
        switch (reportType) {
            case 'week':
                return 'Past 7 Days';
            case 'month':
                return 'Past Month';
            case 'year':
                return 'Past Year';
            default:
                return 'Past Month';
        }
    }

    return (
        <>
            {viewStudent ? (
                <StudentView 
                    student={viewStudent}
                    educator={educator}
                    employees={employees}
                    selectedEducatorId={employee.id}
                    setViewStudent={setViewStudent}
                />
            ) : (
                <>
                    <Grid container alignItems="center" justifyContent="center" spacing={3}>
                        <Grid item sm={12} md={6}>
                            <Link onClick={() => setViewUser(null)} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                                <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To All Employees
                            </Link>
                        </Grid>
                        <Grid item sm={12} md={6} align='right'>
                            <Tooltip 
                                title={(educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 1 && educator?.is_primary === 0 && employee?.is_primary === 1) ? 'You do not have permission to edit this user.' : ""}
                                arrow
                            >
                                <span>
                                    <Button
                                        variant='contained'
                                        disableElevation
                                        size='large'
                                        disabled={(educator?.active === 0) || (educator?.user_level === 2 && employee?.user_level === 1) || (educator?.user_level === 1 && educator?.is_primary === 0 && employee?.is_primary === 1) || (employee?.id === educator?.id) ? true : false}
                                        onClick={setOpenEditModal}
                                    >
                                        Edit Employee
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                    <Paper>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                                    <Tab label="Overview" {...a11yProps(0)} sx={{width:'50%', maxWidth:'50%'}} />
                                    <Tab label="Assigned Students" {...a11yProps(1)} sx={{width:'50%', maxWidth:'50%'}} />
                                </Tabs>
                            </Box>
                            <TabPanel value={tabValue} index={0} sx={{position:'relative'}}>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">                                   
                                    <Grid item md={12} lg={6} order={{xs: 2, sm: 2, md: 2, lg: 1}}>
                                        <Typography color='secondary' variant='h6'>
                                            {employee?.first_name} {employee?.last_name} {employee?.id === educator?.id && ('(You)')}
                                            {employee?.is_primary === 1 && (
                                                <Tooltip 
                                                    title="This is the primary user on the account. Payments are hooked up to this user."
                                                    arrow
                                                >
                                                    <StarIcon 
                                                        color='primary'
                                                        sx={{ml:'4px',mb:'-6px'}}
                                                    />
                                                </Tooltip>
                                            )} 
                                        </Typography>
                                        <Chip size='small' color={employee?.active === 1 ? 'success' : 'secondary'} label={employee?.active === 1 ? 'Active' : 'Inactive'} />&nbsp;
                                        {employee?.user_level === 1 && (
                                            <Chip size='small' label='Owner' />
                                        )}  
                                        {employee?.user_level === 2 && (
                                            <Chip size='small' label='Manager' />
                                        )} 
                                        {employee?.user_level === 3 && (
                                            <Chip size='small' label='Employee' />
                                        )}
                                        <Divider variant="fullWidth" sx={{ mt: 2 }} />
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={{pl:0}}>
                                                        <Typography color='secondary' component='div' sx={{fontWeight:'bold'}}>
                                                            Email
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" color='primary' sx={{pr:0}}>
                                                        <Typography color='primary' component='div'>
                                                            {employee?.email}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={{pl:0}}>
                                                        <Typography color='secondary' component='div' sx={{fontWeight:'bold'}}>
                                                            Employee Added
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" color='primary' sx={{pr:0}}>
                                                        <Typography color='primary' component='div'>
                                                            {formatDate(employee?.created_at)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    <Grid item md={12} lg={6} textAlign={'center'} order={{xs: 1, sm: 1, md: 1, lg: 2}}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                height: "130px",
                                                width: "130px",
                                                border: "1px solid #777",
                                                overflow: "hidden",
                                                cursor: "pointer",
                                                position: "relative",
                                                borderRadius: '4px'
                                            }}>
                                                {!employee?.profile_image && (
                                                <>
                                                    <PersonIcon
                                                        fontSize="large"
                                                        style={{
                                                            position: "absolute",
                                                            top: "37%",
                                                            left: "37%",
                                                            transform: "translateX(-50%) translateY(-50%)",
                                                            zIndex: 0,
                                                            color: "#999",
                                                            transform: "scale(4)"
                                                        }}
                                                    />
                                                </>
                                                )}
                                                {employee?.profile_image && (
                                                <>
                                                    <img
                                                        src={storageUrl + employee?.profile_image}
                                                        style={{
                                                            height: "100%",
                                                            position: "absolute",
                                                            top: "50%",
                                                            left: "50%",
                                                            transform: "translateX(-50%) translateY(-50%)",
                                                            zIndex: 1
                                                        }}
                                                    />
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{mt:'-10px'}}>
                                    <Grid item sm={12} md={6}>
                                        <Typography color='secondary' variant='h6' component='div'>
                                        Reporting Data
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12} md={6} align='right'>
                                        <FormControl size='small'>
                                            <Select
                                                labelId="reportType"
                                                id="reportType"
                                                onChange={changeReport}
                                                value={reportType}
                                                sx={{ 
                                                    color: '#777777', 
                                                    boxShadow: 'none',
                                                    '.MuiOutlinedInput-notchedOutline': { 
                                                        border: 0 
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: '#f1f1f1',
                                                        '.MuiSelect-icon': { 
                                                            visibility: 'visible'
                                                        }
                                                    }
                                                }}
                                            >
                                                <MenuItem value='week'>
                                                    <ListItemText primary="Past 7 Days" />
                                                </MenuItem>
                                                <MenuItem value='month'>
                                                    <ListItemText primary="Past Month" />
                                                </MenuItem>
                                                <MenuItem value='year'>
                                                    <ListItemText primary="Past Year" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />

                                <Grid container alignItems="center" justifyContent="center" spacing={3}>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Typography 
                                            color='secondary' 
                                            variant='h6' 
                                            component='div'
                                            sx={{mb:2, mt:2}}
                                        >
                                            Messages Sent/Recieved ({reportTimeText()})
                                        </Typography>
                                        <Box sx={{ width:'100%', height: '300px' }}>
                                            <ResponsiveContainer width='100%' height='100%'>
                                                <BarChart
                                                data={reportsGraphData?.messages}
                                                height={300}
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis label={<CustomizedYLabel text='Messages' />} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                                                <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Typography 
                                            color='secondary' 
                                            variant='h6' 
                                            component='div'
                                            sx={{mb:2, mt:2}}
                                        >
                                            Tasks Created ({reportTimeText()})
                                        </Typography>
                                        <Box sx={{ width:'100%', height: '300px' }}>
                                            <ResponsiveContainer width='100%' height='100%'>
                                                <BarChart
                                                data={reportsGraphData?.tasks}
                                                height={300}
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis label={<CustomizedYLabel text='Tasks' />} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                                                <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Typography 
                                            color='secondary' 
                                            variant='h6' 
                                            component='div'
                                            sx={{mb:2, mt:2}}
                                        >
                                            Suggestions Created ({reportTimeText()})
                                        </Typography>
                                        <Box sx={{ width:'100%', height: '300px' }}>
                                            <ResponsiveContainer width='100%' height='100%'>
                                                <BarChart
                                                data={reportsGraphData?.suggestions}
                                                height={300}
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis label={<CustomizedYLabel text='Suggestions' />} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                                                <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <Typography 
                                            color='secondary' 
                                            variant='h6' 
                                            component='div'
                                            sx={{mb:2, mt:2}}
                                        >
                                            Documents Sent ({reportTimeText()})
                                        </Typography>
                                        <Box sx={{ width:'100%', height: '300px' }}>
                                            <ResponsiveContainer width='100%' height='100%'>
                                                <BarChart
                                                data={reportsGraphData?.documents}
                                                height={300}
                                                margin={{
                                                    top: 0,
                                                    right: 0,
                                                    left: 0,
                                                    bottom: 0,
                                                }}
                                                >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis label={<CustomizedYLabel text='Documents' />} />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="bottom" stackId="a" name='Inactive' fill='#777777' />
                                                <Bar dataKey="top" stackId="a" name='Active' fill='#fa7918' />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <StudentList
                                        studentList={assignedStudents}
                                        educator={educator}
                                        employees={employees}
                                        educatorType='educator'
                                        setViewStudent={setViewStudent}
                                    />
                                </Suspense>
                            </TabPanel>
                        </Box>
                    </Paper>
                </>
            )}
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <EmployeeEdit
                    employee={employee}
                    currentEducator={educator}
                />
            </Modal>
        </>
    )
}