import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { userAccount } from '../../services/userService';
import { getLoan, getLoanTypes, addLoan, updateLoan, getLoanRepaymentPlans, getEligibleRepaymentPlans } from '../../services/loansService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export const LoansModal = ({loan}) => {

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [investmentId, setInvestmentId] = React.useState(null);
  const [loanTypes, setLoanTypes] = React.useState([]);
  const [loanType, setLoanType] = React.useState("");
  const [loanBalance, setLoanBalance] = React.useState("");
  const [loanInterestRate, setLoanInterestRate] = React.useState("");
  const [loanRepaymentPlan, setLoanRepaymentPlan] = React.useState("");
  const [loanTerm, setLoanTerm] = React.useState("");
  const [loanRepaymentPlans, setLoanRepaymentPlans] = React.useState([]);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  React.useEffect(() => {
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
        getLoanTypes(localStorage.getItem('token')).then(loanTypesData => {
          if (loanTypesData.status == true) {
            setLoanTypes(loanTypesData.data);
          }
        });
        getLoanRepaymentPlans(localStorage.getItem('token')).then(loanRepaymentPlansData => {
          if (loanRepaymentPlansData.status == true) {
            setLoanRepaymentPlans(loanRepaymentPlansData.data);
          }
        });
        if (loan.id) {
          getLoan(localStorage.getItem('token'), loan.id).then(loanData => {
            if (loanData.status == true) {
              setLoanType(loanData.data.type);
              setLoanBalance(loanData.data.balance);
              setLoanInterestRate(loanData.data.interest_rate);
              setLoanRepaymentPlan(loanData.data.repayment_plan);
              setLoanTerm(loanData.data.loan_term);
            }
          });
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const handleUpdateLoan = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'investment_id':investmentId,
      'type':loanType,
      'balance':loanBalance,
      'interest_rate':loanInterestRate,
      'payments_made':0,
      'repayment_plan':loanRepaymentPlan,
      'loan_term':loanTerm
    });

    if (loan.id) {
      updateLoan(localStorage.getItem('token'), loan.id, data).then(update => {
        window.location.reload();
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    } else {
      addLoan(localStorage.getItem('token'), data).then(update => {
        window.location.reload();
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }
  };

  const onLoanTypeChange = (event, values) => {
    setLoanType('');
    if (event.target.value) {
      setLoanType(event.target.value);
      handleExpectedLoanRepaymentPlans(event);
    }
  }

  const onLoanBalanceChange = (event, values) => {
    setLoanBalance('');
    if (event.target.value) {
      setLoanBalance(event.target.value);
      handleExpectedLoanRepaymentPlans(event);
    }
  }

  const onLoanInterestRateChange = (event, values) => {
    setLoanInterestRate('');
    if (event.target.value) {
      setLoanInterestRate(event.target.value);
      handleExpectedLoanRepaymentPlans(event);
    }
  }

  const onLoanRepaymentPlanChange = (event, values) => {
    setLoanRepaymentPlan('');
    if (event.target.value) {
      setLoanRepaymentPlan(event.target.value);
      handleExpectedLoanRepaymentPlans(event);
    }
  }

  const onLoanTermChange = (event, values) => {
    setLoanTerm('');
    if (event.target.value) {
      setLoanTerm(event.target.value);
      handleExpectedLoanRepaymentPlans(event);
    }
  }

  const handleExpectedLoanRepaymentPlans = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'investment_id':investmentId || null,
      'type':loanType || null,
      'balance':loanBalance || null,
      'interest_rate':loanInterestRate || null,
      'payments_made':0,
      'repayment_plan':loanRepaymentPlan || null,
      'loan_term':loanTerm || null
    });

    getEligibleRepaymentPlans(localStorage.getItem('token'), data).then(loanRepaymentPlansData => {
      if (loanRepaymentPlansData.status == true) {
        setLoanRepaymentPlans(loanRepaymentPlansData.data);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }

  const handleLoanTerm = (code) => {
    switch(code) {
      case 'STANDARD_FIXED':
        setLoanTerm(loanBalanceTermConditional(loanBalance));
        break;
      case 'FIXED_EXTENDED':
        setLoanTerm(25);
        break;
      case 'GRADUATED':
        setLoanTerm(loanBalanceTermConditional(loanBalance));
        break;
      case 'GRADUATED_EXTENDED':
        setLoanTerm(25);
        break;
      case 'INCOME_BASED_REPAY':
        setLoanTerm(25);
        break;
      case 'INCOME_BASED_REPAY_NEW':
        setLoanTerm(20);
        break;
      case 'PAY_AS_YOU_EARN':
        setLoanTerm(20);
        break;
      case 'REVISED_PAY_AS_YOU_EARN':
        setLoanTerm(25);
        break;
      case 'INCOME_CONTINGENT_REPAY':
        setLoanTerm(25);
        break;
      default:
        setLoanTerm(loanBalanceTermConditional(loanBalance));
    }
  }

  const loanBalanceTermConditional = (balance) => {
    if (balance < 10000) {
      return 12;
    } else if (balance < 20000) {
      return 15;
    } else if (balance < 40000) {
      return 20;
    } else if (balance < 60000) {
      return 25;
    } else {
      return 30;
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
          {(loan.id) ? 'Update your' : 'Add a'} loan here. NOTE: This will affect your investment projections.
        </Typography>
        <FormControl fullWidth required>
          <InputLabel id="type-label">Loan Type</InputLabel>
          <Select
            labelId="type"
            id="type"
            name="type"
            label="Loan Type"
            sx={{ mb: 4 }}
            defaultValue=""
            value={loanType}
            onChange={onLoanTypeChange}
          >
            {loanTypes?.map((loanType) => (
              <MenuItem key={loanType.id} value={loanType.id}>{loanType.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="balance"
              required
              fullWidth
              id="balance"
              label="Loan Balance"
              sx={{ mb: 4 }}
              type="number"
              value={loanBalance}
              onChange={onLoanBalanceChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="interest_rate"
              required
              fullWidth
              id="interest_rate"
              label="Interest Rate %"
              sx={{ mb: 4 }}
              type="number"
              value={loanInterestRate}
              onChange={onLoanInterestRateChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="loan_term"
              required
              fullWidth
              id="loan_term"
              label="Loan Term (Years)"
              sx={{ mb: 4 }}
              type="number"
              value={loanTerm}
              onChange={onLoanTermChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="repayment_plan-label">Repayment Plan</InputLabel>
              <Select
                labelId="repayment_plan"
                id="repayment_plan"
                name="repayment_plan"
                label="Repayment Plan"
                sx={{ mb: 4 }}
                defaultValue=""
                value={loanRepaymentPlan}
                onChange={onLoanRepaymentPlanChange}
              >
                {loanRepaymentPlans?.map((loanRepaymentPlan) => (
                  <MenuItem key={loanRepaymentPlan.id} value={loanRepaymentPlan.id} onClick={() => handleLoanTerm(loanRepaymentPlan.code)}>{loanRepaymentPlan.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          onClick={handleUpdateLoan}
          disabled={(loanType && loanBalance && loanInterestRate && loanRepaymentPlan && loanTerm) ? false : true}
        >
          {(loan.id) ? 'Update Loan' : 'Add Loan'}
        </Button>
      </Box>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
