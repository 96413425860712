import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Popper from '@mui/material/Popper';
import { MainListItems, EducatorListItems, AccountListItems } from './listItems';
import { Outlet } from "react-router-dom";
import Footer from "../default/footer/footer";
import './layout.scss';
import { userAccount, resendActivationEmail, getUserNotifications, markNotificationsRead } from '../../../services/userService';
import { Notifications } from '../../global/notifications';
import { getUnreadMessagesCount } from '../../../services/messagingService';

import logoDesktop from '../../../assets/images/logos/logo-wide.jpg';
import logoMobile from '../../../assets/images/logos/logo-icon.jpg';

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Layout = () => {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = React.useState({});
  const [userNotifications, setUserNotifications] = React.useState([]);
  const [unreadNotifications, setUnreadNotifications] = React.useState(null);
  const [resendButtonText, setResendButtonText] = React.useState('RESEND EMAIL');
  const [resendButtonDisabled, setResendButtonDisabled] = React.useState(false);
  const [accountLocked, setAccountLocked] = React.useState(false);
  const [accountSoftLocked, setAccountSoftLocked] = React.useState(false);
  const [messagesCount, setMessagesCount] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openNotifications = Boolean(anchorEl);
  const notificationID = openNotifications ? 'notification-popover' : undefined;

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleClickNotification = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    markNotificationsRead(localStorage.getItem('token')).then(notifications => {
      if (notifications.status === true) {
        setUnreadNotifications(null);
      }
    });
  };

  React.useEffect(() => {
      if (window.innerWidth <= 800 && document.readyState != 'complete') {
          setOpen(false);
      }

      userAccount(localStorage.getItem('token')).then(userData => {
        if (userData.status == true) {
          if (
            (userData.data.investments[0].occupation === "0" || 
            userData.data.investments[0].institution === "0") &&
            !window.location.href.includes('students/dashboard')
          ) {
            window.location.href = '/students/dashboard';
          }
          setUserData(userData.data);
          setAccountLocked(userData.data.account_locked);
          setAccountSoftLocked(userData.data.account_soft_locked);
          getUserNotifications(localStorage.getItem('token')).then(notifications => {
            if (notifications.status === true) {
              setUserNotifications(notifications.data);
              let newCount = 0;
              notifications.data.forEach(notification => {
                if (notification.seen === 0) {
                  newCount++;
                }
              });
              if (newCount === 0) {
                setUnreadNotifications(null);
              } else {
                setUnreadNotifications(newCount);
              }
            }
          });
          getUnreadMessagesCount(localStorage.getItem('token')).then(messages => {
            if (messages.status === true) {
              setMessagesCount(messages.data);
            }
          });
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
  }, []);

  const resendConfirmationEmail = () => {
    resendActivationEmail(localStorage.getItem('token')).then(response => {
      if (response.status == true) {
        setResendButtonText('SUCCESS');
        setResendButtonDisabled(true);
      }
    })
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="absolute" open={open} color="inherit" className="AppBar">
          <Toolbar
            sx={{
              pr: '24px',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" className="logoDiv" sx={{ flexGrow: 1 }}>
              <Link to="/students/dashboard" className="logo" style={{backgroundImage: "url(" + logoMobile + ")"}}>
                <img src={logoDesktop} alt="Logo Desktop" />
              </Link>
            </Typography>
            <IconButton onClick={handleClickNotification}>
              <Badge badgeContent={unreadNotifications} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Popper
              id={notificationID}
              open={openNotifications}
              anchorEl={anchorEl}
              placement={'bottom-end'}
              sx={{
                zIndex: '9999'
              }}
            >
              <Notifications notifications={userNotifications} />
            </Popper>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MainListItems
              accountLocked={accountLocked}
              accountSoftLocked={accountSoftLocked}
            />
            <Divider sx={{ my: 1 }} />
            <EducatorListItems
              accountLocked={accountLocked}
              messagesCount={messagesCount}
              accountSoftLocked={accountSoftLocked}
            />
            <Divider sx={{ my: 1 }} />
            <AccountListItems
              accountLocked={accountLocked}
              accountSoftLocked={accountSoftLocked}
              openSidebar={open}
            />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <Toolbar />
          {userData?.account_notifications?.map((notification, index) => (
            <Alert 
              severity={notification.type} 
              key={index} 
              sx={{borderRadius:0}}
              action={(notification.message === 'Please confirm your email.') &&
                <Button 
                  color="inherit" 
                  size="small"
                  onClick={resendConfirmationEmail}
                  disabled={resendButtonDisabled}
                >
                  {resendButtonText}
                </Button>
              }
            >
              {notification.message}
            </Alert>
          ))}
          <Outlet />
        </Box>
      </Box>
      <Footer />
    </>
  )
};

export default Layout;
