import React from 'react';
import arrayMove from "array-move";
import { Container, Draggable } from 'react-smooth-dnd';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { deleteResumeItem, updateResumeItemOrder } from '../../../services/resumeService';

export function ResumeItemList({type, student, resumeItemList, setResumeItemList, setReloadList, handleOpenDrawer, setError, setErrorText}) {
    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        var formattedDate  = new Date(date);

        return formattedDate.toLocaleDateString('en-US', options);
    };

    const deleteItem = (item) => {
        deleteResumeItem(localStorage.getItem('token'), item?.id).then(deletedItem => {
            if (deletedItem.status) {
                setReloadList(deletedItem)
            } else {
                setError(true);
                setErrorText(deletedItem.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error deleting this document.');
        });
    };

    const onDrop = ({ removedIndex, addedIndex }) => {
        let list = arrayMove(resumeItemList, removedIndex, addedIndex);
        let builtData = [];
        for (let i = 0; i < list.length; i++) {
            let pushData = {
                id: list[i]['id'],
                order: i
            }
            builtData.push(pushData);
        }
        
        let data = new URLSearchParams({
            'items':JSON.stringify(builtData),
        });

        updateResumeItemOrder(localStorage.getItem('token'), data).then(updateItem => {
            if (!updateItem.status) {
                setError(true);
                setErrorText(updateItem.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error deleting this document.');
        });

        setResumeItemList(list);
    };

    return (
        <>
            {resumeItemList?.length > 0 ? (
                <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                    {resumeItemList?.map((item, index) => (
                        <Draggable key={item?.id}>
                            <Grid 
                                container 
                                alignItems="center" 
                                justifyContent="center" 
                                spacing={0}
                                sx={{mb:2}}
                                key={index}
                            >
                                {type === 'student' && (
                                    <Grid item xs={1}>
                                        <IconButton 
                                            className="drag-handle" 
                                            aria-label="drag-handle" 
                                            color="secondary" 
                                            size='small' 
                                        >
                                            <DragHandleIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={type === 'student' ? 10 : 12}>
                                    <Link 
                                        color='primary' 
                                        variant='text'
                                        sx={{cursor:'pointer', fontWeight:'bold'}}
                                        size='large'
                                        onClick={() => handleOpenDrawer(item)}
                                    >
                                        {item.title} {(item?.job_title && item?.job_title !== 'undefined') && ('- ' + item.job_title)}
                                    </Link>
                                    {item?.start_date && (
                                        <Typography sx={{mt:1}} color='secondary'>
                                            {item?.start_date && ('Started: ' + formatDate(item?.start_date))} {item?.end_date && ('- Ended: ' + formatDate(item.end_date))}
                                        </Typography>
                                    )}
                                </Grid>
                                {type === 'student' && (
                                    <Grid item xs={1}>
                                        <IconButton aria-label="delete" color="secondary" size='small' onClick={() => deleteItem(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Divider variant="fullWidth" sx={{ mt: 2}} />
                                </Grid>
                            </Grid>
                        </Draggable>
                    ))}
                </Container>
            ) : (
                <>
                    <Typography color='secondary' variant='h6' component='div' sx={{ textAlign: 'center', pt: 10, pb: 10, pl: 1, pr: 1 }}>
                        {type === 'student' ? 'You have not added anything to your resume.' : 'This student has not added anything to their resume.'}
                    </Typography>
                </>
            )}
        </>
    )
}