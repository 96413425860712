import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { addPortfolioItem, updatePortfolioItem, getPortfolioItemDocuments, getPublicPortfolioItemDocuments, downloadPortfolioItemDocument, downloadPublicPortfolioItemDocument, addPortfolioItemDocument, deletePortfolioItemDocument } from '../../../services/portfolioService';

export function PortfolioItemView({type, student, selectedPortfolioItem, handleCloseDrawer, setReloadList, setError, setErrorText}) {
    const [portfolioItemFiles, setPortfolioItemFiles] = React.useState([]);
    const [portfolioItemTitle, setPortfolioItemTitle] = React.useState(selectedPortfolioItem?.title);
    const [portfolioItemDescription, setPortfolioItemDescription] = React.useState(selectedPortfolioItem?.description);
    const [portfolioItemLink, setPortfolioItemLink] = React.useState(selectedPortfolioItem?.outbound_link);
    const [portfolioItemDate, setPortfolioItemDate] = React.useState(selectedPortfolioItem?.start_date);
    const [isPublic, setIsPublic] = React.useState(selectedPortfolioItem?.public);
    const [formCompleted, setFormCompleted] = React.useState(false);

    const imageUploader = React.useRef(null);

    React.useEffect(() => {
        setFormCompleted(false);
        if (portfolioItemTitle) {
            setFormCompleted(true);
        }
    });

    React.useEffect(() => {
        if (selectedPortfolioItem?.id) {
            if (type === 'student') {
                getPortfolioItemDocuments(localStorage.getItem('token'), selectedPortfolioItem?.id).then(portfolioItemFiles => {
                    if (portfolioItemFiles.status) {
                        setPortfolioItemFiles(portfolioItemFiles.data);
                    } else {
                        setError(true);
                        setErrorText(portfolioItemFiles.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error getting your portfolio documents.');
                });
            } else {
                getPublicPortfolioItemDocuments(student?.id, selectedPortfolioItem?.id).then(portfolioItemFiles => {
                    if (portfolioItemFiles.status) {
                        setPortfolioItemFiles(portfolioItemFiles.data);
                    } else {
                        setError(true);
                        setErrorText(portfolioItemFiles.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error getting your portfolio documents.');
                });
            }
        }
    }, []);

    const onPortfolioItemTitleChange = (event) => {
        setPortfolioItemTitle(event.target.value);
    };

    const onPortfolioItemDescriptionChange = (event) => {
        setPortfolioItemDescription(event.target.value);
    };

    const onPortfolioItemLinkChange = (event) => {
        setPortfolioItemLink(event.target.value);
    };

    const onPortfolioItemDateChange = (date) => {
        setPortfolioItemDate(date);
    };

    const onPublicChange = (event) => {
        setIsPublic(event.target.value);
    };

    const onFilesAdd = (event) => {
        if (selectedPortfolioItem?.id) {
            for (let i = 0; i < event.target.files.length; ++i) {
                const data = new FormData();
                data.append('document', event.target.files[i]);
                addPortfolioItemDocument(localStorage.getItem('token'), data, selectedPortfolioItem?.id).then(document => {
                    if (document.status === false) {
                        setError(true);
                        setErrorText(document.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error uploading the image.');
                });
            };
            getPortfolioItemDocuments(localStorage.getItem('token'), selectedPortfolioItem?.id).then(portfolioItemFiles => {
                if (portfolioItemFiles.status) {
                    setPortfolioItemFiles(portfolioItemFiles.data);
                } else {
                    setError(true);
                    setErrorText(portfolioItemFiles.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error getting portfolio documents.');
            });
        } else {
            let files = portfolioItemFiles.slice();
            for (let i = 0; i < event.target.files.length; ++i) {
                files.push(event.target.files[i]);
            };
            setPortfolioItemFiles(files);
        }
    };

    const deleteFile = (file, index) => {
        if (selectedPortfolioItem?.id) {
            deletePortfolioItemDocument(localStorage.getItem('token'), file?.id).then(deleteFiles => {
                if (deleteFiles.status) {
                    getPortfolioItemDocuments(localStorage.getItem('token'), selectedPortfolioItem?.id).then(portfolioItemFiles => {
                        if (portfolioItemFiles.status) {
                            setPortfolioItemFiles(portfolioItemFiles.data);
                        } else {
                            setError(true);
                            setErrorText(portfolioItemFiles.message);
                        }
                    }).catch(err => {
                        setError(true);
                        setErrorText('There was an error getting portfolio documents.');
                    });
                } else {
                    setError(true);
                    setErrorText(portfolioItemFiles.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error deleting this document.');
            });
        } else {
            let files = portfolioItemFiles.slice();
            delete files[index];
            setPortfolioItemFiles(files);
        }
    }

    const downloadFile = (file, index) => {
        if (selectedPortfolioItem?.id) {
            if (type === 'student') {
                downloadPortfolioItemDocument(localStorage.getItem('token'), file?.id, file?.file).then(downloadedFile => {
                    if (file) {
                        return false;
                    } else {
                        setError(true);
                        setErrorText('There was an error downloading the file.');
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error downloading the file.');
                });
            } else {
                downloadPublicPortfolioItemDocument(student?.id, file?.id, file?.file).then(downloadedFile => {
                    if (file) {
                        return false;
                    } else {
                        setError(true);
                        setErrorText('There was an error downloading the file.');
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText('There was an error downloading the file.');
                });
            }
        } else {
            const link = document.createElement('a')
            const url = URL.createObjectURL(portfolioItemFiles[index])
          
            link.href = url;
            link.download = file.name;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    
    const formatFormDate = (date) => {
        return new Date(date);
    };

    const formatDate = (date) => {
        if (date === null) {
            return null;
        }

        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate  = new Date(date);
        var toLocale = formattedDate.toLocaleDateString('en-US', options);

        if (toLocale === 'Invalid Date') {
            return null;
        }

        return toLocale;
    };

    const handleSubmit = () => {
        let data = new URLSearchParams({
            'title':portfolioItemTitle,
            'description':portfolioItemDescription,
            'outbound_link':formatDate(portfolioItemLink),
            'date':formatDate(portfolioItemDate),
            'public':isPublic
        });
        if (selectedPortfolioItem?.id) {
            updatePortfolioItem(localStorage.getItem('token'), data, selectedPortfolioItem?.id).then(portfolioItem => {
                if (portfolioItem.status === true) {
                    setReloadList(portfolioItem);
                    handleCloseDrawer();
                } else {
                    setError(true);
                    setErrorText(portfolioItem.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error updating your portfolio.');
            });
        } else {
            addPortfolioItem(localStorage.getItem('token'), data).then(portfolioItem => {
                if (portfolioItem.status) {
                    portfolioItemFiles.forEach((file) => {
                        const data = new FormData();
                        data.append('document', file);
                        addPortfolioItemDocument(localStorage.getItem('token'), data, portfolioItem.data.id).then(document => {
                            if (document.status === false) {
                                setError(true);
                                setErrorText(document.message);
                            }
                        }).catch(err => {
                            setError(true);
                            setErrorText('There was an error uploading the image.');
                        });
                    });
                    setReloadList(portfolioItem);
                    handleCloseDrawer();
                } else {
                    setError(true);
                    setErrorText(portfolioItem.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText('There was an error adding to your portfolio.');
            });
        }
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{p:2}}>
                <Grid item xs={12}>
                    <Typography color='secondary' variant='h5' component='div'>
                        {Object.keys(selectedPortfolioItem).length === 0 ? 'Add Something To Your Portfolio' : 'Added On ' + formatDate(selectedPortfolioItem?.created_at)}
                    </Typography>
                    <Divider variant="fullWidth" sx={{ mt: 2 }} />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        name="title"
                        required={type === 'student' ? true : false}
                        disabled={type === 'student' ? false : true}
                        fullWidth
                        id="title"
                        label="Title of this portfolio item"
                        type="text"
                        value={portfolioItemTitle}
                        onChange={onPortfolioItemTitleChange}
                        multiline
                        rows={1}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                            }
                        }}
                    />
                </Grid>
                {(type === 'student' || portfolioItemDescription) && (
                    <Grid item xs={12} sm={12}>
                        <TextField
                            name="description"
                            fullWidth
                            id="description"
                            label="Description"
                            type="text"
                            multiline
                            rows={10}
                            disabled={type === 'student' ? false : true}
                            value={portfolioItemDescription}
                            onChange={onPortfolioItemDescriptionChange}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                }
                            }}
                        />
                    </Grid>
                )}
                {(type === 'student' || portfolioItemLink) && (
                    <Grid item xs={12} sm={12}>
                        <Tooltip 
                            arrow 
                            title='Must include https://'
                            PopperProps={{style:{zIndex:9999}}}
                        >
                            <TextField
                                name="url"
                                fullWidth
                                id="url"
                                label="Relevant Url"
                                type="text"
                                value={portfolioItemLink}
                                onChange={onPortfolioItemLinkChange}
                                multiline
                                rows={1}
                                disabled={type === 'student' ? false : true}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                            />
                        </Tooltip>
                    </Grid>
                )}
                {(type === 'student' || portfolioItemDate) && (
                    <Grid item xs={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                                label="Date"
                                disabled={type === 'student' ? false : true}
                                sx={{
                                    width:'100%',
                                    '& .Mui-disabled': {
                                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                    }
                                }}
                                onChange={(newValue) => onPortfolioItemDateChange(newValue)}
                                value={portfolioItemDate ? formatFormDate(portfolioItemDate) : null}
                            />
                        </LocalizationProvider>
                    </Grid>
                )}
                {type === 'student' && (
                    <Grid item xs={12} sm={12}>
                        <Tooltip 
                            arrow 
                            title='This will make this portfolio item public to educators and employers.'
                            PopperProps={{style:{zIndex:9999}}}
                        >  
                            <FormControl fullWidth>
                                <InputLabel id="public">Public</InputLabel>
                                <Select
                                    labelId="public"
                                    id="public"
                                    label="Is Public"
                                    value={isPublic ? 1 : 0}
                                    onChange={onPublicChange}
                                    MenuProps={{
                                        style: {zIndex: 9999}
                                    }}
                                    sx={{
                                        textAlign:'left',
                                        '& .Mui-disabled': {
                                            WebkitTextFillColor: 'rgba(0, 0, 0, 0.87) !important'
                                        }
                                    }}
                                >
                                    <MenuItem value={1}>Public</MenuItem>
                                    <MenuItem value={0}>Not Public</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                )}
                {(type === 'student' || portfolioItemFiles.length > 0) && (
                    <Grid item xs={12}>
                        <Tooltip 
                            arrow 
                            title='Showcase images, essays or any other files.'
                            PopperProps={{style:{zIndex:9999}}}
                        >  
                            <Divider variant="fullWidth" sx={{ mb: 2 }}>Portfolio Files</Divider>
                        </Tooltip>
                        {portfolioItemFiles?.map((file, index) => (
                                <Grid 
                                    container 
                                    alignItems="center" 
                                    justifyContent="center" 
                                    spacing={0}
                                    sx={{mb:2}}
                                    key={index}
                                >
                                    <Grid item xs={type === 'student' ? 11 : 12}>
                                        <Button 
                                            color='primary' 
                                            variant='text'
                                            sx={{cursor:'pointer', textDecoration:'underline'}} 
                                            onClick={() => downloadFile(file, index)}
                                        >
                                            {file?.name ? file?.name : file?.file}
                                        </Button>
                                    </Grid>
                                    {type === 'student' && (
                                        <Grid item xs={1}>
                                            <IconButton aria-label="delete" color="secondary" size='small' onClick={() => deleteFile(file, index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <Divider variant="fullWidth" sx={{ mt: 2}} />
                                    </Grid>
                                </Grid>
                        ))}
                        {type === 'student' && (
                            <>
                                <Button
                                    variant='outlined'
                                    disableElevation
                                    size='small'
                                    color='secondary'
                                    component="label"
                                    onClick={() => imageUploader.current.click()}
                                >
                                    Click To Add Files
                                </Button>
                                <input
                                    type="file"
                                    accept="*/*"
                                    onChange={onFilesAdd}
                                    ref={imageUploader}
                                    style={{
                                        display: "none"
                                    }}
                                />
                                <Divider variant="fullWidth" sx={{ mt: 2}} />
                            </>
                        )}
                    </Grid>
                )}
                {type === 'student' && (
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            disableElevation
                            size='large'
                            sx={{width:'100%'}}
                            disabled={formCompleted ? false : true}
                            onClick={handleSubmit}
                        >
                            {Object.keys(selectedPortfolioItem).length === 0 ? 'Add To' : 'Update'} Portfolio
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    )
}