import React, { Suspense } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { DocumentsList } from '../../../../../components/global/documents/documentsList';
import { getUserDocumentsByReferrer } from '../../../../../services/documentsService';

export function DocumentsComponent({user, educator, setOpenErrorModal, setOpenErrorModalText}) {
    const [documents, setDocuments] = React.useState([]);
    const [type, setType] = React.useState('student');
    const [updateList, setUpdateList] = React.useState(false);
    const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);

    React.useEffect(() => {
        getUserDocumentsByReferrer(localStorage.getItem('token'), educator?.id).then(documents => {
            if (documents.status == true) {
                setDocuments(documents.data);
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(documents.message);
            }
        }).catch(err => {
            setOpenErrorModal(true);
            setOpenErrorModalText(err);
        });
    }, []);

    return (
        <>
            <Typography color='secondary' variant='h5' component='div'>
                Documents
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Box sx={{ width: '100%' }}>
                <Suspense fallback={<div>Loading...</div>}>
                    <DocumentsList
                        type={type}
                        documents={documents}
                        attachedUser={user}
                        setUpdateList={setUpdateList}
                        setOpenAddDocumentModal={setOpenAddDocumentModal}
                        setError={setOpenErrorModal}
                        setErrorText={setOpenErrorModalText}
                        disableGutters={true}
                    />
                </Suspense>
            </Box>
        </>
    )
}