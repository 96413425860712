import React, { Suspense } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { userAccount, checkType } from '../../../services/userService';
import { storageUrl } from '../../../constants/globals';
import { getAllUserResumeItems, downloadResume } from '../../../services/resumeService';
import { ResumeItemList } from '../../../components/global/resume/resumeList';
import { ResumeItemView } from '../../../components/global/resume/resumeView';

const Resume = () => {
  const [profileImage, setProfileImage] = React.useState(null);
  const [userData, setUserData] = React.useState({});
  const [resumeData, setResumeData] = React.useState({});
  const [selectedResumeItem, setSelectedResumeItem] = React.useState({});
  const [openError, setOpenError] = React.useState(false);
  const [openErrorText, setOpenErrorText] = React.useState('');
  const handleCloseError = () => setOpenError(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const handleCloseSuccess = () => setOpenSuccess(false);
  const [reloadList, setReloadList] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleCloseDrawer = () => {
    setSelectedResumeItem({});
    setOpenDrawer(false);
  }

  const handleOpenDrawer = (resumeItem) => {
    setSelectedResumeItem(resumeItem);
    setOpenDrawer(true);
  }

  const drawerStyle = {
      width: '60%',
      '@media (max-width: 800px)': {
          width: '100%'
      }
  }

  if (!localStorage.getItem('token')) {
      window.location.href = '/login';
  }

  React.useEffect(() => {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        if (userData.data.educator_count === 0) {
          window.location.href = '/students/dashboard';
        }
        if (userData.data?.account_locked || userData.data?.account_soft_locked) {
          window.location.href = '/students/dashboard';
        }
        setUserData(userData.data);
        if (userData.data.profile_image !== null) {
          setProfileImage(storageUrl + userData.data.profile_image);
        }
        getAllUserResumeItems(localStorage.getItem('token')).then(resumeItems => {
          if (resumeItems.status == true) {
            setResumeData(resumeItems.data);
          } else {
            setOpenError(true);
            setOpenErrorText(resumeItems.message);
          }
        });
      } else {
        setOpenError(true);
        setOpenErrorText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  React.useEffect(() => {
    getAllUserResumeItems(localStorage.getItem('token')).then(resumeItems => {
          if (resumeItems.status == true) {
            setResumeData(resumeItems.data);
          } else {
            setOpenError(true);
            setOpenErrorText(resumeItems.message);
          }
      }).catch(err => {
        setOpenError(true);
        setOpenErrorText(err);
      });
      setReloadList(false);
  }, [userData, setOpenDrawer, reloadList]);

  const handleDownloadResume = () => {
    downloadResume(localStorage.getItem('token')).then(resumeItems => {
      if (resumeItems.status == true) {
        return false;
      } else {
        setOpenError(true);
        setOpenErrorText(resumeItems.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }

  return (
    <>
      <Container sx={{ mt: 4, mb: 4 }} maxWidth='lg'>
        <Paper sx={{ p: 2 }}>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item sm={12} md={6}>
              <Typography color='secondary' variant='h4' component='div'>
                Resume
              </Typography>
            </Grid>
            <Grid item sm={12} md={6} align='right'>
              <Button
                variant='contained'
                disableElevation
                size='large'
                onClick={() => handleOpenDrawer({})}
                disabled={!userData?.account_locked ? false : true}
              >
                Add To Resume
              </Button>
              <Tooltip 
                  arrow 
                  title='Download your resume to submit to employers.'
              >  
                <IconButton aria-label="download" color="primary" size='large' onClick={handleDownloadResume}>
                    <FileDownloadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ width: '100%' }}>
              <Suspense fallback={<div>Loading...</div>}>
                <ResumeItemList
                  type={'student'}
                  student={userData}
                  resumeItemList={resumeData}
                  setResumeItemList={setResumeData}
                  setReloadList={setReloadList}
                  handleOpenDrawer={handleOpenDrawer}
                  setError={setOpenError}
                  setErrorText={setOpenErrorText}
                />
              </Suspense>
          </Box>
        </Paper>
      </Container>
      <Snackbar
        open={openError}
        onClose={handleCloseError}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
          {openErrorText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        onClose={handleCloseSuccess}
        autoHideDuration={6000}
      >
        <Alert onClose={handleCloseSuccess} severity="success" sx={{ width: '100%', maxWidth: '500px' }}>
          Success
        </Alert>
      </Snackbar>
      <Drawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          anchor='right'
          sx={{zIndex: 9999}}
          PaperProps={{
              sx: drawerStyle,
          }}
      >
          <DialogTitle 
              disableTypography
              sx={{
                  position: 'absolute',
                  top: '5px',
                  right: '5px',
                  padding: '0 !important'
              }}
          >
              <IconButton onClick={handleCloseDrawer}>
                  <CloseIcon />
              </IconButton>
          </DialogTitle>
          <ResumeItemView
            type={'student'}
            student={userData}
            selectedResumeItem={selectedResumeItem}
            handleCloseDrawer={handleCloseDrawer}
            setReloadList={setReloadList}
            setError={setOpenError}
            setErrorText={setOpenErrorText}
          />
      </Drawer>
    </>
  );
};

export default Resume;
