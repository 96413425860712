import { apiUrl } from '../constants/globals';

export async function getLoans(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoan(token, loanId) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentLoans(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/loans/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addLoan(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateLoan(token, loanId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteLoan(token, loanId) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/' + loanId, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoanTypes(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loanTypes/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getLoanRepaymentPlans(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/loanRepaymentPlans/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEligibleRepaymentPlans(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/eligibleRepaymentPlans/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function handleNoLoans(token, investmentId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/loans/handleNoLoans/' + investmentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
