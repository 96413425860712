import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import { createBillingMethod } from '../../../../../../../services/educatorBillingService';
import { FormControl } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const EditPayments = ({
    setAddPaymentPage,
    setUpdateSubscriptionPage,
    subscription
}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);

    const [stripeComplete, setStripeComplete] = React.useState(false);
    const [paymentComplete, setPaymentComplete] = React.useState(false);
    const [addressComplete, setAddressComplete] = React.useState(false);
    const [completedButtonText, setCompletedButtonText] = React.useState('Submit');
    const [defaultPaymentMethod, setDefaultPaymentMethod] = React.useState(true);

    const setOverviewPage = () => {
        setAddPaymentPage(false);
        setUpdateSubscriptionPage(false);
    };

    React.useEffect(() => {
        setStripeComplete(false);
        if (paymentComplete && addressComplete) {
            setStripeComplete(true);
        }
    });

    const paymentElementChange = (element) => {
        setPaymentComplete(false);
        if (!element.empty && element.complete) {
            setPaymentComplete(true);
        }
    }

    const addressElementChange = (element) => {
        setAddressComplete(false);
        if (!element.empty && element.complete) {
            setAddressComplete(true);
        }
    }

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }

        setStripeComplete(false);
        setAddressComplete(false);
        setPaymentComplete(false);
        setCompletedButtonText('Working');

        const {error: submitError} = await elements.submit();
        if (submitError) {
            setOpenErrorModal(true);
            setOpenErrorModalText('Error submitting payment, please try again.');

            setStripeComplete(true);
            setAddressComplete(true);
            setPaymentComplete(true);
            setCompletedButtonText('Submit');
            return;
        }

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            elements
        });

        if (error) {
            setOpenErrorModal(true);
            setOpenErrorModalText('Error submitting payment, please try again.');

            setStripeComplete(true);
            setAddressComplete(true);
            setPaymentComplete(true);
            setCompletedButtonText('Submit');
            return;
        }

        let paymentData = new URLSearchParams({
            'payment': paymentMethod.id,
            'primary': defaultPaymentMethod
        });

        await createBillingMethod(localStorage.getItem('token'), paymentData).then(cardAction => {
            if (cardAction.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(cardAction.errors);

                setStripeComplete(true);
                setAddressComplete(true);
                setPaymentComplete(true);
                setCompletedButtonText('Submit');
            }
        });
    };

    const onDefaultPaymentMethodChange = (event, values) => {
      if (values) {
        setDefaultPaymentMethod(values);
      } else {
        setDefaultPaymentMethod(false);
      }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Link onClick={setOverviewPage} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                    <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To Overview
                </Link>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            </Grid>
            <FormControl style={{minWidth: '100%'}}>
                <Grid item sm={12}>
                    <AddressElement 
                        options={{mode: 'billing'}}
                        onChange={addressElementChange}
                    />
                </Grid>
                <Grid item sm={12} sx={{mt:2, mb:1}}>
                    <PaymentElement
                        onChange={paymentElementChange}
                    />
                </Grid>
                <Grid item sm={12}>
                    {Object.keys(subscription).length !== 0 && (
                        <Tooltip arrow title={Object.keys(subscription).length === 0 ? "The first card added must be your default payment method." : ""}>
                            <FormControlLabel
                                sx={{mt:1}}
                                disabled={Object.keys(subscription).length === 0 ? true : false}
                                control={<Checkbox value="defaultPaymentMethod" defaultChecked onChange={onDefaultPaymentMethodChange} color="primary" name="default_payment_method" />}
                                label="Use as default payment method for both subscriptions and your advertising balance."
                            />
                        </Tooltip>
                    )}
                </Grid>
                <Button 
                    fullWidth
                    disableElevation
                    variant='contained'
                    onClick={handleSubmit}
                    disabled={stripeComplete ? false : true}
                    sx={{mt:2}}
                >
                    {completedButtonText}
                </Button>
            </FormControl>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    )
}

export default EditPayments;