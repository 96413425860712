import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { addDocument } from '../../../services/documentsService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

export function DocumentsAdd({type, attachedUser, educators, setError, setErrorText, handleCloseAddDocumentModal}) {
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [documentTitle, setDocumentTitle] = useState(null);
    const [document, setDocument] = useState(null);
    const [status, setStatus] = useState('Requires Review');
    const [selectedEducator, setSelectedEducator] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const imageUploader = React.useRef(null);

    React.useEffect(() => {
        setFormCompleted(false);
        if (documentTitle && document && status) {
            if (type !== 'student') {
                if (attachedUser !== null) {
                    setFormCompleted(true);
                }
            } else {
                setFormCompleted(true);
            }
        }
    });

    const onDocumentTitleChange = (event) => {
        setDocumentTitle(event.target.value);
    };

    const onDocumentChange = (event) => {
        setDocument(event.target.files[0]);
    };

    const onStatusChange = (event) => {
      setStatus(event.target.value);
    }

    const onEducatorChange = (event) => {
      setSelectedEducator(event.target.value);
    }

    const handleSubmit = (event) => {
        const data = new FormData();
        data.append('document', document);
        data.append('document_title', documentTitle);
        data.append('status', status);
        if (type === 'student') {
            if (selectedEducator) {
                data.append('attached_user', selectedEducator);
            }
        } else {
            data.append('attached_user', attachedUser?.id);
        }

        addDocument(localStorage.getItem('token'), data).then(document => {
            if (document.status) {
                handleCloseAddDocumentModal();
            } else {
                setError(true);
                setErrorText(document.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText('There was an error uploading the image.');
        });
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <Box sx={style}>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography textAlign={'center'} color='secondary' variant='h5' component='div'>
                        Add New Document
                    </Typography>
                </Grid>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid item xs={12}>
                  <TextField
                    name="document_title"
                    required
                    fullWidth
                    id="document_title"
                    label="Document Title"
                    type="text"
                    onChange={onDocumentTitleChange}
                  />
                </Grid>

                {type !== 'student' ? (
                    <Grid item xs={12}>
                        <TextField
                            name="student"
                            required
                            fullWidth
                            id="student"
                            label="Student"
                            type="text"
                            disabled
                            value={attachedUser.first_name + ' ' + attachedUser.last_name}
                        />
                    </Grid>
                ) : (
                    <>
                        {educators?.length > 0 && (
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                <InputLabel id="educator">Send To Educator (Optional)</InputLabel>
                                <Select
                                    labelId="educator"
                                    id="educator"
                                    label="Send To Educator (Optional)"
                                    sx={{textAlign:'left'}}
                                    onChange={onEducatorChange}
                                >
                                    {educators?.map((educator, index) => (
                                        <MenuItem key={index} value={educator?.id}>{educator?.first_name} {educator?.last_name}</MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                            </Grid>
                        )}
                    </>
                )}

                <Grid item xs={12}>
                    <div 
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: '100%',
                        }}
                    >
                        <input
                            type="file"
                            accept="*/*"
                            onChange={onDocumentChange}
                            ref={imageUploader}
                            style={{
                                display: "none"
                            }}
                        />
                        <div
                            style={{
                                height: "100px",
                                width: "100%",
                                border: `1px dashed rgba(0, 0, 0, ${isHovered ? 0.85 : 0.35})`,
                                overflow: "hidden",
                                cursor: "pointer",
                                position: "relative",
                                borderRadius: "4px"
                            }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={() => imageUploader.current.click()}
                        >
                            <div style={{
                                position: "absolute",
                                top: "0",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                                {document ? (
                                    <CheckCircleSharpIcon 
                                        sx={{
                                            color:'green',
                                            opacity: 0.65,
                                            transform: 'scale(2)',
                                        }}
                                    />
                                ) : (
                                    'Click to upload document *'
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="status">Document Status *</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      label="Document Status *"
                      value={status}
                      sx={{textAlign:'left'}}
                      onChange={onStatusChange}
                    >
                      <MenuItem key='1' value='Requires Review'>Requires Review</MenuItem>
                      <MenuItem key='2' value='Submitted'>Submitted</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        disableElevation
                        size='large'
                        sx={{width:'100%'}}
                        disabled={formCompleted ? false : true}
                        onClick={handleSubmit}
                    >
                        Add Document
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}