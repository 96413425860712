import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { userAccount } from '../../services/userService';
import { getScholarship, getScholarshipTypes, addScholarship, updateScholarship } from '../../services/scholarshipService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export const ScholarshipsModal = ({scholarship}) => {

  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openErrorModalText, setOpenErrorModalText] = React.useState('');
  const [investmentId, setInvestmentId] = React.useState(null);
  const [scholarshipTypes, setScholarshipTypes] = React.useState([]);
  const [scholarshipType, setScholarshipType] = React.useState("");
  const [scholarshipName, setScholarshipName] = React.useState("");
  const [scholarshipRecurring, setScholarshipRecurring] = React.useState("0");
  const [scholarshipRecurrancyYears, setScholarshipRecurrancyYears] = React.useState("1");
  const [showRecurrancy, setShowRecurrancy] = React.useState(false);
  const [scholarshipTotal, setScholarshipTotal] = React.useState("");
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  React.useEffect(() => {
    userAccount(localStorage.getItem('token')).then(userData => {
      if (userData.status == true) {
        setInvestmentId(userData.data.investments[0].id);
        getScholarshipTypes(localStorage.getItem('token')).then(scholarshipTypesData => {
          if (scholarshipTypesData.status == true) {
            setScholarshipTypes(scholarshipTypesData.data);
          }
        });
        if (scholarship.id) {
          getScholarship(localStorage.getItem('token'), scholarship.id).then(scholarshipData => {
            if (scholarshipData.status == true) {
              setScholarshipType(scholarshipData.data.type);
              setScholarshipName(scholarshipData.data.name);
              setScholarshipRecurring(scholarshipData.data.recurrent.toString());
              if (scholarshipData.data.recurrent === 1) {
                setShowRecurrancy(true);
              }
              setScholarshipRecurrancyYears(scholarshipData.data.recurrency_years.toString());
              setScholarshipTotal(scholarshipData.data.total);
            }
          });
        }
      } else {
        setOpenErrorModal(true);
        setOpenErrorModalText(userData.message);
      }
    }).catch(err => {
      localStorage.removeItem('token');
      window.location.href = '/login';
    });
  }, []);

  const handleUpdateScholarship = (event) => {
    event.preventDefault();

    let data = new URLSearchParams({
      'investment_id':investmentId,
      'type':scholarshipType,
      'name':scholarshipName,
      'recurrent':scholarshipRecurring,
      'recurrency_years':scholarshipRecurrancyYears,
      'total':scholarshipTotal
    });

    if (scholarship.id) {
      updateScholarship(localStorage.getItem('token'), scholarship.id, data).then(update => {
        window.location.reload();
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    } else {
      addScholarship(localStorage.getItem('token'), data).then(update => {
        window.location.reload();
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }
  };

  const onScholarshipTypeChange = (event, values) => {
    if (event.target.value) {
      setScholarshipType(event.target.value);
    }
  }

  const onScholarshipNameChange = (event, values) => {
    if (event.target.value) {
      setScholarshipName(event.target.value);
    }
  }

  const onScholarshipTotalChange = (event, values) => {
    if (event.target.value) {
      setScholarshipTotal(event.target.value);
    }
  }

  const onScholarshipRecurringChange = (event, values) => {
    if (event.target.value) {
      if (event.target.value === "1") {
        setShowRecurrancy(true);
      } else {
        setShowRecurrancy(false);
      }
      setScholarshipRecurring(event.target.value);
    }
  }

  const onScholarshipRecurrancyYearsChange = (event, values) => {
    if (event.target.value) {
      setScholarshipRecurrancyYears(event.target.value);
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'left' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 4, textAlign: 'center' }}>
          {(scholarship.id) ? 'Update your' : 'Add a'} scholarship here. NOTE: This will affect your investment projections.
        </Typography>
        <TextField
          name="name"
          required
          fullWidth
          id="name"
          label="Scholarship Name"
          sx={{ mb: 4 }}
          value={scholarshipName}
          onChange={onScholarshipNameChange}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="type-label">Scholarship Type</InputLabel>
              <Select
                labelId="type"
                id="type"
                name="type"
                label="Scholarship Type"
                sx={{ mb: 4 }}
                defaultValue=""
                value={scholarshipType}
                onChange={onScholarshipTypeChange}
              >
                {scholarshipTypes?.map((scholarshipType) => (
                  <MenuItem key={scholarshipType.id} value={scholarshipType.id}>{scholarshipType.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="amount"
              required
              fullWidth
              id="amount"
              label="Scholarship Amount"
              sx={{ mb: 4 }}
              value={scholarshipTotal}
              onChange={onScholarshipTotalChange}
            />
          </Grid>
        </Grid>

        <FormControl fullWidth required>
          <InputLabel id="type-label">Is This Scholarship Recurring?</InputLabel>
          <Select
            labelId="recurring"
            id="recurring"
            name="recurring"
            label="Is This Scholarship Recurring?"
            sx={{ mb: 4 }}
            defaultValue="0"
            value={scholarshipRecurring}
            onChange={onScholarshipRecurringChange}
          >
            <MenuItem value="0">No</MenuItem>
            <MenuItem value="1">Yes</MenuItem>
          </Select>
        </FormControl>

        {showRecurrancy && (
          <>
            <TextField
              name="recurrency_years"
              required
              fullWidth
              id="recurrency_years"
              label="How Many Years?"
              sx={{ mb: 4 }}
              value={scholarshipRecurrancyYears}
              onChange={onScholarshipRecurrancyYearsChange}
            />
          </>
        )}

        <Button
          variant="contained"
          type="submit"
          disableElevation
          size="large"
          fullWidth
          onClick={handleUpdateScholarship}
          disabled={(scholarshipType && scholarshipName && scholarshipRecurring && scholarshipRecurrancyYears && scholarshipTotal) ? false : true}
        >
          {(scholarship.id) ? 'Update Scholarship' : 'Add Scholarship'}
        </Button>
      </Box>
      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openErrorModalText}
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
