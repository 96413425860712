import * as React from 'react';
import Typography from '@mui/material/Typography';

export const educationPickerDisclaimer = (
  <React.Fragment>
    <Typography component='div' sx={{ textAlign: 'center', fontSize: 10, mt: 2 }}>
        <strong>DISCLAIMER:</strong>

        The school or university selection software provided is intended solely for informational purposes and should not be considered as professional advice. While we strive to provide accurate and up-to-date information, we cannot guarantee the completeness, reliability, or suitability of the data presented.

        Users are encouraged to conduct thorough research and consult with relevant educational institutions, advisors, or experts before making any decisions related to school or university selection. The software's recommendations are based on algorithms and user input, and individual preferences, circumstances, and goals may vary.

        We disclaim any liability for decisions made or actions taken based on the use of this software. Users assume full responsibility for their choices and outcomes resulting from the utilization of the software's recommendations.

        By using this software, you acknowledge and agree to these terms and conditions.
    </Typography>
  </React.Fragment>
);