import { apiUrl } from '../constants/globals';

export async function logIn(data) {
    try {
        const response = await fetch(apiUrl + '/login/', {
          method: 'POST',
          body: data
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function logOut(token) {
    try {
        const response = await fetch(apiUrl + '/logout/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function registerStudent(data) {
    try {
        const response = await fetch(apiUrl + '/register/', {
          method: 'POST',
          body: data
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function registerEducator(data) {
    try {
        const response = await fetch(apiUrl + '/educator/register', {
          method: 'POST',
          body: data
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function activate(token) {
    try {
        const response = await fetch(apiUrl + '/register/activation/' + token, {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function userAccount(token) {
    try {
        const response = await fetch(apiUrl + '/students/account/user/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function publicUserAccount(id) {
    try {
        const response = await fetch(apiUrl + '/students/public/account/user/' + id, {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function educatorAccount(token) {
    try {
        const response = await fetch(apiUrl + '/educator/account/user/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function checkType(token) {
    try {
        const response = await fetch(apiUrl + '/checkType/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateUserAccount(token, id, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/user/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateStudentPhoto(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/user/photo/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateEducatorAccount(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/account/user/', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateEducatorPhoto(token, data) {
    try {
        const response = await fetch(apiUrl + '/educator/account/user/photo/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function investmentCalculator(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/account/calculator/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentCalculationsByEmployer(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/calculator/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function requestRecover(data) {
    try {
        const response = await fetch(apiUrl + '/recover', {
          method: 'POST',
          body: data
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function recover(token, data) {
    try {
        const response = await fetch(apiUrl + '/recover/' + token, {
          method: 'POST',
          body: data
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function resendActivationEmail(token) {
    try {
        const response = await fetch(apiUrl + '/resend/activation', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserNotifications(token) {
    try {
        const response = await fetch(apiUrl + '/user/notifications/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function markNotificationsRead(token) {
    try {
      const response = await fetch(apiUrl + '/user/notifications/', {
          method: 'POST',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}
