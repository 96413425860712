import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { educationPickerDisclaimer } from '../../../components/global/educationPickerDisclaimer';
import { getEducationTest } from '../../../services/educationPickerService';
import { getStates } from '../../../services/generalService';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function EducationDecider({handleComplete, handleEducationFindModal, educationDeciderData, setEducationDeciderData, chosenState, setChosenState, setOpenModal, setOpenModalText}) {
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [educationTest, setEducationTest] = React.useState([]);
    const [states, setStates] = React.useState([]);

    React.useEffect(() => {
        getEducationTest(localStorage.getItem('token')).then(test => {
            if (test.status == true) {
                let buildSelectedAnswers = {};
                setEducationTest(test.data);
                test.data?.forEach(question => {
                    buildSelectedAnswers[question.id] = educationDeciderData[question.id] ? educationDeciderData[question.id] : [];
                });
                getStates().then(states => {
                    setStates(states.data);
                });
                setEducationDeciderData(buildSelectedAnswers);
                checkFormCompletion(buildSelectedAnswers, chosenState);
            } else {
                setOpenModal(true);
                setOpenModalText(test.message);
            }
        });
    }, []);

    const onStateChange = (event) => {
      setChosenState(event.target.value);
      checkFormCompletion(educationDeciderData, event.target.value);
    }

    const handleSelectChange = (event, question) => {
        let main = structuredClone(educationDeciderData);
        main[question?.id] = [event.target.value];
        setEducationDeciderData(main);
        checkFormCompletion(main, chosenState);
    }

    const handleSearchChange = (event, values, question) => {
        let uniqueData = [];
        let idMap = new Map();

        values.forEach(item => {
        if (!idMap.has(item.id)) {
            idMap.set(item.id, true);
            uniqueData.push(item);
        }
        });

        let main = structuredClone(educationDeciderData);
        main[question?.id] = uniqueData;
        setEducationDeciderData(main);
        checkFormCompletion(main, chosenState);
    }

    const checkFormCompletion = (answers, state) => {
        let formCompletion = false;
        if (
            state &&
            answers[43].length > 0 &&
            answers[45].length > 0 &&
            answers[46].length > 0
        ) {
            if (answers[43].length > 0 && answers[43][0] === 434 && answers[44].length === 0) {
                formCompletion = false;
            } else {
                formCompletion = true;
            }
        }
        setFormCompleted(formCompletion);
    }

    return (
        <>
            <Typography 
                variant="h4" 
                sx={{
                    textAlign:'center',
                    fontSize: {
                        xs: '18px',
                        sm: '22px',
                        md: '30px'
                    }
                }}
            >
            Answer a few questions and we will suggest an institution.
            </Typography>
            <Typography color="secondary">
            Based on what you answer we will match a school based on your chosen or matched career paths.
            </Typography>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl fullWidth>
                    <InputLabel id="state_label">What state do you reside in? *</InputLabel>
                    <Select
                        labelId="state_label"
                        id="state"
                        value={chosenState || ''}
                        label="What state do you reside in? *"
                        onChange={onStateChange}
                    >
                        {states.map((state) => (
                        <MenuItem key={state.code} value={state.code}>{state.name}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </Grid>
                {educationTest?.length > 0 && (
                    <>
                        {educationTest?.map((question, index) => (
                            <>
                            {(question?.id !== 44 || question?.id === 44 && Object.keys(educationDeciderData).length > 0 && educationDeciderData[43][0] === 434) && (
                                <Grid 
                                    item 
                                    xs={12} 
                                    sm={(question?.id === 44 && Object.keys(educationDeciderData).length > 0 && educationDeciderData[43][0] === 434) ? 12 : 6} 
                                    md={(question?.id === 44 && Object.keys(educationDeciderData).length > 0 && educationDeciderData[43][0] === 434) ? 12 : 6} 
                                    key={index}
                                >
                                        {question.type === 'select' && (
                                            <FormControl fullWidth>
                                                <InputLabel>{question.title} {(question.required) ? '*' : ''}</InputLabel>
                                                <Select
                                                label={(question.required) ? question.title + ' *' : question.title}
                                                onChange={(event) => handleSelectChange(event, question)}
                                                value={educationDeciderData[question.id]}
                                                >
                                                {question.answers?.map((answer, index) => (
                                                    <MenuItem value={answer.id} key={index}>{answer.title}</MenuItem>
                                                ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {question.type === 'search_multiple' && (
                                            <Autocomplete
                                                multiple
                                                options={question.answers}
                                                disableCloseOnSelect
                                                defaultValue={educationDeciderData[question.id]}
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, values) => handleSearchChange(event, values, question)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 2 }}
                                                            checked={selected}
                                                        />
                                                        {option.title}
                                                    </li>
                                                )}
                                                style={{ width: '100%' }}
                                                renderInput={(params) => (
                                                    <TextField {...params} label={(question.required) ? question.title + ' *' : question.title} placeholder={(question.required) ? question.title + ' *' : question.title} />
                                                )}
                                            />
                                        )}
                                        {(question?.id === 49) && (
                                            <Typography 
                                                color='secondary'
                                                sx={{
                                                    fontSize: '14px',
                                                    mt:1
                                                }}
                                            >
                                                Don't see your major? Chat with support and we will work to get it added.
                                            </Typography>
                                        )}
                                        {(question?.id === 48) && (
                                            <Typography 
                                                color='secondary'
                                                sx={{
                                                    fontSize: '14px',
                                                    mt:1
                                                }}
                                            >
                                                Don't see your religon here? Chat with support and we will work to get it added.
                                            </Typography>
                                        )}
                                </Grid>
                            )}
                            </>
                        ))}
                    </>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleEducationFindModal(false)}
                    >
                    Cancel
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disableElevation
                    onClick={handleComplete}
                    disabled={formCompleted ? false : true}
                    >
                    Next Step
                    </Button>
                </Grid>
                {educationPickerDisclaimer}
            </Grid>
        </>
    )
}