import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import './recover.scss';
import { recover, checkType } from '../../../services/userService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const Recover = () => {
  if (localStorage.getItem('token')) {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
  }

  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState('');
  const [requirementsCompleted, setRequirementsCompleted] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { token } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    recover(token, data).then(requestData => {
      if (requestData.status == true) {
        localStorage.setItem('token', requestData.data.token);
        checkType(requestData.data.token).then(requestData => {
          if (requestData.status == true) {
            if (requestData.data?.type === 1) {
              window.location.href = '/students/dashboard';
            } else if (requestData.data?.type === 2) {
              window.location.href = '/educator/dashboard';
            }
          }
        });
      } else {
        setOpenModal(true);
        setOpenModalText(requestData.message);
      }
    });
  };

  const requirementsChange = (event, values) => {
    setRequirementsCompleted(false);
    if (
      validatePassword(event.target.value)
    ) {
      setRequirementsCompleted(true);
    }
  }

  const validatePassword = (password) => {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length > 4;
  }

  return (
    <Container component="div" className="wrapper wrapper-small recover">
      <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Tooltip enterTouchDelay={0} title="Must include at least one uppercase character, number and special character" arrow>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Enter New Password"
            id="password"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            onChange={requirementsChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Tooltip>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disableElevation
          sx={{ mt: 3, mb: 2 }}
          disabled={requirementsCompleted ? false : true}
        >
          Recover Password
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
}

export default Recover;
