import React, { useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import Container from '@mui/material/Container';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { registerEducator } from '../../../services/userService';
import { checkType } from '../../../services/userService';

const steps = ['Company Info', 'Your Info'];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

let signupData = {
  'first_name':null,
  'last_name':null,
  'email':null,
  'phone':null,
  'password':null,
  'newsletter_opt_in':true,
  'institution_type':'university',
  'institution_name':null,
  'employees_count':null,
  'students_count':null
};

const Register = () => {
  if (localStorage.getItem('token')) {
    checkType(localStorage.getItem('token')).then(requestData => {
      if (requestData.status == true) {
        if (requestData.data?.type === 1) {
          window.location.href = '/students/dashboard';
        } else if (requestData.data?.type === 2) {
          window.location.href = '/educator/dashboard';
        }
      }
    });
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [institutionLabel, setInstitutionLabel] = React.useState("School/University Name");
  const [identificationCompleted, setIdentificationCompleted] = React.useState(false);
  const [companyCompleted, setCompanyCompleted] = React.useState(false);
  const [paymentCompleted, setPaymentCompleted] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalText, setOpenModalText] = React.useState('');
  const [phoneValue, setPhoneValue] = useState("");
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = new FormData();
    for ( var key in signupData ) {
      data.append(key, signupData[key]);
    }
    registerEducator(data).then(register => {
      if (register.status == true) {
        localStorage.setItem('token', register.data.token);
        window.location.href = '/educator/dashboard';
      } else {
        setOpenModal(true);
        setOpenModalText(register.message);
      }
    });
  };

  const onNewsletterChange = (event, values) => {
    if (values) {
      signupData['newsletter_opt_in'] = values;
    } else {
        signupData['newsletter_opt_in'] = false;
    }
  }

  const onCompanyChange = (event, values) => {
    signupData[event.target.name] = null;
    setCompanyCompleted(false);
    if (event.target.value) {
      signupData[event.target.name] = event.target.value;
      if (signupData['institution_type'] == 'university') {
        setInstitutionLabel("School/University Name");
      } else {
        setInstitutionLabel("Business Name");
      }
    }
    if (
      signupData['institution_type'] &&
      signupData['institution_name'] &&
      signupData['employees_count'] &&
      signupData['students_count']
    ) {
      setCompanyCompleted(true);
    }
  }

  const handlePhone = (newValue, info) => {
    signupData['phone'] = null;
    setIdentificationCompleted(false);
    setPhoneValue(newValue);
    signupData['phone'] = info.numberValue;
    if (
      signupData['first_name'] &&
      signupData['last_name'] &&
      signupData['phone'] &&
      validateEmail(signupData['email']) &&
      validatePassword(signupData['password'])
    ) {
      setIdentificationCompleted(true);
    }
  };

  const onIdentificationChange = (event, values) => {
    signupData[event.target.name] = null;
    setIdentificationCompleted(false);
    if (event.target.value) {
      signupData[event.target.name] = event.target.value;
    }
    if (
      signupData['first_name'] &&
      signupData['last_name'] &&
      signupData['phone'] &&
      validateEmail(signupData['email']) &&
      validatePassword(signupData['password'])
    ) {
      setIdentificationCompleted(true);
    }
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const validateEmail = (email) => {
    return String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const validatePassword = (password) => {
    return /[A-Z]/       .test(password) &&
           /[a-z]/       .test(password) &&
           /[0-9]/       .test(password) &&
           /[^A-Za-z0-9]/.test(password) &&
           password.length > 4;
  }

  const company = (step) => {
    return (
      <Box component="div" sx={{ display: (step == 0) ? 'block' : 'none' }}>
        <Typography variant="h4" align="center">
          Step 1: Company Info
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={12} align="center">
            <RadioGroup
              name="institution_type"
              required
              id="institution_type"
              defaultValue="university"
              row
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onChange={onCompanyChange}
            >
              <FormControlLabel value="university" control={<Radio />} label="School or University" />
              <FormControlLabel value="business" control={<Radio />} label="Individual or Business" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="institution_name"
              name="institution_name"
              autoComplete="institution_name"
              label={institutionLabel}
              onChange={onCompanyChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="employees_count"
              label="Approximate Number of Employees"
              name="employees_count"
              autoComplete="employees_count"
              type="number"
              onChange={onCompanyChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="students_count"
              label="Approximate Number of Students"
              name="students_count"
              autoComplete="students_count"
              type="number"
              onChange={onCompanyChange}
            />
          </Grid>
          <Grid item sm={12}>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation
              onClick={handleComplete}
              disabled={companyCompleted ? false : true}
            >
              Next Step
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    )
  }

  const identification = (step) => {
    return (
      <Box component="div" sx={{ display: (step == 1) ? 'block' : 'none' }}>
        <Typography variant="h4" align="center">
          Step 2: Your Info
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="first_name"
              required
              fullWidth
              id="first_name"
              label="First Name"
              autoFocus
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="family-name"
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={onIdentificationChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MuiTelInput
              defaultCountry="US"
              value={phoneValue}
              onChange={handlePhone}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Tooltip enterTouchDelay={0} title="Must include at least one uppercase character, number and special character" arrow>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="new-password"
                onChange={onIdentificationChange}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Previous Step
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disableElevation
              disabled={identificationCompleted ? false : true}
            >
              Finish
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link href="/login" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Container component="div" className="wrapper wrapper-small signup">
      <Stepper activeStep={activeStep} nonLinear alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
        {company(activeStep)}
        {identification(activeStep)}
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {openModalText}
          </Typography>
        </Box>
      </Modal>
    </Container>
  );
};

export default Register;
