import { apiUrl } from '../constants/globals';

export async function getOccupationsList() {
    try{
        const response = await fetch(apiUrl + '/getOccupationsList');
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getIndustriesList(occupation_id) {
    try{
        const response = await fetch(apiUrl + '/getIndustriesList/' + occupation_id);
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateOccupation(token, investmentId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/occupation/' + investmentId, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function setCustomWage(token, investmentId, data) {
    try {
        const response = await fetch(apiUrl + '/students/account/occupation/' + investmentId + '/customWage', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getOccupation(token, investmentId) {
    try {
        const response = await fetch(apiUrl + '/students/account/occupation/' + investmentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getStudentOccupationByEmployer(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/educator/student/occupation/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateIncome(token, id, data) {
    try {
      const response = await fetch(apiUrl + '/students/account/occupation/' + id + '/customWage', {
        method: 'PUT',
        body: data,
        headers: new Headers({
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      });
      return await response.json();
    } catch(error) {
        return error;
    }
}
