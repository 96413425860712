import { apiUrl } from '../constants/globals';

export async function getAllUserResumeItems(token) {
    try {
        const response = await fetch(apiUrl + '/students/resume/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getAllPublicUserResumeItems(userId) {
    try {
        const response = await fetch(apiUrl + '/students/public/resume/' + userId, {
          method: 'GET'
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserResumeItemById(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/resume/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function downloadResume(token) {
    try {
        const response = await fetch(apiUrl + '/students/download/resume/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        var formattedDate  = new Date();

        const blob = await response.blob();
        const fileURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = 'resume-' + formattedDate.toLocaleDateString('es-CL');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        return true;
    } catch(error) {
        return error;
    }
}

export async function addResumeItem(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/resume/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateResumeItem(token, data, id) {
    try {
        const response = await fetch(apiUrl + '/students/resume/' + id, {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function updateResumeItemOrder(token, data) {
    try {
        const response = await fetch(apiUrl + '/students/resumeOrder/', {
          method: 'PUT',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteResumeItem(token, id) {
    try {
        const response = await fetch(apiUrl + '/students/resume/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}