import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { inviteStudent } from '../../../../services/educationActionsService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 400,
  minWidth: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign:'center'
};

export function StudentAdd({educator, employees, handleCloseAddModal}) {
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [checkedEmployees, setCheckedEmployees] = React.useState([]);
    const [errorText, setErrorText] = React.useState('');

    const handleEmployeeChange = (event) => {
      const {
        target: { value },
      } = event;
      setCheckedEmployees(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleEmailChange = (event, values) => {
      setEmail('');
      if (event.target.value) {
        setEmail(event.target.value);
      }
    };

    const handleMessageChange = (event, values) => {
      setMessage('');
      if (event.target.value) {
        setMessage(event.target.value);
      }
    };

    const handleSelectRender = (selected) => {
        let selectedNames = [];
        selected.forEach(id => {
            employees?.forEach(employee => {
                if (id === employee.id) {
                    selectedNames.push(employee.first_name + ' ' + employee.last_name);
                }
            });
        });
        return selectedNames.join(', ');
    }

    const handleAddStudent = (event) => {
      let controlledEmployees = '[' + educator?.id + ']';
      if (educator?.user_level === 1 || educator?.user_level === 2) {
        controlledEmployees = '[' + [...new Set(checkedEmployees)].toString() + ']';
      }
      let data = new URLSearchParams({
        'email':email,
        'educators':controlledEmployees,
        'message':message
      });
  
      inviteStudent(localStorage.getItem('token'), data).then(update => {
        if (update?.status == true) {
            setEmail('');
            setCheckedEmployees([]);
            handleCloseAddModal();
            window.location.href = '/educator/students';
        } else {
            setErrorText(update.message);
        }
      });
    };

    const getEmployeeName = (employee) => {
        let ending = '';
        if (employee.id === educator.id) {
            ending = ' (You)';
        }
        return employee.first_name + ' ' + employee.last_name + ending;
    }

    return (
        <Box sx={style}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography textAlign={'center'} color='secondary' variant='h4' component='div'>
                        Invite A Student
                    </Typography>
                    {errorText && (
                        <Typography textAlign={'center'} sx={{color:'red'}} variant='h6' component='div'>
                            {errorText}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="email"
                        id="email"
                        label="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </Grid>
                {employees?.length > 0 && (educator?.user_level === 1 || educator?.user_level === 2) && (
                    <Grid item xs={12}>
                        <FormControl sx={{ width:'100%', textAlign: 'left' }}>
                            <InputLabel id="employees-label">Assign An Employee</InputLabel>
                            <Select
                                labelId="employees-label"
                                id="employees-checkbox"
                                multiple
                                required
                                fullWidth
                                value={checkedEmployees}
                                onChange={handleEmployeeChange}
                                input={<OutlinedInput label="Assign An Employee" />}
                                renderValue={(selected) => handleSelectRender(selected)}
                            >
                                {employees?.map((employee, index) => (
                                    <MenuItem key={employee.id} value={employee.id} disabled={employee.active && !employee.account_locked ? false : true}>
                                        <Checkbox checked={checkedEmployees.indexOf(employee.id) > -1} />
                                        <ListItemText primary={getEmployeeName(employee)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <TextField
                        name="message"
                        fullWidth
                        id="message"
                        label={employees?.length > 0 && (educator?.user_level === 1 || educator?.user_level === 2) ? ('Add A Message (NOTE: This will send as a direct message to the student from each educator selected)') : ('Add A Message (Optional)')}
                        type="text"
                        multiline
                        rows={5}
                        value={message}
                        onChange={handleMessageChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type="submit"
                        disableElevation
                        size="large"
                        fullWidth
                        onClick={handleAddStudent}
                        disabled={email && (educator?.user_level === 3 || checkedEmployees.length > 0) ? false : true}
                    >
                        Invite Student
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}