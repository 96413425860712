import React, { PureComponent } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { getMessages, markMessagesRead } from '../../../services/messagingService';

export function MessagingContacts ({setError, setErrorText, user, contacts, selectedContact, setSelectedContact, setSelectedMessages, setMessagesOpen}) {
    const [searchedContacts, setSearchedContacts] = React.useState(contacts);

    React.useEffect(() => {
        if (window.innerWidth > 899) {
            setTimeout(function(){
                if (searchedContacts.length > 0) {
                    let id = null;
                    if (user?.id === searchedContacts[0]?.user_id) {
                        id = searchedContacts[0]?.student_id;
                    } else if (user?.id === searchedContacts[0]?.student_id) {
                        id = searchedContacts[0]?.user_id;
                    }
                    markContactMessagesRead(id, searchedContacts[0], searchedContacts);
                }
            }, 5000);
        }
    }, []);

    const formatDate = (date) => {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        var formattedDate  = new Date(date);

        return formattedDate.toLocaleDateString('en-US', options);
    }

    const searchQuery = (searchQuery) => {
        if (!searchQuery) {
            setSearchedContacts(contacts);
            setContactAndMessages(contacts);
        } else {
            let contactArray = [];
            contacts?.forEach(contact => {
                let nameCheck = false;

                if (!searchQuery) {
                    nameCheck = true;
                } else {
                    let contactName = contact.first_name + ' ' + contact.last_name;
                    if (contactName.toLowerCase().includes(searchQuery.toLocaleLowerCase())) {
                        nameCheck = true;
                    }
                }

                if (nameCheck) {
                    contactArray.push(contact);
                }
            });
            setSearchedContacts(contactArray);
            setContactAndMessages(contactArray);
        }
    };

    const setContactAndMessages = (contacts) => {
        if (contacts.length > 0) {
            let id = null;
            if (user?.id === contacts[0]?.user_id) {
                id = contacts[0]?.student_id;
            } else if (user?.id === contacts[0]?.student_id) {
                id = contacts[0]?.user_id;
            }
            setSelectedContact(contacts[0]);
            getMessages(localStorage.getItem('token'), id).then(messages => {
                if (messages.status == true) {
                    setSelectedMessages(messages.data);
                    if (window.innerWidth > 899) {
                        setTimeout(function(){
                            markContactMessagesRead(id, contacts[0], contacts);
                        }, 5000);
                    }
                } else {
                    setError(true);
                    setErrorText(messages.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        } else {
            setSelectedContact([]);
            setSelectedMessages([]);
        }
    }

    const handleOpenMessages = (contact) => {
        setSelectedContact(contact);
        let id = null;
        if (user?.id === contact?.user_id) {
            id = contact?.student_id;
        } else if (user?.id === contact?.student_id) {
            id = contact?.user_id;
        }
        if (id !== null) {
            getMessages(localStorage.getItem('token'), id).then(messages => {
                if (messages.status == true) {
                    setSelectedMessages(messages.data);
                    setMessagesOpen(true);
                    markContactMessagesRead(id, contact, searchedContacts);
                } else {
                    setError(true);
                    setErrorText(messages.message);
                }
            }).catch(err => {
                setError(true);
                setErrorText(err);
            });
        }
    }

    const markContactMessagesRead = (id, contact, searchedContacts) => {
        markMessagesRead(localStorage.getItem('token'), id).then(messagesRead => {
            if (messagesRead.status == true) {
                let contactArray = [];
                searchedContacts?.map((searchedContact) => {
                    if (contact?.id === searchedContact?.id) {
                        if (searchedContact?.unread_messages !== 0) {
                            searchedContact.unread_messages = 0;
                        }
                    }
                    contactArray.push(searchedContact);
                });
                setSearchedContacts(contactArray);
            } else {
                setError(true);
                setErrorText(messagesRead.message);
            }
        }).catch(err => {
            setError(true);
            setErrorText(err);
        });
    }

    return (
        <>
            <Grid 
                container 
                spacing={0} 
                sx={{
                    padding: 1,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ddd',
                    borderTop: '1px solid #ddd',
                }}
            >
                <TextField
                    id="search-bar"
                    className="text"
                    onInput={(e) => {
                        searchQuery(e.target.value);
                    }}
                    label="Search Contacts"
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                    sx={{
                        width:'100%'
                    }}
                />
            </Grid>
            {searchedContacts?.length > 0 ? (
                <>
                    {searchedContacts?.map((contact, index) => (
                        <Grid 
                            container 
                            spacing={0} 
                            key={index}
                            sx={{
                                padding: 1,
                                backgroundColor: selectedContact?.id === contact?.id ? '#fff' : 'transparent',
                                borderBottom: '1px solid #ddd',
                                wordWrap: 'break-word',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#fff'
                                }
                            }}
                            onClick={() => handleOpenMessages(contact)}
                        >
                            <Grid 
                                item 
                                xs={9}
                            >
                                <Typography 
                                    component='div'
                                    sx={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {contact?.first_name} {contact?.last_name}
                                    {contact?.active === 0 && (
                                        <Chip label="Inactive" size='small' sx={{ml:1, fontWeight:'normal'}} />
                                    )}
                                </Typography>
                                {contact?.messages?.message && (
                                    <Typography 
                                        component='div'
                                        sx={{
                                            fontSize: '14px', 
                                            lineHeight: '1'
                                        }}
                                    >
                                        {contact?.messages?.message}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid 
                                item 
                                xs={3}
                                sx={{
                                    textAlign: 'right',
                                    wordWrap: 'break-word',
                                }}
                            >
                                <Typography 
                                    component='div' 
                                    sx={{
                                        fontSize: '12px', 
                                        lineHeight: '1',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {contact?.messages?.message ? (
                                        <>
                                            {formatDate(contact?.messages?.created_at)}
                                        </>
                                    ) : (
                                        'No messages'
                                    )}
                                </Typography>
                                {contact?.unread_messages !== 0 && (
                                    <Chip
                                        label='NEW'
                                        color='primary'
                                        size='small'
                                        sx={{
                                            color: '#fff',
                                            fontSize: '10px',
                                            marginTop: '5px',
                                        }}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </>
            ) : (
                <Typography 
                    color='secondary'
                    component='div'
                    sx={{ textAlign: 'center', p: 2 }}
                >
                    No Contacts Found. Add {user.user_level !== undefined ? 'a student' : 'an educator'} to your account to start messaging.
                </Typography>
            )}
        </>
    );
};