import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { getAdvertisementReports } from '../../../services/advertisementsService';
import { 
  BarChart, 
  Bar,
  Tooltip,
  XAxis, 
  YAxis, 
  ResponsiveContainer, 
  CartesianGrid, 
  Legend 
} from 'recharts';

const CustomizedYLabel = ({ text }) => {
  return (
      <text
          x={0}
          y={0}
          dx={-180}
          dy={25}
          transform="rotate(-90)"
      >            
          {text}
      </text>
  );
};

export function AdvertisementReports({advertisement, setError, setErrorText}) {
    const [reportsGraphData, setReportsGraphData] = React.useState([]);
    const [reports, setReports] = React.useState({});
    const [reportType, setReportType] = React.useState('month');
    const [reportClicks, setReportClicks] = React.useState(0);
    const [reportAmount, setReportAmount] = React.useState(0);

    React.useEffect(() => {
        getAdvertisementReports(localStorage.getItem('token'), advertisement?.id, reportType).then(reports => {
        if (reports.status == true) {
            setReports(reports?.data);
            setReportData(reports);
        } else {
            setError(true);
            setErrorText(reports.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }, []);

    const changeReport = (event, values) => {
        getAdvertisementReports(localStorage.getItem('token'), advertisement?.id, event.target.value).then(reports => {
        if (reports.status == true) {
            setReportType(event.target.value);
            setReportData(reports);
        } else {
            setError(true);
            setErrorText(reports.message);
        }
      }).catch(err => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }

    const setReportData = (reports) => {
        setReports(reports?.data);
        let clicks = 0;
        let amount = 0;
        let graphData = [];
        Object.keys(reports?.data).forEach(function(key){
            clicks += reports?.data[key].clicks;
            amount += reports?.data[key].amount;
            graphData.push({
                name: key,
                clicks: reports?.data[key].clicks,
                amount: reports?.data[key].amount
            });
        });
        setReportClicks(clicks);
        setReportAmount(amount);
        setReportsGraphData(graphData);
    }

    return (
        <Grid container alignItems="center" justifyContent="center" spacing={3} sx={{textAlign:'left', p: 2}}>
            <Grid item xs={12}>
                <Typography color='secondary' variant='h5' component='div'>
                    Advertisement Report
                </Typography>
                <Divider variant="fullWidth" sx={{ mt: 2 }} />
            </Grid>
            <Grid item xs={12} align='right'>
                <FormControl size='small'>
                    <Select
                        labelId="reportType"
                        id="reportType"
                        onChange={changeReport}
                        value={reportType}
                        sx={{ 
                            color: '#777777', 
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { 
                                border: 0 
                            },
                            '&:hover': {
                                backgroundColor: '#f1f1f1',
                                '.MuiSelect-icon': { 
                                    visibility: 'visible'
                                }
                            }
                        }}
                        MenuProps={{
                            style: {zIndex: 9999}
                        }}
                    >
                        <MenuItem value='week'>
                            <ListItemText primary="Past 7 Days" />
                        </MenuItem>
                        <MenuItem value='month'>
                            <ListItemText primary="Past Month" />
                        </MenuItem>
                        <MenuItem value='year'>
                            <ListItemText primary="Past Year" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} sx={{textAlign:'center'}}>
                <Typography color='secondary' variant='h5' component='div'>
                    Total Amount Spent
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                    ${reportAmount}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{textAlign:'center'}}>
                <Typography color='secondary' variant='h5' component='div'>
                    Total Number of Clicks
                </Typography>
                <Typography color='primary' variant='h4' component='div'>
                    {reportClicks}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width:'100%', height: '300px' }}>
                    <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                        data={reportsGraphData}
                        height={300}
                        margin={{
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis label={<CustomizedYLabel text='Amount' />} />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="clicks" stackId="a" name='Number of Clicks' fill='#fa7918' />
                        <Bar dataKey="amount" stackId="b" name='Amount Spent' fill='#777777' />
                    </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Grid>
        </Grid>
    );
}