import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { getUserDocumentsByReferrer, getEmployeeDocumentsByStudent } from '../../../../../services/documentsService';
import { DocumentsList } from '../../../../../components/global/documents/documentsList';
import { DocumentsAdd } from '../../../../../components/global/documents/documentsAdd';

export function DocumentsComponent({user, student, selectedEducator, setOpenDocuments, setError, setErrorText}) {
    const [documents, setDocuments] = React.useState([]);
    const [type, setType] = React.useState('employee');
    const [updateList, setUpdateList] = React.useState(false);
    const [openAddDocumentModal, setOpenAddDocumentModal] = React.useState(false);
    const handleCloseAddDocumentModal = () => setOpenAddDocumentModal(false);

    React.useEffect(() => {
        if (selectedEducator !== undefined) {
            if (user?.id === selectedEducator?.id) {
                setType('self_educator');
                getUserDocumentsByReferrer(localStorage.getItem('token'), student?.id).then(documents => {
                    if (documents.status == true) {
                        setDocuments(documents.data);
                    } else {
                        setError(true);
                        setErrorText(documents.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            } else {
                setType('educator');
                getEmployeeDocumentsByStudent(localStorage.getItem('token'), selectedEducator?.id, student?.id).then(documents => {
                    if (documents.status == true) {
                        setDocuments(documents.data);
                    } else {
                        setError(true);
                        setErrorText(documents.message);
                    }
                }).catch(err => {
                    setError(true);
                    setErrorText(err);
                });
            }
        }
    }, [setOpenDocuments, selectedEducator, openAddDocumentModal, updateList]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography color='secondary' variant='h5' component='div'>
                    Documents
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} align='right'>
                    {type === 'self_educator' && (
                        <Button
                            variant='contained'
                            disableElevation
                            size='small'
                            sx={{ mr: 1 }}
                            onClick={setOpenAddDocumentModal}
                            disabled={selectedEducator?.active ? false : true}
                        >
                            Add Document
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
            <DocumentsList
                type={type}
                documents={documents}
                attachedUser={selectedEducator}
                setUpdateList={setUpdateList}
                setOpenAddDocumentModal={setOpenAddDocumentModal}
                setError={setError}
                setErrorText={setErrorText}
            />
            <Modal
              open={openAddDocumentModal}
              onClose={handleCloseAddDocumentModal}
            >
                <DocumentsAdd
                    type={type}
                    attachedUser={student}
                    educators={[]}
                    setError={setError}
                    setErrorText={setErrorText}
                    handleCloseAddDocumentModal={handleCloseAddDocumentModal}
                />
            </Modal>
        </>
    )
}