import React, { PureComponent } from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { updateSubscription } from '../../../../../../../services/educatorBillingService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
};

const tierLimits = {
    'Standard': 50,
    'Standard Plus': 100,
    'Premium': 200,
    'Enterprise': Infinity
}

const EditSubscription = ({
    products,
    institution,
    subscription,
    setAddPaymentPage,
    setUpdateSubscriptionPage
}) => {
    const [openErrorModal, setOpenErrorModal] = React.useState(false);
    const [openErrorModalText, setOpenErrorModalText] = React.useState('');
    const handleCloseErrorModal = () => setOpenErrorModal(false);
    const [selectedPricing, setSelectedPricing] = React.useState({});
    const [selectedPricingValue, setSelectedPricingValue] = React.useState({});

    const handlePricingChange = (event, values) => {
        let pricing = structuredClone(selectedPricing);
        pricing[values.props.id] = event.target.value;
        
        let pricingValues = structuredClone(selectedPricingValue);
        products.forEach(product => {
            if (product.id === values.props.id) {
                product.pricing.forEach(pricing => {
                    if (pricing.id === event.target.value) {
                        pricingValues[values.props.id] = {
                            'unit_amount':pricing?.unit_amount,
                            'interval': pricing?.recurring.interval
                        };
                    }
                });
            }
        });
        
        setSelectedPricing(pricing);
        setSelectedPricingValue(pricingValues);
    };

    const handlePricingClick = (productID) => {
        let pricingData = new URLSearchParams({
            'price':selectedPricing[productID]
        });

        updateSubscription(localStorage.getItem('token'), pricingData).then(response => {
            if (response.status == true) {
                window.location.reload();
            } else {
                setOpenErrorModal(true);
                setOpenErrorModalText(response.message);
            }
        });
    };

    React.useEffect(() => {
        if (Object.keys(selectedPricing).length === 0) {
            if (Array.isArray(products) && products.length !== 0) {
                let pricing = {};
                let pricingValues = {};

                products?.forEach((product) => {
                    pricing[product.id] = product?.pricing[0].id;
                    pricingValues[product.id] = {
                        'unit_amount':product?.pricing[0]?.unit_amount,
                        'interval': product?.pricing[0]?.recurring.interval,
                    };
                });

                setSelectedPricing(pricing);
                setSelectedPricingValue(pricingValues);
            }
        }
    }, []);

    const setOverviewPage = () => {
        setAddPaymentPage(false);
        setUpdateSubscriptionPage(false);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Link onClick={setOverviewPage} color="secondary" underline="none" sx={{cursor:'pointer'}}>
                    <ArrowBackIosIcon sx={{ fontSize: 12 }} /> Back To Account Overview
                </Link>
                <Divider variant="fullWidth" sx={{ mt: 2, mb: 2 }} />
                <Grid container spacing={5} alignItems="flex-end">
                    {products.length > 0 && products?.slice(0).reverse().map((product, index) => (
                        <Grid
                            item
                            key={product.id}
                            xs={12}
                            md={6}
                            lg={3}
                        >
                            <Card
                                variant='outlined'
                            >
                                <CardHeader
                                    title={product.name}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                        color: 'primary'
                                    }}
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.grey[200]
                                    }}
                                />
                                <CardContent>
                                    <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mt: 2,
                                    }}
                                    >
                                    <Select
                                        value={selectedPricing[product.id] || ''}
                                        autoWidth
                                        sx={{width:'100%', textAlign: "center"}}
                                        onChange={handlePricingChange}
                                    >
                                        {product?.pricing.slice(0).map((price, index) => (
                                            <MenuItem key={price.id} id={product.id} value={price.id}>
                                                <Typography component="h2" variant="h3" display="inline">
                                                    ${price.unit_amount.toString().substring(0, price.unit_amount.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                </Typography>
                                                <Typography variant="h6" color="secondary" display="inline">
                                                    {price?.recurring.interval === 'year' ? "/yr" : "/mo"}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </Box>
                                    <Typography
                                        component="p"
                                        align="center"
                                        sx={{
                                            mt:2,
                                            fontWeight:'bold'
                                        }}
                                    >
                                    {tierLimits[product?.name]} Or Less Students<br />
                                    Amount Due Today: ${subscription.plan.amount < selectedPricingValue[product?.id]?.unit_amount ? ((selectedPricingValue[product?.id]?.unit_amount - subscription.plan.amount)?.toString().substring(0, (selectedPricingValue[product?.id]?.unit_amount - subscription.plan.amount)?.toString().length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")) : '0.00'}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                
                                <Tooltip arrow title={institution.actual_number_of_students > tierLimits[product?.name] ? "The number of students you have exceeds this tier limit." : ""}>
                                    <span style={{display:'block', width:'100%'}}>
                                        <Button 
                                            fullWidth
                                            disableElevation
                                            variant='contained'
                                            disabled={institution.actual_number_of_students > tierLimits[product?.name] ? true : false}
                                            onClick={() => handlePricingClick(product.id)}
                                        >
                                            Switch Tiers
                                        </Button>
                                    </span>
                                </Tooltip>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Modal
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    {openErrorModalText}
                    </Typography>
                </Box>
            </Modal>
        </Grid>
    )
}

export default EditSubscription;