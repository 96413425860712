import * as React from 'react';
import Navigation from "./navigation/navigation";
import { marketingUrl } from '../../../../constants/globals';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import './header.scss';

import logoDesktop from '../../../../assets/images/logos/logo-wide.jpg';
import logoMobile from '../../../../assets/images/logos/logo-icon.jpg';

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        color="inherit"
        className="AppBar"
      >
        <Toolbar>
          <Typography variant="h6" component="div" className="logoDiv" sx={{ flexGrow: 1 }}>
            <a href={marketingUrl} className="logo" style={{backgroundImage: "url(" + logoMobile + ")"}}>
              <img src={logoDesktop} alt="Logo Desktop" />
            </a>
          </Typography>
          <Navigation />
        </Toolbar>
      </AppBar>
    </Box>
  )
};

export default Header;
