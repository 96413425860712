import { apiUrl } from '../constants/globals';

export async function getAllUserSuggestions(token) {
    try {
        const response = await fetch(apiUrl + '/user/all/suggestions/', {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getUserSuggestionsByReferrer(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/suggestions/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getEmployeeSuggestionsByStudent(token, employeeId, studentId) {
    try {
        const response = await fetch(apiUrl + '/user/suggestions/' + employeeId + '/' + studentId, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getSystemSuggestions(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/system/suggestions/' + id, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getComparisons(token, user_id, id, type) {
    try {
        const response = await fetch(apiUrl + '/user/comparison/' + user_id + '/' + id + '/' + type, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getCalculatorComparison(token, studentId, typeId, comparisonCode) {
    try {
        const response = await fetch(apiUrl + '/user/calculator/comparison/' + studentId + '/' + typeId + '/' + comparisonCode, {
          method: 'GET',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function addSuggestion(token, data) {
    try {
        const response = await fetch(apiUrl + '/user/suggestions/', {
          method: 'POST',
          body: data,
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function deleteSuggestion(token, id) {
    try {
        const response = await fetch(apiUrl + '/user/suggestions/delete/' + id, {
          method: 'DELETE',
          headers: new Headers({
            'Authorization': 'Bearer ' + token
          })
        });
        return await response.json();
    } catch(error) {
        return error;
    }
}