import { apiUrl } from '../constants/globals';

export async function getStates() {
    try{
        const response = await fetch(apiUrl + '/getStates/');
        return await response.json();
    } catch(error) {
        return error;
    }
}

export async function getTaxFilingStatuses() {
    try{
        const response = await fetch(apiUrl + '/getTaxFilingStatuses/');
        return await response.json();
    } catch(error) {
        return error;
    }
}
