import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AlertTitle from '@mui/material/AlertTitle';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PhoneIcon from '@mui/icons-material/Phone';
import { clickAdvertisement } from '../../../services/advertisementsService';
import { storageUrl } from '../../../constants/globals';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign:'center'
  };

export function AdvertisementDisplay({advertisement, selectedCategory, type, typeTitle, setError, setErrorText}) {
    const [showMore, setShowMore] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const handleCloseModal = () => setOpenModal(false);

    const handlePhoneClick = () => {
        if (type !== 'preview') {
            handleClick();
        }
        setOpenModal(true);
    }

    const handleUrlClick = () => {
        if (type !== 'preview') {
            handleClick();
        }
        window.open(
            advertisement?.link,
            '_blank'
        );
    }

    const handleClick = () => {
        clickAdvertisement(localStorage.getItem('token'), advertisement?.id, selectedCategory).then(click => {
            if (click.status == true) {
                return true;
            }
        });
    }

    return (
        <>
            <Box className='advertisement' sx={{mb: 2}}>
                {advertisement?.disapproved_reason && (
                    <Paper sx={{ position: 'relative' }}>
                        <Alert 
                            severity="error"
                            sx={{mb: 2}}
                        >
                            <AlertTitle>This {typeTitle} Was Not Approved</AlertTitle>
                            {advertisement?.disapproved_reason}
                        </Alert>
                    </Paper>
                )}
                {(advertisement?.approved === 0 && !advertisement?.disapproved_reason) && (
                    <Paper sx={{ position: 'relative' }}>
                        <Alert 
                            severity="warning"
                            sx={{mb: 2}}
                        >
                            <AlertTitle>{typeTitle} Is Pending Approval</AlertTitle>
                        </Alert>
                    </Paper>
                )}
                <Paper sx={{ position: 'relative' }}>
                    {type === 'preview' && (
                        <Alert 
                            severity="info"
                            sx={{
                                borderRadius: '4px 4px 0px 0px',
                                padding: '2px 5px'
                            }}
                        >
                            This is a preview
                        </Alert>
                    )}
                    {advertisement?.image && (
                        <Box
                            component="div"
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '210px',
                                overflow:'hidden',
                                borderRadius: type === 'preview' ? '0px' : '4px 4px 0 0'
                            }}
                            onClick={(advertisement?.link) ? () => handleUrlClick() : () => handlePhoneClick()}
                        >
                            <Box
                                component="img"
                                sx={{
                                    maxWidth: '100%',
                                    cursor:'pointer',
                                    borderRadius: type === 'preview' ? '0px' : '4px 4px 0 0'
                                }}
                                alt={advertisement?.headline}
                                src={storageUrl + advertisement?.image}
                            />
                        </Box>
                    )}
                    <Box
                        sx={{
                            pl: 1, 
                            pr: 1, 
                            pb: (advertisement?.amount && advertisement?.amount > 0) ? 0 : 1, 
                            pt: 1
                        }}
                    >
                        <Button
                            variant='text'
                            disableElevation
                            size='large'
                            color={'primary'}
                            sx={{
                                display: 'block',
                                padding:'0px',
                                fontWeight: 'bold',
                                width:'100%',
                                textAlign:'left',
                                fontSize: '22px'
                            }}
                            onClick={(advertisement?.link) ? () => handleUrlClick() : () => handlePhoneClick()}
                        >
                            {advertisement?.headline}
                        </Button>
                    </Box>
                    {(advertisement?.amount && advertisement?.amount > 0) && (
                        <Box
                            sx={{pl: 1, pr: 1, pb: 1}}
                        >
                            <Typography 
                                sx={{
                                    fontSize:'18px',
                                    fontWeight:'bold'
                                }} 
                                color={'secondary'}
                            >
                                ${advertisement?.amount}
                            </Typography>
                        </Box>
                    )}
                    {advertisement?.description && (
                        <Box
                            sx={{
                                pl: 1,
                                pr: 1,
                                pb: 1,
                                overflow: 'hidden',
                                position: 'relative',
                                maxHeight: (advertisement?.description.length > 150 && !showMore) ? '100px' : 'auto'
                            }}
                        >
                            <Typography sx={{fontSize:'14px'}}>{advertisement?.description}</Typography>
                            {(advertisement?.description.length > 150 && !showMore) ? (
                                <Box
                                    sx={{
                                        position:'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        p: 1, 
                                        height: '50px',
                                        background: 'rgb(255,255,255)',
                                        background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.99) 50%)'
                                    }}
                                >
                                    <Button
                                        variant='text'
                                        disableElevation
                                        size='small'
                                        color={'primary'}
                                        sx={{
                                            position:'absolute',
                                            bottom: 0,
                                            left: 0,
                                            m: 1,
                                            fontWeight:'bold'
                                        }}
                                        onClick={() => setShowMore(true)}
                                    >
                                        Read More
                                    </Button>
                                </Box>
                            ) : (
                                <>
                                    {(advertisement?.description.length > 150) && (
                                        <Button
                                            variant='text'
                                            disableElevation
                                            size='small'
                                            color={'primary'}
                                            sx={{
                                                fontWeight:'bold'
                                            }}
                                            onClick={() => setShowMore(false)}
                                        >
                                            Show Less
                                        </Button>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                    {(advertisement?.link || advertisement?.phone) && (
                        <Grid container spacing={0}>
                            {advertisement?.link && (
                                <Grid item xs={12} sm={12} md={12} lg={!advertisement?.phone ? 12 : 6}>
                                    <Button
                                        variant='text'
                                        disableElevation
                                        size='small'
                                        color={'primary'}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            fontSize: '20px'
                                        }}
                                        startIcon={<InsertLinkIcon />}
                                        onClick={() => handleUrlClick()}
                                    >
                                        See More
                                    </Button>
                                </Grid>
                            )}
                            {advertisement?.phone && (
                                <Grid item xs={12} sm={12} md={12} lg={!advertisement?.link ? 12 : 6}>
                                    <Button
                                        variant='text'
                                        disableElevation
                                        size='small'
                                        color={!advertisement?.link ? 'primary' : 'secondary'}
                                        sx={{
                                            width:'100%',
                                            fontWeight: 'bold',
                                            fontSize: '20px'
                                        }}
                                        startIcon={<PhoneIcon />}
                                        onClick={() => handlePhoneClick()}
                                    >
                                        Call Us
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Paper>
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box sx={style}>
                    <Typography 
                        variant="h4" 
                        component="h2"
                    >
                        <Link href={'tel:' + advertisement?.phone}>
                            {advertisement?.phone?.replace('+1 ','')?.replace(/ /g, '-')}
                        </Link>
                    </Typography>
                </Box>
            </Modal>
        </>
    )
}